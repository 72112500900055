import {
  useState,
  useCallback,
  useContext,
  // , useEffect
} from "react";
// import clsx from "clsx";
import {
  KycGeneralSchema,
  uploadInitialValue,
  setInitialValues,
} from "./helpers";
import { Formik } from "formik";
import { AddUser, uploadKyc, UpdateKycUser } from "../../Services/Admin/Kyc";
import { Card, List, ListItem } from "@material-ui/core";
import Alert from "../Alert";
import GeneralForm from "./GeneralForm";
import KycForm from "./KycForm";
import useStyles from "../../assets/CustomeMaterialStyleSheet";
import { ToasterContext } from "../../App";

// import PerfectScrollbar from "react-perfect-scrollbar";
const Tabs = (props) => {
  // const { setFieldValue } = props;
  const { actionState, updatedRow, Refetch, handleClose, kycDocs } = props;
  const classes = useStyles();
  const listItems = [
    {
      tab: "general",
      title: "General",
      idName: "general",
    },
    {
      tab: "kyc",
      title: "KYC",
      idName: "kyc",
    },
    // {
    //   tab: "security",
    //   title: "Security",
    //   idName: "security",
    // },
  ];
  const [activeTab, setActiveTab] = useState("general");
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [upload, setUpload] = useState("");
  const [upload1, setUpload1] = useState("");
  const [docType, setDocType] = useState("");

  // Solve KYC image Bug RCN-144
  const [passportImg, setPasspostImg] = useState(null);
  const [cnicFront, setCnicFront] = useState(null);
  const [cnicBack, setCnicBack] = useState(null);
  const [drivingImg, setDrivingImg] = useState(null);

  // useEffect(() => {
  //   if (passportImg && docType === "passport") {
  //     // setFieldValue("upload", passportImg);
  //     setUpload(passportImg);
  //   } else if (cnicFront && docType === "cnic") {
  //     // setFieldValue("upload", cnicFront);
  //     setUpload(cnicFront);
  //   } else if (drivingImg && docType === "license") {
  //     // setFieldValue("upload", drivingImg);
  //     setUpload(drivingImg);
  //   }
  //   // eslint-disable-next-line
  // }, [passportImg, cnicFront, drivingImg]);

  // useEffect(() => {
  //   if (cnicBack && docType === "cnic") {
  //     // setFieldValue("upload1", cnicBack);
  //     setUpload1(cnicBack);
  //   }
  //   // eslint-disable-next-line
  // }, [cnicBack]);
  //  end RCN-144

  const KycAddUser = (data, actions) => {
    AddUser(data).then((res) => {
      if (res.status === 200) {
        Refetch();
        handleClose();
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const KycUpdateUser = (data, actions) => {
    UpdateKycUser(data).then((res) => {
      if (res.status === 200) {
        Refetch();
        handleClose();
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const SubmitForm = (data, actions, actionState) => {
    if (actionState === "edit") {
      KycUpdateUser(data, actions);
    } else {
      KycAddUser(data, actions);
    }
  };
  const onUpload = async (values, actions, actionState, upload, upload1) => {
    values.type = docType;
    values.upload = upload;
    values.upload1 = upload1;
    uploadKyc(values).then((res) => {
      if (res.code === 200) {
        setUpload("");
        setUpload1("");
        handleClose();
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const toggle = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    },
    [activeTab]
  );

  return (
    <>
      <Alert {...alert} />
      <Card className={classes.tabCard}>
        <div id="settings-header" className="tab-card">
          <div className="card-header px-0">
            <div className="custom-tab mt-3">
              <List component="div" className={classes.tabCardHeading}>
                {listItems.map((item) => {
                  return (
                    <ListItem
                      id={"kyc-" + item.idName + "-tab"}
                      button
                      className="font-size-md rounded-0 custsom-tab-btn fit-content"
                      selected={activeTab === item.tab}
                      onClick={() => {
                        toggle(item.tab);
                      }}
                    >
                      <span className="font-weight-bold pt-1 font-size-sm text-uppercase">
                        {item.title}
                      </span>
                      <div className="divider" />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </div>
        </div>
        <Formik
          initialValues={
            activeTab === "general"
              ? setInitialValues(updatedRow)
              : activeTab === "kyc"
              ? uploadInitialValue
              : {}
          }
          onSubmit={(values, actions) => {
            if (activeTab === "kyc") {
              onUpload(values, actions, actionState, upload, upload1);
            }
            if (activeTab === "general") {
              SubmitForm(values, actions, actionState);
            }
          }}
          validationSchema={activeTab === "general" ? KycGeneralSchema : null}
        >
          {(props) => {
            return (
              <div className="d-flex justify-content-between kyc-small-input">
                <div className="mx-auto w90">
                  <div className="pt-2">
                    {activeTab === "general" ? (
                      <GeneralForm
                        actionState={actionState}
                        setActiveTab={setActiveTab}
                        {...props}
                      />
                    ) : activeTab === "kyc" ? (
                      <KycForm
                        actionState={actionState}
                        setActiveTab={setActiveTab}
                        {...props}
                        upload={upload}
                        upload1={upload1}
                        setUpload1={setUpload1}
                        setUpload={setUpload}
                        onUpload={onUpload}
                        // setFieldValue={setFieldValue}
                        setDocType={setDocType}
                        passportImg={passportImg}
                        setPasspostImg={setPasspostImg}
                        cnicFront={cnicFront}
                        setCnicFront={setCnicFront}
                        cnicBack={cnicBack}
                        setCnicBack={setCnicBack}
                        drivingImg={drivingImg}
                        setDrivingImg={setDrivingImg}
                        kycDocs={kycDocs}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </Card>
    </>
  );
};
export default Tabs;
