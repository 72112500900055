/* eslint-disable */
import { useState, useEffect, useMemo } from "react";

import { useDropzone } from "react-dropzone";
import ErrorIcon from "@mui/icons-material/Error";
import AddIcon from "@mui/icons-material/Add";
const Uploader = (props) => {
  const { upload, setUpload } = props;
  const [files, setFiles] = useState([]);

  useEffect(() => {
    // if (props.upload && props.upload.preview) {
    //   setFiles([props.upload]);
    // }
    setFiles([upload]);
  }, [upload]);
  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  };
  const thumb = {
    display: "inline-flex",
  };
  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };
  const img = {
    display: "block",
    width: "10%",
    height: "100%",
  };
  const baseStyle = {
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    boxShadow: "0 3px 5px rgb(0 0 0 / 20%)",

    color: "#bdbdbd",
    transition: "border .24s ease-in-out",
    justifyContent: "center",
  };
  const focusedStyle = {
    borderColor: "#2196f3",
  };
  const acceptStyle = {
    borderColor: "#00e676",
  };
  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    // noClick: true,
    noKeyboard: true,
    accept: "image/*",
    minSize: 0,
    maxSize: 1000 * 1000,
    onDrop: (acceptedFiles, rejectedFiles) => {
      console.log("files", acceptedFiles);
      console.log("rejected files", rejectedFiles);
      if (acceptedFiles.length > 0) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setUpload(
          acceptedFiles && acceptedFiles.length > 0
            ? acceptedFiles[0]
            : props.icon
        );
      }
      if (rejectedFiles) {
        // setError(rejectedFiles.error);
      }
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  // const acceptedFileItems = acceptedFiles.map((file) => (
  //   <div style={thumbsContainer}>
  //     <div style={thumb} key={file.name}>
  //       <div style={thumbInner}>
  //         <img src={file.preview} alt="preview" style={img} />
  //       </div>
  //     </div>
  //   </div>
  // ));
  //   console.log('files', files)
  const editFileItems = files.map((file) => (
    <div style={thumbsContainer}>
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img src={file.preview} alt="preview" style={img} />
        </div>
      </div>
    </div>
  ));
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div>
      <ul>
        {errors.map((e) => (
          <li
            key={e.code}
            style={{ position: "absolute", left: "43%", color: "red" }}
          >
            <span className="mr-1">
              <ErrorIcon />
            </span>
            <span>File size exceeds maximum limit 1 MB</span>
          </li>
        ))}
      </ul>
    </div>
  ));
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file?.preview));
    },
    [files]
  );
  return (
    <div>
      <section className="d-flex">
        <div {...getRootProps({ style })} className="bg-primary ">
          <input {...getInputProps()} />
          <div
            className="d-flex flex-row  "
            style={{ width: "100px", textAlign: "center", paddingLeft: "8px" }}
          >
            <AddIcon
              style={{
                color: "white",
                border: "1px solid white",
                borderRadius: "50%",
                marginRight: "7px",
              }}
            />
            {/* <p className='error'>{errors}</p> */}
            <span className="text-white">Upload</span>
          </div>
        </div>
        <aside className="mt-5 ml-4">{fileRejectionItems}</aside>
      </section>
    </div>
  );
};
export default Uploader;
