import moment from "moment";
import { ETHEREUM_S, MMMM_Do_YYYY, h_mm_ss_a } from "../../helpers/constants";
import {
  TEXT_LEFT,
  TEXT_DARK,
  D_NONE__D_SM_TABLE_cell,
} from "../../helpers/CLASSES";

const TableRow = (props) => {
  const { row, index } = props;
  return (
    <>
      <tr key={row.id + index} id={ETHEREUM_S + "-tr-" + index}>
        <td className={TEXT_LEFT}>
          <span>👤 {row.name}</span>
          <span style={{ fontSize: "10px", textTransform: "initial" }}>
            📧 {row.email}
          </span>
        </td>
        {/* <td className={TEXT_LEFT}>
          <span>USD</span>
        </td> */}
        <td className={TEXT_LEFT}>
          <span>
            ⭕ {row.payableToken} {row.tokens}
            <span className={TEXT_DARK}>💳 {row.expectedamount} USD</span>
          </span>
        </td>
        {/* <td className={TEXT_LEFT}>
          <span className={D_NONE__D_SM_TABLE_cell}>
            <span className={TEXT_DARK}>{row.saleType}</span>
          </span>
        </td> */}

        {/* <td className={TEXT_LEFT}>
          <span className={D_NONE__D_SM_TABLE_cell}>
            <span className={TEXT_DARK}>{row.expectedamount}</span>
          </span>
        </td> */}
        <td className={TEXT_LEFT}>
          <span className={D_NONE__D_SM_TABLE_cell}>
            <span className={TEXT_DARK}>👛 {row.recevingAddress}</span>
            <span className={TEXT_DARK}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>{row.saleType}</small>
            </span>
          </span>
        </td>
        <td className={TEXT_LEFT}>
          <span>🕛 {moment(row.updatedAt).format(MMMM_Do_YYYY)}</span>
          <span>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            {moment(row.updatedAt).format(h_mm_ss_a)}
          </span>
        </td>
      </tr>
    </>
  );
};
export default TableRow;
