import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Clip from "../../components/Comps-Utils/ClipLoader";
import { ConfirmUserApi } from "../../Services/User/User";

const SuccessMessage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    handleConfirmEmail();
  }, []);

  const handleConfirmEmail = async () => {
    setLoading(true);
    const res = await ConfirmUserApi(id);
    if (res.data.code === 200) {
      setLoading(false);
    } else {
      setLoading(false);
      setError(true);
      setErrorMessage(res.data.msg);
    }
  };

  return (
    <div>
      <div className="user-ath user-success-msg">
        <div className="user-ath-page">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8  text-center">
                <div className="user-ath-logo">
                  <Link to={""}>
                    <img
                      src="../images/logo.png"
                      srcSet="images/logo-sm2x.png 2x"
                      alt="icon"
                    />
                  </Link>
                </div>
                {loading ? (
                  <Clip color="#f64b1c" size={50} />
                ) : !error ? (
                  <div className="user-ath-box">
                    <div className="status status-thank">
                      <div className="status-icon">
                        <em className="ti ti-check"></em>
                      </div>
                      <h2 className="status-text">Thank you!</h2>
                      <span className="status-text">
                        Your signup process is almost done.
                      </span>
                      <div className="form-note">
                        {" "}
                        <Link to={"/login"}>Login</Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {error ? (
                  <div className="user-ath-box">
                    <h2 className="status-text">{errorMessage}</h2>
                    <div className="form-note">
                      {" "}
                      <Link to={"/login"}>Goto Login</Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
