import React from "react";
import { Link } from "react-router-dom";

const EmailConfirmation = () => {
  return (
    <>
      <div className="user-ath">
        <div className="user-ath-page">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8  text-center">
                <div className="user-ath-logo">
                  <Link to={"/"}>
                    <img
                      src="images/logo.png"
                      srcSet="images/logo-sm2x.png 2x"
                      alt="icon"
                    />
                  </Link>
                </div>
                <div className="user-ath-box">
                  <div className="status status-thank">
                    <div className="status-icon">
                      <em className="ti ti-check"></em>
                    </div>
                    <span className="status-text">
                      Thank you! Your Email has been Confirmed.
                    </span>
                    <p>
                      Please click here to <Link to={"/login"}>Login</Link>{" "}
                    </p>
                  </div>
                </div>
                <div className="gaps-2x"></div>
                {/* <div className="form-note">
                  {" "}
                  Already a member? <a href="login.html">Login</a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailConfirmation;
