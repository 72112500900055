import { useState, useEffect, useContext, useCallback } from "react";
import { GetAllTransactionsAirDrop } from "../../Services/Admin/Dashboard";
import { createRows, AirDropTableHeadings } from "./helpers";
import TableRows from "./tablerow";
import Loader from "../Comps-Utils/ClimbingLoader";
import AirDropTable from "../Comps-Utils/GenericTable";
import { ToasterContext } from "../../App";
import AirdropDetail from "./airdropdetail";
import {
  AIRDROPS_TABLE_HEADING,
  SUCCESS,
  ERROR,
  AIRDROPS_SEARCH_PLACEHOLDER,
} from "../../helpers/constants";
const AirDrop = () => {
  const [loading, setLoading] = useState(true);
  const [tostify, setTostify] = useContext(ToasterContext);
  const [transactions, setTransactions] = useState([]);
  const [selected, setSelected] = useState([]);
  const GetTransactionsService = useCallback(() => {
    setLoading(true);
    GetAllTransactionsAirDrop().then((res) => {
      if (res.status === 200) {
        setTransactions(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? { SUCCESS } : { ERROR },
          message: res.message,
        });
      }
    });
  }, [tostify, setTostify]);
  useEffect(() => {
    GetTransactionsService();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="mx-auto w95">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="row flex-md-row flex-column-reverse">
              <div className="col-md-9">
                <div className="mt-3">
                  <AirDropTable
                    tableHeading={AIRDROPS_TABLE_HEADING}
                    searchPlaceHolder={AIRDROPS_SEARCH_PLACEHOLDER}
                    loading={loading}
                    data={transactions}
                    TableRow={TableRows}
                    createRows={createRows}
                    headings={AirDropTableHeadings}
                    selected={selected}
                    setSelected={setSelected}
                    tostify={tostify}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="mt-3">
                  <AirdropDetail
                    data={transactions}
                    selected={selected}
                    setSelected={setSelected}
                    setTransactions={setTransactions}
                  />
                </div>{" "}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default AirDrop;
