//MODAL HEADINGS
export const PERMISSIONS_MODAL_HEADING = "Permissions";

//TABLE HEADINGS
export const AIRDROPS_TABLE_HEADING = "AirDrops";
export const BITCOIN_TABLE_HEADING = "Bitcoin Transactions";
export const ETHEREUM_TABLE_HEADING = "Ethereum Transactions";
export const CREDIT_TABLE_HEADING = "Credit Card Transactions";
export const STABLE_TABLE_HEADING = "Stable Transactions";
export const ADMIN_TABLE_HEADING = "Admins";
export const PERMISSIONS_TABLE_HEADING = "Manage Permissions";

//Seacrch Palce Holders
export const AIRDROPS_SEARCH_PLACEHOLDER = "Search Air Drops";
export const BITCOIN_SEARCH_PLACEHOLDER = "Search Bitcoin Transactions";
export const ETHEREUM_SEARCH_PLACEHOLDER = "Search Ethereum Transactions";
export const STABLE_SEARCH_PLACEHOLDER = "Search Stable Transactions";
export const ADMIN_SEARCH_PLACEHOLDER = "Search Admins";
export const PERMISSIONS_SEARCH_PLACEHOLDER = "Search Permissions";

//Others
export const AIRDROP_DETAILS = "AirDrop Details";
export const AIRDROP = "Airdrop";
export const TOTAL_AMOUNT = "Total Amount";
export const TOTAL_ADDRESSES = "Total Addresses";
export const SELECTED_ADDRESSES = "Selected Addresses";
export const BTC = "Btc";
export const ETH = "Eth";
export const DAI = "Dai";
export const BUSD = "Busd";
export const USDT = "Usdt";
export const TUSD = "Tusd";
export const TRANSACTIONS = "Transactions";
export const TRANSACTIONS_DATA = "Transactions Data";
export const ACTIVATE_ADMIN = "Activate Admin";
export const DEACTIVATE_ADMIN = "Deactivate Admin";
export const PLEASE_CONFIRM_ACTIVATE_ADMIN =
  "Please confirm you would like to activate this admin?";
export const ARE_YOU_SURE_YOU_WANT = "Are you sure you want to do this?";
export const DELETE_ADMIN = "Delete Admin";
export const PLEASE_CONFIRM_DELETE_ADMIN =
  "Please confirm would you like to delete this admin?";
export const ADMIN = "Admin";
export const ADMINS = "Admins";
export const MANAGER = "Manager";
export const UPDATE_ROLE = "Update Role";
export const PLEASE_CONFIRM_UPDATE_ROLE =
  "Please confirm would you like to update this Role?";
export const DELETE_ROLE = "Delete Role";
export const PLEASE_CONFIRM_DELETE_ROLE =
  "Please confirm would you like to delete this Role?";
export const TYPE = "Type";
export const PERMISSIONS = "Permissions";

export const STABLE = "stable";
export const SUCCESS = "success";
export const ERROR = "error";

//ALL SMALL
export const BITCOIN_S = "bitcoin";
export const ETHEREUM_S = "ethereum";
export const DAI_S = "dai";
export const TUSD_S = "tusd";
export const USDT_S = "usdt";
export const BUSD_S = "busd";
export const PERSON_S = "person";
export const SUCCESS_S = "success";
export const ERROR_S = "error";
export const LINE_S = "line";
export const DASHBOARD_S = "dashboard";
export const Permissions_S = "permissions";
export const NEW_S = "new";
export const KYC_S = "kyc";
export const EXPAND_ROW_S = "expand row";
export const SMALL_S = "small";
export const STATUS_S = "status";
export const TYPE_S = "type";
export const TYPESTATUS_S = "userStatus";
export const KYCSTATUS_S = "kycStatus";
export const VIEWKYC_S = "viewKyc";
export const EDITKYC_S = "editKyc";
export const DELETEKYC_S = "deleteKyc";
export const ADDKYC_S = "addKyc";

//ALL CAPS
export const BTC_C = "BTC";
export const ETH_C = "ETH";
export const CREDIT_C = "credit";
export const DAI_C = "DAI";
export const BUSD_C = "BUSD";
export const USDT_c = "USDT";
export const TUSD_C = "TUSD";

//FORMAT DATES
// bitcoin eth stable status
export const MMMM_Do_YYYY = "MMMM Do YYYY";
export const h_mm_ss_a = "h:mm:ss a";
export const TX_NOT_SUBMIT = "txHash not Submit";
export const HASH_SUBMIT = "hash submit";
export const TX_VERIFIED = "tx verified";
export const TX_AIRDROP = "airdropped";
