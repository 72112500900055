export const DialogMessage = (
  setDialog,
  id = "",
  header = "",
  message = "",
  handler = () => {}
) => {
  setDialog({
    id: id,
    visible: true,
    header: header,
    message: message,
    key: Math.random().toString(36).substring(6),
    ok: handler,
  });
};
