import React from "react";
import { useContext } from "react";
import { KycContext } from "../../userRoutes";

function KycSuccess() {
  const kycStatus = useContext(KycContext);
  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2 className="user-panel-title">Identity Verification - KYC</h2>
          <p>
            To comply with regulation each participant will have to go through
            identity verification (KYC). So please complete our fast and secure
            verification process to participate in our token sale. You can
            proceed from here to verify your identity and also you can check
            your application status if you have submitted it already.{" "}
          </p>
          {/* <div className="gaps-2x"></div> */}
          <div className="status status-empty">
            <div className="status-icon">
              <em className="ti ti-files"></em>
              <div className="status-icon-sm">
                <em className="ti ti-check"></em>
              </div>
            </div>
            <span className="status-text">
              {kycStatus[0].toLowerCase() == "active" &&
                "Your Submitted KYC Application is approved"}
              {kycStatus[0].toLowerCase() == "blocked" &&
                "Your Submitted KYC Application is blocked"}{" "}
            </span>
          </div>
          <div className="note note-md note-info note-plane">
            <em className="fas fa-info-circle"></em>
            <p>
              Some of countries and regions will not able to pass KYC process
              and therefore are restricted from token sale.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default KycSuccess;
