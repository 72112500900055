import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import { ClipLoader } from 'react-spinners'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import PreSaleClock from '../HomePage/PreSaleClock'
// import Clock from './clock'
const ExpandClaimTokens = (props) => {
  const { item } = props;
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <div className="">
        <div className="p-0 mb-2">
          <IconButton
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{
              marginTop: "5px",
              background: "#f64b1c",
              borderRadius: "4px",
            }}
            className="expand-claim"
          >
            <p className="text-white">View Details</p>
            {expanded ? (
              <ExpandLessIcon sx={{ color: "white" }} />
            ) : (
              <ExpandMoreIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {item.forMonths > 0 ? (
            <>
              <>
                {/* For Months <span>{item.forMonths} months</span> */}
                <br />
                <ul className="vesting-list">
                  {item?.vestingMonths.map((iter, index) => {
                    return (
                      <li key={index}>
                        {/* <em className="ti ti-check"></em> */}
                        {item.noClaimedMonths - index > 0 ? (
                          <>
                            {" "}
                            <span style={{ color: "green !important" }}>
                              <CheckCircleIcon color={"green"} />
                              {iter.releaseDate}
                            </span>
                          </>
                        ) : (
                          <>
                            <ErrorOutlineIcon />
                            {iter.releaseDate}
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </>
            </>
          ) : (
            <></>
          )}
        </Collapse>
      </div>
    </>
  );
};
export default ExpandClaimTokens;
