import React, { useEffect, useState } from "react";
import Home from "../../src/UserDashboard";
import SideNav from "../UserDashboard/navigation/sidenav";
import InvestorBanner from "../UserDashboard/InvestorBanner/InvestorBanner";
import { TotalVestingAmount } from "../Services/User/web3Calls/contractCall";

const UserLayout = (props) => {
  const [hardCapLimit, setHardCapLimit] = useState(false);
  const [publicSaleEnded, setPublicSaleEnded] = useState(false);
  const getHardCapLimit = async () => {
    const data = await TotalVestingAmount();
    if (data.harCapLimit > data.hardcap) {
      setHardCapLimit(true);
    }
    if (data.publicSaleToken === 0) {
      setPublicSaleEnded(true);
    }
  };
  useEffect(() => {
    getHardCapLimit();
  }, []);
  const { children } = props;
  return (
    <>
      <Home />
      <div className="user-wraper">
        {hardCapLimit && publicSaleEnded ? (
          <div className="container">
            <InvestorBanner />
          </div>
        ) : (
          ""
        )}
        <div className="container">
          <div className="d-flex">
            <div className="sidenav-main">
              <SideNav />
            </div>
            <div className="user-content-main">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserLayout;
