import { useEffect, useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import AddIcon from "@mui/icons-material/Add";
// import Skeleton from '@material-ui/lab/Skeleton';
const UploadIcon = (props) => {
  const { upload, setUpload } = props;
  const [files, setFiles] = useState([]);
  useEffect(() => {
    // if (props.upload && props.upload.preview) {
    //   setFiles([props.upload]);
    // }
    setFiles([upload]);
  }, [upload]);
  const baseStyle = {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    color: "#bdbdbd",
    transition: "border .24s ease-in-out",
    justifyContent: "center",
    // borderColor: '#1343f4'
  };
  const focusedStyle = {
    borderColor: "#2196f3",
  };
  const acceptStyle = {
    borderColor: "#00e676",
  };
  const rejectStyle = {
    borderColor: "#ff1744",
  };
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: "image/*",
    minSize: 0,
    maxSize: 800 * 1000,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setUpload(
          acceptedFiles && acceptedFiles.length > 0
            ? acceptedFiles[0]
            : props.icon
        );
      }
      if (rejectedFiles) {
        console.log("Rejected Files");
      }
    },
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line
    [isFocused, isDragAccept, isDragReject]
  );
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <ul>
      {errors.map((e) => (
        <li key={e.code}>{e.message}</li>
      ))}
    </ul>
  ));
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file?.preview));
    },
    [files]
  );
  return (
    <>
      <section className="d-flex align-items-baseline">
        <div
          {...getRootProps({ style })}
          className="bg-primary btn btn-grad-theme"
        >
          <input {...getInputProps()} />
          {/* <div className='py-2 d-flex align-items-center justify-content-center'>
          <div className='d-flex avatar-icon-wrapper d-100 rounded-circle m-2'>
            <aside> */}
          {/* <AddAPhotoIcon className='w-100' /> */}
          <div
            className="d-flex flex-row  "
            style={{ width: "100px", textAlign: "center", paddingLeft: "8px" }}
          >
            {/* <h6 className='text-primary mr-2'>
            {' '}
          </h6> */}
            <AddIcon
              style={{
                color: "white",
                border: "1px solid white",
                borderRadius: "50%",
                marginRight: "7px",
              }}
            />
            <h6 className="text-white">Upload</h6>
          </div>
          {/* </aside>
          </div>
        </div> */}
        </div>
        <aside className="mt-5 ml-4">{fileRejectionItems}</aside>
      </section>
    </>
  );
};
export default UploadIcon;
