import TextField50 from "../Comps-Utils/Textfield50";
import SubmitBtn from "../Comps-Utils/SubmitButton";
import AutocompleteCountry from "../Comps-Utils/AutoCompleteCountry";
import TextFiledLabelRequired from "../Comps-Utils/TextfieldLabelRequired";
import Btnloader from "../Comps-Utils/ClipLoader";
import TextFiledLabel from "../Comps-Utils/TextfieldLabel";
import { ConnectedFocusError } from "focus-formik-error";
import { GeneralFormFields } from "./helpers";
// import { ErrorMessage } from "formik";
const GeneralForm = (props) => {
  const {
    touched,
    errors,
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    isSubmitting,
    actionState,
  } = props;
  return (
    <>
      <div className="mb-3" onSubmit={handleSubmit} autoComplete="off">
        <ConnectedFocusError />
        {GeneralFormFields.map((item, index) => {
          return (
            <div className="d-flex justify-content-between mb-3">
              <div className="w48">
                {item.fieldName === "telegram" ||
                item.fieldName === "address2" ||
                item.fieldName2 === "telegram" ||
                item.fieldName2 === "address2" ? (
                  <TextFiledLabel widthclass=" " field={item.field} />
                ) : (
                  <TextFiledLabelRequired widthclass=" " field={item.field} />
                )}
                <TextField50
                  widthclass=" "
                  fieldName={item.fieldName}
                  handleChange={handleChange}
                  values={values}
                  touched={touched}
                  errors={errors}
                  place={item.place}
                  type={item.type}
                />
              </div>
              {item && item.field2 ? (
                <div className="w48">
                  {item.fieldName2 === "telegram" ||
                  item.fieldName2 === "address2" ||
                  item.fieldName2 === "telegram" ||
                  item.fieldName2 === "address2" ? (
                    <TextFiledLabel widthclass=" " field={item.field2} />
                  ) : (
                    <TextFiledLabelRequired
                      widthclass=" "
                      field={item.field2}
                    />
                  )}

                  {item.fieldName2 === "nationality" ? (
                    <>
                      <div>
                        <AutocompleteCountry
                          fieldName={item.fieldName2}
                          handleChange={handleChange}
                          values={values}
                          touched={touched}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldValue}
                          setFieldError={setFieldValue}
                        />
                      </div>
                    </>
                  ) : (
                    <TextField50
                      widthclass=" "
                      fieldName={item.fieldName2}
                      handleChange={handleChange}
                      values={values}
                      touched={touched}
                      errors={errors}
                      place={item.place2}
                      type={item.type2}
                    />
                  )}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      {actionState === "edit" ? (
        <div className="d-flex justify-content-end">
          <SubmitBtn
            text={
              isSubmitting ? (
                <span className="ml-3">
                  <Btnloader />{" "}
                </span>
              ) : (
                "Save"
              )
            }
            disable={isSubmitting}
            click={handleSubmit}
          />
        </div>
      ) : null}
      <div className="gaps-4x"></div>
    </>
  );
};
export default GeneralForm;
