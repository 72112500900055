import React from "react";
import { useState, useEffect } from "react";
// import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
// import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
// import CreditScoreSharpIcon from "@mui/icons-material/CreditScoreSharp";
// import InventoryIcon from "@mui/icons-material/Inventory";
// import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
// import TokenIcon from "@mui/icons-material/Token";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { URL } from "url";
// import {tabImage} from '../../../public/images/metamask.png'
function Guideline() {
  //   const [info, setInfo] = useState([
  //     {
  //       title: "Setup Wallet",
  //       description:
  //         "<h6>First of all,you have to create your wallet from metamask.there is complete metamask guideline page .A useful guide to setup your wallet and network configuration</h6>",
  //     },
  //     {
  //       title: "Buy Tokens",
  //       description:
  //         "In Buy Token Section user will be able to invest in Recruit token .There are multiple options availble to invest e.g BTC,ETH,DAI.UST,BUSD.In order to invest in stable coins, you must have a metamask wallet and tokens/coins which you want to invest.If you want to invest in btc.you must have a btc wallet and btc balance in your account",
  //     },

  //     {
  //       title: "Application",
  //       description:
  //         "This is a KYC(know your customer) section.Here you have to submit details about your current country, phone number ,address etc. These details will be cross checked based on your submitted documents",
  //     },

  //     {
  //       title: "Claim Tokens",
  //       description:
  //         "After investment in Buy tokens section .User will be able to claim tokens after specific locked time .Claims amount will be based upon time of investment and bonus.",
  //     },
  //   ]);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);

    // eslint-disable-nextline
  }, [slider1, slider2]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    loop: false,
    asNavFor: ".slider-for",
    dots: false,
    arrows: false,
    centerMode: false,
    swipeToSlide: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settingsThumbs: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settingsThumbs: {
          slidesToShow: 1,
          arrows: true,
        },
      },
    ],
  };
  // var settingsThumbs = {
  //   slidesToShow:4 ,
  //     slidesToScroll: 1,
  //     loop: false,
  //     asNavFor: '.slider-for',
  //     dots: false,
  //     arrows:false,
  //     centerMode: false,
  //     swipeToSlide: false,
  //     focusOnSelect: true,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settingsThumbs: {
  //       slidesToShow: 3,
  //       slidesToScroll: 3,
  //       infinite: true,
  //       swipeToSlide: true,
  //       centerMode: true,
  //       loop: true
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settingsThumbs: {
  //       slidesToShow: 2,
  //       slidesToScroll: 2,
  //       initialSlide: 2
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settingsThumbs: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };
  const [isTabActive, setTabActive] = useState(false);
  const toggleTab = () => {
    setTabActive(!isTabActive);
  };
  return (
    <div className="info-section shadow-none border-0 mb-lg-4 mb-sm-3">
      <h2>Guideline for user</h2>
      <p>
        This sections helps to view details and guidelines of each section of
        ico. Just click on it then it will expand to show details.
      </p>

      <div className="slider-wrapper">
        <div className="thumbnail-slider-wrap">
          <Slider
            className="tab-btn-row"
            {...settingsThumbs}
            asNavFor={nav1}
            ref={(slider) => setSlider2(slider)}
          >
            <button
              className={
                isTabActive
                  ? "tab-btn-not-active tab-btn"
                  : "tab-btn-active tab-btn"
              }
              onClick={toggleTab}
            >
              <h4>
                {/* <span className="mr-2">
                      <AccountBalanceWalletIcon />
                    </span> */}
                <span>1 . Setup Wallet</span>
              </h4>
            </button>
            <button
              className={
                isTabActive
                  ? "tab-btn-not-active tab-btn"
                  : "tab-btn-active tab-btn"
              }
              onClick={toggleTab}
            >
              <h4>
                {/* <span className="mr-2">
                      <CreditScoreSharpIcon />
                    </span> */}
                <span>2 . Application</span>
              </h4>
            </button>
            <button
              className={
                isTabActive
                  ? "tab-btn-not-active tab-btn"
                  : "tab-btn-active tab-btn"
              }
              onClick={toggleTab}
            >
              <h4>
                {/* <span className="mr-2">
                      <TokenIcon />
                    </span> */}
                <span>3 . Buy Tokens</span>
              </h4>
            </button>
            <button
              className={
                isTabActive
                  ? "tab-btn-not-active tab-btn"
                  : "tab-btn-active tab-btn"
              }
              onClick={toggleTab}
            >
              <h4>
                {/* <span className="mr-2">
                      <AssignmentIndIcon />
                    </span> */}
                <span>4 . Claim Tokens</span>
              </h4>
            </button>
          </Slider>
          {/* TABS */}
          <Slider
            className="accordion-slider"
            {...settingsMain}
            asNavFor={nav2}
            ref={(slider) => setSlider1(slider)}
          >
            <div className="accordion-content">
              <div className="content-z-index">
                <h3 className="mb-lg-2 mb-1">
                  <span className="theme-color">Setup Wallet</span>
                </h3>
                <h4 className="mb-lg-2 mb-1">
                  <span className="theme-color">
                    About Wallets and Recruit Coin tokens
                  </span>
                </h4>
                <p className="mb-2">
                  A crypto wallet keeps track of your crypto accounts and allows
                  you to approve transfers to and from other accounts. You need
                  a wallet to participate in the Recruit Coin ICO. Recruit Coins
                  are{" "}
                  <a href="https://ethereum.org/en/developers/docs/standards/tokens/erc-20/">
                    Ethereum ECR-20
                  </a>{" "}
                  compatible tokens. Your crypto wallet must be capable of
                  holding ECR-20 tokens.
                </p>
                <p className="mb-2">
                  Recruit Coins are managed on the{" "}
                  <a href="https://polygon.technology/about/">Polygon</a> block
                  chain. Polygon is a layer 2 chain which is much faster and
                  less expensive than the main Ethereum chain. Layer 2
                  transactions are rolled-up and stored on the main Ethereum
                  chain for security. If your Crypto wallet does not list the
                  Polygon network, you must add it using these values:
                </p>
                <ul>
                  <li>
                    <p>Name: Polygon Mainnet</p>
                  </li>
                  <li>
                    <p>
                      New PRC URL:{" "}
                      <a href="https://polygon-rpc.com">
                        https://polygon-rpc.com
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>Chain ID: 137</p>
                  </li>
                  <li>
                    <p>Currency Symbol: MATIC</p>
                  </li>
                  <li>
                    <p>
                      Block Explorer URL:{" "}
                      <a href="https://polygonscan.com/">
                        https://polygonscan.com/
                      </a>
                    </p>
                  </li>
                </ul>
                <p className="mb-2">
                  You need to Connect your wallet to this application so you
                  can:
                </p>
                <ul>
                  <li>
                    <p>
                      (optional) Spend your existing tokens to invest in Recruit
                      Coins. If you are using a credit card, this step is not
                      required.
                    </p>
                  </li>
                  <li>
                    <p>
                      Claim your Recruit Coin tokens from Betts (after vesting).
                    </p>
                  </li>
                </ul>
                <p className="mb-2">
                  Recruit Coins are released to investors based on a vesting
                  schedule. This is typical of ICO’s to help prevent early
                  investors from manipulating the price by controlling a large
                  amount of coins.
                </p>
                <h4 className="mb-lg-2 mb-1">
                  <span className="theme-color">
                    I Already Have an Ethereum ECR-20 Compatible Wallet
                  </span>
                </h4>
                <p className="mb-2">
                  Great, you just need to click the “Connect Wallet” button in
                  the upper right corner of this page to associate your wallet
                  with this application (known as a dApp - distributed
                  application in crypto speak). Make sure your wallet is
                  pointing to the Polygon Mainnet (main network). You may need
                  to add this to the list of networks your wallet knows about.
                  The values needed are listed above in the “About Wallets and
                  the Recruit Coin tokens” section.
                </p>
                <h4 className="mb-lg-2 mb-1">
                  <span className="theme-color">
                    I Don’t have an ECR-20 Compatible Wallet
                  </span>
                </h4>
                <p className="mb-2">
                  No problem, a wallet is easy to create. We recommend using{" "}
                  <a href="https://metamask.io/">MetaMask</a>, one of the most
                  popular software wallets. Be <strong>absolutely sure</strong>{" "}
                  you have <strong>backed up your Recovery Phrase</strong> in a
                  safe, offline, location before using your wallet!
                </p>
                <p className="mb-2">
                  Your new wallet will have 1 account by default. You can add
                  additional accounts if you desire.
                </p>
                <p className="mb-2">
                  You must configure MetaMask to point to the Polygon Mainnet
                  (main network). There is a dropdown at the top of the MetaMask
                  browser extension showing a list of networks. If Polygon is
                  not listed, you will need to “Add Network”. The values needed
                  are listed above in the “About Wallets and the Recruit Coin
                  tokens” section.
                </p>
                <p className="mb-2">
                  Click the “Connect Wallet” button in the upper right corner of
                  this page to associate your wallet with this application
                  (known as a dApp - distributed application in crypto speak).
                </p>
              </div>
            </div>
            <div className="accordion-content">
              <div className="content-z-index">
                <h3>
                  <span className="theme-color">Application</span>
                </h3>
                <p className="mb-2">
                  Application, First of all,you have to create your wallet from{" "}
                  <span className="theme-color">metamask</span>. there is
                  complete metamask guideline page .A useful guide to setup your
                  wallet and network configuration
                </p>
              </div>
            </div>
            <div className="accordion-content">
              <div className="content-z-index">
                <h3>
                  <span className="theme-color">Buy Tokens</span>
                </h3>
                <p className="mb-2">
                  Buy Tokens, First of all,you have to create your wallet from{" "}
                  <span className="theme-color">metamask</span>. there is
                  complete metamask guideline page .A useful guide to setup your
                  wallet and network configuration
                </p>
                <p className="mb-2">
                  First of all,you have to create your wallet from{" "}
                  <span className="theme-color">metamask</span>. there is
                  complete metamask guideline page .A useful guide to setup your
                  wallet and network configuration
                </p>
              </div>
            </div>
            <div className="accordion-content">
              <h3>
                <span className="theme-color">Claim Tokens</span>
              </h3>
              <div className="content-z-index">
                <p className="mb-2">
                  Claim Tokens, First of all,you have to create your wallet from{" "}
                  <span className="theme-color">metamask</span>. there is
                  complete metamask guideline page .A useful guide to setup your
                  wallet and network configuration
                </p>
                <p className="mb-2">
                  First of all,you have to create your wallet from{" "}
                  <span className="theme-color">metamask</span>. there is
                  complete metamask guideline page .A useful guide to setup your
                  wallet and network configuration
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <>
        {/* <div className="accordion-item">
          <h6
            className="accordion-heading collapsed"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse-1-1`}
          >
            {" "}
            <div className="row">
              <div className="col-11">
                <h4>
                  <span className="mr-2">
                    {" "}
                    <AccountBalanceWalletIcon />
                  </span>

                  <span>Setup Wallet</span>
                </h4>{" "}
              </div>

              <div className="col-1">
                <h3>
                  <ExpandCircleDownIcon sx={{ color: "#f64b1c" }} />
                </h3>
              </div>
            </div>
          </h6>

          <div
            id={`collapse-1-1`}
            className="collapse"
            data-bs-parent="#faqList"
          >
            <div className="accordion-content">
              <div className="content-z-index">
                <p className="mb-2">
                  First of all,you have to create your wallet from <span className="theme-color">metamask</span>. there
                  is complete metamask guideline page .A useful guide to setup
                  your wallet and network configuration
                </p>
                  <p className="mb-2">
                  First of all,you have to create your wallet from <span className="theme-color">metamask</span>. there
                  is complete metamask guideline page .A useful guide to setup
                  your wallet and network configuration
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h6
            className="accordion-heading collapsed"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse-1-2`}
          >
            {" "}
            <div className="row">
              <div className="col-11">
                <h4>
                  <span className="mr-2">
                    {" "}
                    <CreditScoreSharpIcon />
                  </span>

                  <span>Buy Tokens</span>
                </h4>{" "}
              </div>

              <div className="col-1">
                <h3>
                  <ExpandCircleDownIcon sx={{ color: "#f64b1c" }} />
                </h3>
              </div>
            </div>
          </h6>

          <div
            id={`collapse-1-2`}
            className="collapse"
            data-bs-parent="#faqList"
          >
            <div className="accordion-content">
            <div className="content-z-index">
                <p className="mb-2">
                  In Buy Token Section user will be able to invest in Recruit
                  token .There are multiple options availble to invest e.g
                  BTC,ETH,DAI.UST,BUSD.In order to invest in stable coins, you
                  must have a metamask wallet and tokens/coins which you want to
                  invest.If you want to invest in btc.you must have a btc wallet
                  and btc balance in your account.
                </p>
                  <p className="mb-2">
                  In Buy Token Section user will be able to invest in Recruit
                  token .There are multiple options availble to invest e.g
                  BTC,ETH,DAI.UST,BUSD.In order to invest in stable coins, you
                  must have a metamask wallet and   <span className="theme-color">tokens/coins</span> which you want to
                  invest.If you want to invest in btc.you must have a btc wallet
                  and btc balance in your account.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h6
            className="accordion-heading collapsed"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse-1-3`}
          >
            {" "}
            <div className="row">
              <div className="col-11">
                <h4>
                  <span className="mr-2">
                    {" "}
                    <AssignmentIndIcon />
                  </span>

                  <span>Application</span>
                </h4>{" "}
              </div>

              <div className="col-1">
                <h3>
                  <ExpandCircleDownIcon sx={{ color: "#f64b1c" }} />
                </h3>
              </div>
            </div>
          </h6>

          <div
            id={`collapse-1-3`}
            className="collapse"
            data-bs-parent="#faqList"
          >
            <div className="accordion-content">
            <div className="content-z-index">
                <p className="mb-2">
                  This is a KYC<span className="theme-color">(know your customers)</span> section.Here you have to
                  submit details about your current country, phone number ,address
                  etc. These details will be cross checked based on your submitted
                  documents.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h6
            className="accordion-heading collapsed"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse-1-4`}
          >
            {" "}
            <div className="row">
              <div className="col-11">
                <h4>
                  <span className="mr-2">
                    {" "}
                    <TokenIcon />
                  </span>

                  <span>Claim Tokens</span>
                </h4>{" "}
              </div>

              <div className="col-1">
                <h3>
                  <ExpandCircleDownIcon sx={{ color: "#f64b1c" }} />
                </h3>
              </div>
            </div>
          </h6>

          <div
            id={`collapse-1-4`}
            className="collapse"
            data-bs-parent="#faqList"
          >
            <div className="accordion-content">
            <div className="content-z-index">
                <p className="mb-2">
                After investment in Buy tokens section .User will be able to
                claim tokens after specific locked time .Claims amount will be
                based upon time of investment and bonus.
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </>
    </div>
  );
}

export default Guideline;
