import { BlockcypherAPIBitcoin } from "../apiConfig";
import { ParseError } from "../../../Config/utils";
export const FetchBtcBalance = async (address) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  //
  let response = BlockcypherAPIBitcoin.get(`/addrs/${address}`)
    .then((response) => {
      //
      if (response) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          code: response,
          data: response,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const FetchEthBalance = async (address) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  //
  let response = BlockcypherAPIBitcoin.get(`/addrs/${address}`)
    .then((response) => {
      //
      if (response) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          code: response,
          data: response,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
  return response;
};
export const VerifyTransaction = async (txn) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  let response = BlockcypherAPIBitcoin.get(`/txs/${txn}`)
    .then((response) => {
      if (response) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          code: response,
          data: response,
          message: response.msg,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 429) {
        return {
          ...responseData,
          status: 400,
          code: response,
          data: response,
          message: "Our Services are currently busy try Again later",
        };
      } else if (err.response.status === 404) {
        return {
          ...responseData,
          status: 400,
          code: response,
          data: response,
          message: err.response.data,
        };
      } else {
        return {
          ...responseData,
          status: 400,
          code: response,
          data: response,
          message: err.response.data,
        };
      }
    });
  return response;
};
