import moment from "moment";
import { MMMM_Do_YYYY, h_mm_ss_a } from "../../helpers/constants";
import { TEXT_LEFT } from "../../helpers/CLASSES";

const TableRow = (props) => {
  const { row, index } = props;
  return (
    <>
      <tr key={row.id + index} id={"transaction-tr-" + index}>
        {/* <td className="px-4 text-left text-capitalize">
          <span>{row.userId}</span>
        </td> */}
        <td className={TEXT_LEFT}>
          <span>👤 {row.name}</span>
          <span style={{ fontSize: "10px", textTransform: "initial" }}>
            📧 {row.email}
          </span>
        </td>
        <td className="text-left">
          <span>
            💰 {row.expectedamount} {row.paymentMethod}
          </span>
        </td>
        <td className="text-left">
          <span>
            ⭕ {row.payableToken} {row.tokens}
          </span>
        </td>
        <td className="text-left">
          <span className="d-none d-sm-table-cell">
            <span className="text-success">{row.saleType}</span>
          </span>
        </td>
        {/* <td className="text-left">
          <span className="d-none d-sm-table-cell">
            <span className="text-success">{row.status}</span>
          </span>
        </td> */}
        <td className={TEXT_LEFT}>
          <span>🕛 {moment(row.updatedAt).format(MMMM_Do_YYYY)}</span>
          <span>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            {moment(row.updatedAt).format(h_mm_ss_a)}
          </span>
        </td>
      </tr>
    </>
  );
};
export default TableRow;
