import { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import {
  fetchWhiteListAddress,
  PostBtcEthTransactions,
} from "../../Services/User/Tokens/token";
import {
  FetchBtcBalance,
  FetchEthBalance,
} from "../../Services/User/BlockCypher/blockcypher";
import { ToasterContext } from "../../App";
import { QRCodeSVG } from "qrcode.react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ErrorIcon from "@mui/icons-material/Error";
function BTCInstruction({
  btcModal,
  closeBtcModal,
  dollar,
  purchase,
  network,
}) {
  const [loading, setLoading] = useState(false);
  //eslint-disable-next-line
  const [whitelistAddress, setWhiteListAddress] = useState([]);
  const [errors, setErrors] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  //eslint-disable-next-line
  const [coinBal, setCoinBal] = useState(0);
  const [agreement, setAgreement] = useState(false);
  const handleBTCETHTransaction = async () => {
    //
    if (selectedAddress !== "" && agreement === true) {
      setLoading(true);
      setErrors(false);
      const data = await PostBtcEthTransactions(purchase, selectedAddress);
      setLoading(false);
      await setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: data.code === 200 ? "success" : "error",
        message: data.message,
      });
      window.location.href = `/paymentManual?&transid=${data.data}`;
      //
    } else {
      setErrors(true);
    }
  };
  useEffect(() => {
    fetchWhitelist();
    // eslint-disable-next-line
  }, []);
  const fetchWhitelist = async () => {
    const data = await fetchWhiteListAddress(network);
    setWhiteListAddress(data.data);
    setSelectedAddress(data.data.length > 0 ? data.data[0].address : "");
  };

  const handleSelect = async (e) => {
    if (e.target.name === "address") {
      setSelectedAddress(e.target.value);
    } else if (e.target.name === "agreement") {
      const { checked } = e.target;
      setAgreement(checked);
    }
    if (network === "bitcoin") {
      const bal = await FetchBtcBalance(e.target.value);
      setCoinBal(
        bal.data.balance
          ? bal.data.balance / 100000000
          : "Unable to Fetch Btc balance "
      );
    } else {
      const bal = await FetchEthBalance(e.target.value);
      setCoinBal(
        bal.data.balance
          ? bal.data.balance / 100000000
          : "Unable to Fetch Eth balance "
      );
    }
  };
  return (
    <div>
      <Modal
        className="bitcoin-modal"
        show={btcModal}
        onHide={closeBtcModal}
        backdrop="static"
      >
        <div className="bitcoin-form" id="style-default">
          <Modal.Header closeButton className="p-3">
            <h4 className="mb-0">
              Instructions for{" "}
              {network?.charAt(0)?.toUpperCase() + network?.slice(1)}{" "}
              Transactions{" "}
            </h4>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="">
              <div className="alert alert-danger" role="alert">
                <p>
                  <em className="fab fa-warning"></em> Please read the following
                  instructions carefully. In case you ignore these instructions
                  your funds can be lost.
                </p>
              </div>
              <div className="tranx-payment-details  border p-3 m-1">
                <h4 className="custom-primary">Step 1.</h4>
                <h6>
                  Select the WhiteListed Address below and open your favourite
                  Wallet app and send {network} to this address mentioned in
                  Step 2
                </h6>
                <div>
                  <div className="d-sm-flex d-block align-items-center justify-content-between">
                    <select
                      className="input-bordered"
                      name="address"
                      style={{
                        border: "2px solid rgb(255 173 122)",
                        width: "70%",
                        // background: "rgb(234 84 39)",
                        color: "rgb(234 84 39)",
                      }}
                      id="address"
                      value={selectedAddress}
                      onChange={handleSelect}
                    >
                      {whitelistAddress.length > 0 ? (
                        whitelistAddress.map((item, index) => {
                          return (
                            <option
                              data-content="fab fa-bitcoin text-warning"
                              value={item?.address}
                              style={
                                item?.address === selectedAddress
                                  ? {
                                      backgroundColor: "#ff653a",
                                      color: "white",
                                    }
                                  : { color: "brown" }
                              }
                              selected={
                                item?.address === selectedAddress ? true : false
                              }
                              key={index}
                              name={item?.address}
                            >
                              {item?.address}{" "}
                            </option>
                          );
                        })
                      ) : (
                        <option value={"Np Address Found"}>
                          {"No Address Found"}
                        </option>
                      )}
                    </select>
                    <button
                      className="btn-primary text-white border-0 p-2 ml-1"
                      onClick={() => {
                        closeBtcModal();
                      }}
                    >
                      Add new Address
                    </button>
                  </div>
                  {/* <p className="text-primary ">
                  Balance:{coinBal} {network}
                </p> */}
                  {errors ? (
                    <p className="text-danger">
                      Select any whitelist address / add new Address
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="tranx-payment-details  border p-3 m-1">
                <h4 className="custom-primary">Step 2.</h4>
                <div className="tranx-payment-info d-flex align-items-center">
                  <span className="tranx-copy-feedback copy-feedback"></span>
                  <div className="icon-inside-input2">
                    <em
                      className={
                        network === "bitcoin"
                          ? "fab fa-bitcoin text-warning"
                          : "fab fa-ethereum"
                      }
                    ></em>
                  </div>
                  <input
                    type="text"
                    className="tranx-payment-address"
                    disabled
                    value={dollar.toFixed(7)}
                  />
                  <abbr title="Copy to Clipboard">
                    {" "}
                    <button
                      className="tranx-payment-copy copy-clipboard-modal"
                      onClick={(e) => {
                        navigator.clipboard.writeText(dollar.toFixed(7));
                      }}
                    >
                      <em className="ti ti-files"></em>
                    </button>
                  </abbr>
                </div>
                <h6 className="text-primary mt-1">
                  Please make your Payment to the below Address.
                </h6>
                <div className="tranx-payment-info">
                  <span className="tranx-copy-feedback copy-feedback"></span>
                  <AccountBalanceWalletIcon
                    sx={{ position: "absolute", margin: "10px" }}
                  />
                  <input
                    type="text"
                    className="tranx-payment-address"
                    disabled
                    value={
                      network === "bitcoin"
                        ? process.env.REACT_APP_BTC_PUBLIC_ADDRESS
                        : process.env.REACT_APP_ETH_PUBLIC_ADDRESS
                    }
                  />
                  <abbr title="Copy to Clipboard">
                    {" "}
                    <button
                      className="tranx-payment-copy copy-clipboard-modal"
                      onClick={(e) => {
                        navigator.clipboard.writeText(
                          network === "bitcoin"
                            ? process.env.REACT_APP_BTC_PUBLIC_ADDRESS
                            : process.env.REACT_APP_ETH_PUBLIC_ADDRESS
                        );
                      }}
                    >
                      <em className="ti ti-files"></em>
                    </button>
                  </abbr>
                </div>
                <p className="mt-2">Or Scan below QR Code</p>
                <div className="text-center">
                  <QRCodeSVG
                    value={
                      network === "bitcoin"
                        ? process.env.REACT_APP_BTC_PUBLIC_ADDRESS
                        : process.env.REACT_APP_ETH_PUBLIC_ADDRESS
                    }
                  />
                </div>
              </div>
              <div className="tranx-payment-details border p-3 m-1">
                <h4 className="custom-primary">Step 3.</h4>
                <h6>
                  Click Done on the email sent to your registered email account.
                  Go and click the link. Fill in all the information on the
                  payment page
                </h6>
              </div>
            </div>
            <div className="input-item text-start mt-2">
              <input
                className="input-checkbox"
                id="term-condition"
                type="checkbox"
                name="agreement"
                onChange={handleSelect}
              />
              <label
                for="term-condition"
                className="mt-2"
                style={{ fontSize: "13px", fontWeight: "lighter" }}
              >
                <p>
                  I hereby declare that I have done the payment to the given
                  address with my selected whitelisted address. Otherwise, my
                  payments can be lost.
                </p>
              </label>
              {errors ? (
                <p className="text-danger mt-2">
                  <span className="mr-2">
                    <ErrorIcon />
                  </span>
                  <span>
                    Error ! select whiteList address or mark terms and
                    conditions.
                  </span>
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className="text-center mb-3 mt-3">
              <button
                className="btn btn-primary"
                onClick={handleBTCETHTransaction}
              >
                {loading ? (
                  <ClipLoader color="white" />
                ) : (
                  <>I have made the payment</>
                )}
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
}
export default BTCInstruction;
