import React from "react";
import { Suspense, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
// import { ClimbingBoxLoader } from "react-spinners";
import UserLayout from "../src/Layouts/UserLayouts";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Transactions from "../src/UserDashboard/transactionInformation/transactions";
import AccountInfo from "../src/UserDashboard/accountInformation/accountInfo";
import Dashboard from "./UserDashboard/userdashboard/admin_panel";
import KycApplication from "./UserDashboard/KYC/kyc_application";
import Security from "../src/UserDashboard/security";
// import SideNav from '../src/UserDashboard/sidenav'
import HowToBuy from "../src/UserDashboard/how_to_buy";
import Home from "../src/UserDashboard/HomePage/HomePage";
import FAQS from "../src/UserDashboard/faqs";
import AlertToaster from "./components/Comps-Utils/Toast";
// import HomePage from './UserDashboard/HomePage'
import Activity from "./UserDashboard/Activity/Activity";
import BuyTokens from "./UserDashboard/BuyTokens/BuyTokens";
import KycVerification from "./UserDashboard/KycVerification/KycVerification";
// import Kyc from './components/Kyc'
import KycSuccess from "./UserDashboard/KYC/kyc-success";
// payment
import Payment from "./UserDashboard/Payment/PaymentCreditCard";
import Referral from "./UserDashboard/Referral/referral";
import PaymentBTC from "./UserDashboard/Payment/PaymentBtc";
import { QueryClient, QueryClientProvider } from "react-query";
import ClaimTokens from "./UserDashboard/claimTokens/claim";
import useSaleSetter from "./CustomHooks/Sale";
import Setupwallet from "./UserDashboard/SetupWallet/setupwallet";
import KycFailPage from "./UserDashboard/KycVerification/KycFailPage";
export const KycContext = React.createContext("");
const queryClient = new QueryClient();
function UserRoutes(props) {
  const { setTostify, tostify } = props;
  // eslint-disable-next-line
  const sale = useSaleSetter();
  // eslint-disable-next-line
  const location = useLocation();
  useEffect(() => {
    tostify.dismissAll();
    setTostify({ ...tostify, visible: false, key: "", type: "", message: "" });
    // eslint-disable-next-line
  }, [location.pathname]);

  const [kycStatus, setKycStatus] = useState("pending");
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };
  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.3,
  };
  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);
    return (
      <>
        <AnimatePresence>
          {
            show
            // && (
            //   <motion.div
            //     key="loading"
            //     initial={{ opacity: 0 }}
            //     animate={{ opacity: 1 }}
            //     exit={{ opacity: 0 }}
            //     transition={{ duration: 0.4 }}
            //   >
            //     <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
            //       <div className="d-flex align-items-center flex-column px-4">
            //         <ClimbingBoxLoader color={"#f64b1c"} loading={true} />
            //       </div>
            //       <div className="text-muted font-size-xl text-center pt-3">
            //         Please wait
            //       </div>
            //     </div>
            //   </motion.div>
            // )
          }
        </AnimatePresence>
      </>
    );
  };

  const TostifyWait = () => {
    const [showTostify, setShowTostify] = useState(false);
    useEffect(() => {
      let timeout = "";
      if (location.pathname === "/dashboard") {
        // eslint-disable-next-line no-use-before-define
        // eslint-disable-next-line
        let timeout = setTimeout(() => setShowTostify(true), 5000);
      } else {
        // eslint-disable-next-line no-use-before-define
        // eslint-disable-next-line
        let timeout = setTimeout(() => setShowTostify(true), 300);
      }
      return () => {
        clearTimeout(timeout);
      };
    }, []);
    return (
      <>
        {showTostify && (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <AlertToaster setTostify={setTostify} {...tostify} />
          </motion.div>
        )}
      </>
    );
  };
  return (
    <>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <TostifyWait />
          <Switch>
            <Route path="/paymentCredit" component={Payment} />
            <Route path="/paymentManual" exact component={PaymentBTC} />
            <Redirect exact from="/" to="/home" />
            <Route path={["/home"]}>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                  style={{ height: "100%" }}
                >
                  <Route path="/home" component={Home} />
                </motion.div>
              </Switch>
            </Route>
            <Route
              path={[
                "/dashboard",
                "/kyc",
                "/security",
                "/account",
                "/how-to-buy",
                "/faqs",
                "/transactions",
                "/activity",
                "/buytokens",
                "/kyc-verification",
                "/claim",
                "/setupwallet",
                "/referral",
              ]}
            >
              <KycContext.Provider value={[kycStatus, setKycStatus]}>
                <UserLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}
                      style={{ height: "100%" }}
                    >
                      <Route path="/dashboard" component={Dashboard} />
                      <Route path="/security" component={Security} />
                      <Route path="/account" component={AccountInfo} />
                      <Route path="/how-to-buy" component={HowToBuy} />
                      <Route path="/faqs" component={FAQS} />
                      <Route path="/transactions" component={Transactions} />
                      <Route path="/claim" component={ClaimTokens} />
                      <Route path="/activity" component={Activity} />

                      <Route path="/setupwallet" component={Setupwallet} />
                      {kycStatus !== "pending" &&
                      kycStatus !== "in progress" ? (
                        // &&
                        // kycStatus !== "in progress"
                        <>
                          <Route
                            path="/kyc-verification"
                            component={KycSuccess}
                          />
                          <Route path="/kyc" component={KycSuccess} />
                          <Route path="/kyc" component={KycApplication} />
                          <Route path="/referral" component={Referral} />
                          <QueryClientProvider client={queryClient}>
                            <Route path="/buytokens" component={BuyTokens} />
                          </QueryClientProvider>
                        </>
                      ) : (
                        <>
                          <Route
                            path="/kyc-verification"
                            component={KycVerification}
                          />
                          <Route path="/kyc" component={KycApplication} />
                          <Route path="/referral" component={KycFailPage} />
                          <QueryClientProvider client={queryClient}>
                            <Route path="/buytokens" component={KycFailPage} />
                          </QueryClientProvider>
                        </>
                      )}
                    </motion.div>
                  </Switch>
                </UserLayout>
              </KycContext.Provider>
            </Route>
            <Route render={() => <Redirect to="/dashboard" />} />
          </Switch>
        </Suspense>
      </AnimatePresence>
    </>
  );
}
export default UserRoutes;
