import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DialogBox = (props) => {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(props.visible);
  }, [props.visible, props.message, props.header]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        id="dialog-component"
        open={open}
        onClose={handleClose}
        classes={{ paper: "shadow-sm-dark rounded-sm" }}
      >
        <div className="text-center p-5" id="dbox">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
              <FontAwesomeIcon
                icon={["far", "question-circle"]}
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">{props.header}</h4>
          <p className="mb-0 text-black-50">{props.message}</p>
          <div className="pt-4">
            <Button
              onClick={handleClose}
              className="btn-neutral-secondary btn-pill text-danger mx-1"
              id="dbox-cancel"
            >
              <span className="btn-wrapper--label">No</span>
            </Button>
            <Button
              onClick={() => {
                props.ok();
                handleClose();
              }}
              className="btn-first btn-pill mx-1"
              id="dbox-ok"
            >
              <span className="btn-wrapper--label">Yes</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default DialogBox;
