import TextField50 from "../Textfield50";
import TextFiledLabelRequired from "../TextfieldLabelRequired";
import { ConnectedFocusError } from "focus-formik-error";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { ICOTokenName } from "../../../Services/Admin/Web3call/contractCall";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import Web3 from "web3";
const LogoForm = (props) => {
  const {
    touched,
    errors,
    values,
    handleChange,
    formFields,

    mintFields,
    setMintFields,
    setValuesTemp,
    tab,
    setFieldError,
    setFieldValue,
  } = props;
  //   useEffect(() => {
  //     setValuesTemp(values);
  //   }, [values]);

  const addFields = () => {
    setValuesTemp(values);
    setMintFields([...mintFields, 0]);
  };
  const minusFields = () => {
    let tempArr = [...mintFields];
    if (tempArr.length > 1) {
      tempArr.pop();
    }
    setMintFields(tempArr);
  };
  const getName = async (index) => {
    // let result = "";
    if (Web3.utils.isAddress(values["address" + index])) {
      const result = await ICOTokenName(values["address" + index]);
      setFieldValue("status" + index, true);
      setFieldValue("name" + index, result);
    } else {
      // result = "Invalid Address";
      setFieldError("address" + index, "Not A Verified Address");
      setFieldValue("status" + index, false);
      setFieldValue("name" + index, "");
    }
  };
  useEffect(() => {
    if (
      tab === "Payment Tokens" ||
      tab === "Early Minting" ||
      tab === "Minting (Uncapped)"
    ) {
      mintFields.map((v, index) => {
        return getName(index);
      });
    }
    // eslint-disable-next-line
  }, [values]);

  return (
    <>
      <form autoComplete="off" className="HHH">
        <ConnectedFocusError />
        <div className="card-common-style block-content block-content-full">
          <div className="">
            <div className="row items-push">
              {/* <div>
                <div className="d-flex justify-content-center">
                  <div className="w45">
                    <TextFiledLabelRequired
                      widthclass="d-flex justify-content-center"
                      field={"Address"}
                    />
                  </div>
                  <div className="w45">
                    {tab === "Payment Tokens" ? null : (
                      <TextFiledLabelRequired
                        widthclass="d-flex justify-content-center"
                        field={"Amount"}
                      />
                    )}
                  </div>
                </div>
              </div> */}
              {formFields.map((item, index) => {
                return (
                  <div>
                    <div className="d-flex justify-content-between flex-md-row flex-column align-items-end">
                      <div className="ico-input-w45">
                        <div className="d-flex justify-content-center w-100 mb-lg-3 mb-sm-2 mb-1">
                          <TextFiledLabelRequired
                            widthclass="d-flex justify-content-center ico-label"
                            field={"Address"}
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          {item && item.minus ? (
                            <div>
                              {" "}
                              <IconButton
                                color="primary"
                                aria-label="add to shopping cart"
                                onClick={() => {
                                  minusFields();
                                }}
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-minus"
                                  className={"font-size-h3 text-xplay-lighter"}
                                />
                              </IconButton>
                            </div>
                          ) : null}
                          <div
                            className={
                              tab === "Payment Tokens"
                                ? "w-100 mx-2"
                                : "w-100 mx-2"
                            }
                          >
                            <TextField50
                              widthclass=""
                              fieldName={item.fieldName}
                              handleChange={handleChange}
                              values={values}
                              touched={touched}
                              errors={errors}
                              place={item.place}
                              type={item.type}
                              tab={tab}
                              index={index}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="ico-input-w45">
                        <div className="d-flex justify-content-center w-100 mb-lg-3 mb-sm-2 mb-1">
                          {tab === "Payment Tokens" ? null : (
                            <TextFiledLabelRequired
                              widthclass="d-flex justify-content-center ico-label"
                              field={"Amount"}
                            />
                          )}
                        </div>
                        <div className="d-flex align-items-center row-reverse">
                          <div className="w-100 mx-2">
                            {
                              <div className="w-100">
                                {item && item.field1 ? (
                                  <>
                                    {item.field1 &&
                                    item.type1 === "status w-100" ? null : (
                                      <div className="w-100">
                                        <TextField50
                                          widthclass=""
                                          fieldName={item.fieldName1}
                                          handleChange={handleChange}
                                          values={values}
                                          touched={touched}
                                          errors={errors}
                                          place={item.place1}
                                          type={item.type1}
                                        />
                                      </div>
                                    )}
                                  </>
                                ) : null}
                              </div>
                            }
                          </div>
                          <div>
                            {item && item.plus ? (
                              <div>
                                {" "}
                                <IconButton
                                  color="primary"
                                  aria-label="add to shopping cart"
                                  onClick={() => {
                                    addFields();
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-plus"
                                    className={
                                      "font-size-h3 text-xplay-lighter"
                                    }
                                  />
                                </IconButton>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {tab === "" ? (
                        <div className="w45">
                          {item.field1 && item.type1 === "status" ? (
                            <>
                              {values[item.fieldName1] ? (
                                <CheckCircleOutlinedIcon color="success" />
                              ) : (
                                <CancelOutlinedIcon color="error" />
                              )}
                            </>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default LogoForm;
