import React, { useContext, useEffect, useState } from "react";
import { ToasterContext } from "../../App";
import Clip from "../../components/Comps-Utils/ClipLoader";
import { GetUserWallet, UpdateUserWallet } from "../../Services/User/User/user";
// import { useAccount } from "wagmi";

function Setupwallet() {
  const [tostify, setTostify] = useContext(ToasterContext);
  const [walletInfo, setWalletInfo] = useState({
    walletAddress1: "",
  });
  const [errors, setError] = useState({
    walletAddress1: "",
  });
  const [loading, setLoading] = useState(false);
  const user =
    localStorage.getItem("user") === "undefined"
      ? { email: "", username: "", _id: "" }
      : JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    fetchWalletData();
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setWalletInfo({ ...walletInfo, [name]: value });
  };

  const fetchWalletData = async () => {
    const data = await GetUserWallet();
    setWalletInfo({
      walletAddress1: data.data.walletAddress,
    });
  };

  const checkValidation = () => {
    let errors = {};
    if (walletInfo.walletAddress1 === "") {
      errors.walletAddress1 = "wallet Address is empty";
    }
    const walletRegex = new RegExp(/^0x[a-fA-F0-9]{40}$/g);
    if (!walletRegex.test(walletInfo.walletAddress1)) {
      errors.walletAddress1 = "Invalid  wallet Address";
    }

    if (Object.keys(errors).length > 0) {
      setError(errors);
      return false;
    } else {
      setError({});
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = checkValidation();

    if (validate) {
      setLoading(true);
      const wallet = await UpdateUserWallet(walletInfo);

      if (wallet.code === 200) {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "success",
          message: "Successfully Updated",
        });
        user.walletAddress = walletInfo.walletAddress1;
        localStorage.setItem("user", JSON.stringify(user));
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message: "Error Server/network ",
        });
      }
    } else {
      // console.log("failed");
    }
  };

  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2 className="user-panel-title">How to Setup Wallet</h2>
          <div className="user-panel-content">
            <p>
              {/* <strong>
                Investors really are spoiled for choice. There are a lot of
                serious ICOs raising funds for important and useful projects, At
                the same time.
              </strong> */}
            </p>
            <div>
              <h4>About Wallets and Recruit Coin tokens</h4>
              <p>
                A crypto wallet keeps track of your crypto accounts and allows
                you to approve transfers to and from other accounts. You need a
                wallet to participate in the Recruit Coin ICO.
              </p>
              <p>
                Recruit Coins are{" "}
                <a
                  href="https://ethereum.org/en/developers/docs/standards/tokens/erc-20/"
                  target="_black"
                  rel="noreferrer"
                >
                  Ethereum ECR-20
                </a>{" "}
                compatible tokens. Your crypto wallet must be capable of holding
                ECR-20 tokens.
              </p>
              <p>
                Recruit Coins are managed on the Polygon block chain. Polygon is
                a layer 2 chain which is much faster and less expensive than the
                main Ethereum chain. Layer 2 transactions are rolled-up and
                stored on the main Ethereum chain for security. If your Crypto
                wallet does not list the Polygon network, you must add it using
                these values:
                <ul className="metamask-info">
                  <li>Name: Polygon Mainnet</li>
                  <li>
                    New PRC URL:{" "}
                    <a
                      href="https://polygon-rpc.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://polygon-rpc.com{" "}
                    </a>
                  </li>
                  <li>Chain ID: 137 </li>
                  <li>Currency Symbol: MATIC</li>
                  <li>
                    Block Explorer URL:{" "}
                    <a
                      href="https://polygonscan.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://polygonscan.com/
                    </a>
                  </li>
                </ul>
              </p>
              <p>
                You need to Connect your wallet to this application so you can:
                <ul>
                  <li>
                    (optional) Spend your existing tokens to invest in Recruit
                    Coins. If you are using a credit card, this step is not
                    required.
                  </li>
                  <li>
                    Claim your Recruit Coin tokens from Betts (after vesting)
                  </li>
                </ul>
              </p>
              <p>
                Recruit Coins are released to investors based on a vesting
                schedule. This is typical of ICO’s to help prevent early
                investors from manipulating the price by controlling a large
                amount of coins.
              </p>
            </div>
            <div>
              <h4>I Already Have an Ethereum ECR-20 Compatible Wallet</h4>
              <p>
                <ul>
                  <li>
                    Great, you just need to click the “Connect Wallet” button in
                    the upper right corner of this page to associate your wallet
                    with this application (known as a dApp - distributed
                    application in crypto speak).
                  </li>
                  <li>
                    Make sure your wallet is pointing to the Polygon Mainnet
                    (main network). You may need to add this to the list of
                    networks your wallet knows about. The values needed are
                    listed above in the “About Wallets and the Recruit Coin
                    tokens” section.
                  </li>
                </ul>
              </p>
            </div>
            <div>
              <h4>I Don’t have an ECR-20 Compatible Wallet</h4>
              <p>
                <ul className="list-group mb-3">
                  <li className="list-group-item mb-3">
                    No problem, a wallet is easy to create. We recommend using{" "}
                    <a
                      href="https://metamask.io/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      MetaMask
                    </a>
                    , one of the most popular software wallets. Be absolutely
                    sure you have backed up your Recovery Phrase in a safe,
                    offline, location before using your wallet!
                  </li>
                  <li>
                    Your new wallet will have 1 account by default. You can add
                    additional accounts if you desire.
                  </li>
                  <li>
                    You must configure MetaMask to point to the Polygon Mainnet
                    (main network). There is a dropdown at the top of the
                    MetaMask browser extension showing a list of networks. If
                    Polygon is not listed, you will need to “Add Network”. The
                    values needed are listed above in the “About Wallets and the
                    Recruit Coin tokens” section.
                  </li>
                  <li>
                    Click the “Connect Wallet” button in the upper right corner
                    of this page to associate your wallet with this application
                    (known as a dApp - distributed application in crypto speak).
                  </li>
                </ul>
              </p>
            </div>
            <div className="input-item input-with-label">
              <label htmlFor="token-address" className="input-item-label">
                Your Address for tokens:
              </label>
              <input
                className="input-bordered"
                type="text"
                id="token-address"
                value={walletInfo.walletAddress1}
                name="walletAddress1"
                onChange={handleChange}
              />
              <div className="error">{errors.walletAddress1}</div>
              <span className="input-note">
                Note: Address should be ERC20-compliant.
              </span>
            </div>

            <div className="d-sm-flex justify-content-between align-items-center">
              <button
                className="btn btn-primary d-flex justify-content-center align-items-center"
                onClick={handleSubmit}
              >
                {loading ? <Clip /> : <>Update</>}
              </button>
              <div className="gaps-2x d-sm-none"></div>
              {/* <span className='color-success'>
            <em className='ti ti-check-box'></em> Saved your wallet address
          </span> */}
            </div>
            {/* <img src="images/setupsuccess.png" alt="human" /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Setupwallet;
