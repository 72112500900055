/* eslint-disable */
import * as Yup from "yup";
import moment from "moment";
export const UserInitialValues = {
  id: "",
  address1: "",
  address2: "",
  city: "",
  dob: "",
  email: "",
  firstname: "",
  lastname: "",
  nationality: "",
  phone: "",
  zip: "",
  telegram: "",
  walletAddress: "",
  walletNetwork: "",
  upload: "",
  upload1: "",
  type: "",
};
export const setInitialValues = (updatedRow) => {
  const Values = {
    id: updatedRow.id ? updatedRow.id : "",
    firstname: updatedRow.id ? updatedRow.firstname : "",
    lastname: updatedRow.id ? updatedRow.lastname : "",
    email: updatedRow.id ? updatedRow.email : "",
    phone: updatedRow.id ? updatedRow.phone : "",
    dob: updatedRow.id ? moment(updatedRow.dob).format("yyyy-MM-DD") : "",
    nationality: updatedRow.id
      ? updatedRow.nationality
      : { code: "PK", label: "Pakistan", phone: "92" },
    address1: updatedRow.id ? updatedRow.address1 : "",
    address2: updatedRow.id ? updatedRow.address2 : "",
    city: updatedRow.id ? updatedRow.city : "",
    zip: updatedRow.zip ? updatedRow.zip : "",
    telegram: updatedRow.telegram ? updatedRow.telegram : "",
    // walletAddress: updatedRow.id ? updatedRow.walletAddress : '',
    // walletNetwork: updatedRow.id ? updatedRow.walletNetwork : '',
    walletAddress: "",
    walletNetwork: "BSC",
    upload: "",
    upload1: "",
    type: "",
  };
  return Values;
};
export const uploadInitialValue = {
  upload: "",
};
export const KycGeneralSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, "Name must be at least 3 characters.")
    .required("First Name is required!"),
  lastname: Yup.string()
    .min(3, "Last Name  be at least 3 characters.")
    .required("Last Name is required!"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  phone: Yup.string()
    .required("Phone Number is required")
    .matches(/^[0-9]{10}$/, "Phone Number must be 10 digits"),
  dob: Yup.string()
    .required("Date of Birth is required!")
    .test(
      "dob",
      "You must be 18 years or older to sign up",
      (date) => moment().diff(moment(date), "years") >= 18
    ),
  nationality: Yup.object()
    .required("Please Select Your Nationality!")
    .nullable(),
  address1: Yup.string()
    .min(5, "Address must be at least 5 characters.")
    .required("Address is required!"),
  city: Yup.string()
    .min(3, "City must be at least 3 characters.")
    .required("City is required!"),
  zip: Yup.number()
    .typeError("Zip Code must be a Number")
    .min(3, "City must be at least 3 characters.")
    .required("Zip Code is required!")
    .positive()
    .integer(),
});
export const GeneralFormFields = [
  {
    field: "First Name",
    fieldName: "firstname",
    place: "Enter First Name",
    type: "text",
    field2: "Last Name",
    fieldName2: "lastname",
    place2: "Enter Last Name",
    type2: "text",
  },
  {
    field: "Email Address",
    fieldName: "email",
    place: "Enter Email Address",
    type: "email",
    field2: "Nationality",
    fieldName2: "nationality",
    place2: "Select Nationality",
    type2: "text",
  },
  {
    field: "Date of Birth",
    fieldName: "dob",
    place: "Enter Date of Birth",
    type: "date",
    field2: "Phone",
    fieldName2: "phone",
    place2: "Enter Phone Number",
    type2: "text",
  },
  {
    field: "Address Line 1",
    fieldName: "address1",
    place: "Enter Address",
    type: "text",
    field2: "Address Line 2",
    fieldName2: "address2",
    place2: "Enter Address",
    type2: "text",
  },
  {
    field: "City of Residence",
    fieldName: "city",
    place: "Enter City",
    type: "text",
    field2: "Zip Code",
    fieldName2: "zip",
    place2: "Enter Zip Code",
    type2: "text",
  },
  {
    field: "Telegram Username",
    fieldName: "telegram",
    place: "Enter Telegram User Name",
    type: "text",
  },
];
export const KycSettingsSchema = Yup.object().shape({
  // walletNetwork: Yup.string().required("Wallet Network is required"),
  walletAddress: Yup.string()
    .required("Wallet Address is required")
    .matches(/^0x[a-fA-F0-9]{40}$/g, "Please add a valid wallet address"),
});
export const SettingsFormFields = [
  // {
  //   field: "Wallet Network",
  //   fieldName: "walletNetwork",
  //   place: "Enter Wallet Network",
  //   type: "select",
  // },
  {
    field: "Wallet Address",
    fieldName: "walletAddress",
    place: "Enter Wallet Address",
    type: "text",
  },
];
export const walletNetworkValue = ["Ethereum", "BSC", "Polygon"];
export const createRows = (rowData) => {
  const rows = rowData.map((i, index) => {
    return {
      userId: index + 1,
      id: i._id,
      address1: i.address1,
      address2: i.address2,
      city: i.city,
      dob: moment(i.dob).format("MM DD YYYY"),
      date: i.date,
      email: i.email,
      emailStatus: i.emailStatus,
      name:
        (i.firstname + " " + i.lastname).trim() === ""
          ? "..."
          : i.firstname + " " + i.lastname,
      firstname: i.firstname,
      lastname: i.lastname,
      lastLogin: i.createdAt,
      kycstatus: i.kycstatus,
      license: i.license,
      nationalId: i.nationalId,
      nationality: i.nationality,
      passport: i.passport,
      phone: i.phone,
      profileImgUrl: i.profileImgUrl,
      username: i.username,
      userstatus: i.userstatus,
      walletAddress: i.walletAddress,
      walletNetwork: i.walletNetwork,
      zip: i.zip,
      telegram: i.telegram,
      // dob: updatedRow.id ? updatedRow.dob : '',
      // nationality: updatedRow.id ? updatedRow.nationality : '',
      // address1: updatedRow.id ? updatedRow.address1 : '',
      // address2: updatedRow.id ? updatedRow.address2 : '',
      // city: updatedRow.id ? updatedRow.city : '',
      // zip: updatedRow.zip ? updatedRow.zip : '',
      // telegram: updatedRow.id ? updatedRow.telegram : '',
      // // walletAddress: updatedRow.id ? updatedRow.walletAddress : '',
      // // walletNetwork: updatedRow.id ? updatedRow.walletNetwork : '',
      // walletAddress:"12345",
      // walletNetwork:"123521"
    };
  });
  return rows;
};
export const rowsPerPage = 100;
export const UserKycTableHeadings = [
  {
    id: "id",
    label: "id",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "user",
    label: "User",
    sort: true,
    class: "bg-body-light",
  },
  // {
  //   id: "userEmail",
  //   label: "Email",
  //   sort: true,
  //   class: "bg-body-light",
  // },
  // {
  //   id: "maticAddress",
  //   label: "Matic Address",
  //   sort: true,
  //   class: "bg-body-light",
  // },
  {
    id: "lastLogin",
    label: "Last Login",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "User Status",
    label: "User Status",
    class: "bg-body-light",
  },
  {
    id: "Change Status",
    label: "Change Status",
    class: "bg-body-light",
  },
  {
    id: "actions",
    label: "Actions",
    class: "bg-body-light text-left",
  },
];
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const SetPermissionsHelpers = (rights) => {
  let pathNames = {};
  Object.values(rights).map((item) => {
    if (item.name === "kyc") {
      pathNames = { ...pathNames, userStatus: item.userStatus };
    }
    if (item.name === "kyc") {
      pathNames = { ...pathNames, kycStatus: item.kycStatus };
    }
    if (item.name === "kyc") {
      pathNames = { ...pathNames, viewKyc: item.viewKyc };
    }
    if (item.name === "kyc") {
      pathNames = { ...pathNames, editKyc: item.editKyc };
    }
    if (item.name === "kyc") {
      pathNames = { ...pathNames, deleteKyc: item.deleteKyc };
    }
    if (item.name === "kyc") {
      pathNames = { ...pathNames, addKyc: item.addKyc };
    }
  });
  return pathNames;
};
