import React from "react";
import { useState, useMemo, useEffect, useContext } from "react";
import NewWalletAddress from "./newWallet";
import Notification from "./notification";
import {
  UpdateUserInfo,
  FetchUserInfo,
  FetchUserProfileImg,
} from "../../Services/User/User/user.js";
import Clip from "../../components/Comps-Utils/ClipLoader";
import ImageUploader from "./imageuploader";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import countryList from "react-select-country-list";
import { ImageContext } from "../../App";
import moment from "moment";
import { ToasterContext } from "../../App";
import Select from "react-select";
import { useLocation } from "react-router";
import querystring from "query-string";
function AccountInfo() {
  const [upload, setUpload] = useState("");
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const location = useLocation();
  const sub = querystring.parse(location.search).tab;

  // eslint-disable-next-line
  const [profileImage, setProfileImage] = useContext(ImageContext);

  const [loading, setLoading] = useState(false);
  const [isAccount, setIsAccount] = useState(true);

  const user =
    localStorage.getItem("user") === "undefined"
      ? { email: "", username: "", _id: "" }
      : JSON.parse(localStorage.getItem("user"));

  const handleClickAccount = () => {
    // 👇️ toggle isActive state on click
    setIsAccount(false);
  };

  const options = useMemo(() => countryList().getData(), []);

  // eslint-disable-next-line no-loop-func
  const [img, setImg] = useState("");

  const [accountInfo, setAccountInfo] = useState({
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    dob: moment().format("yyyy-MM-DD"),
    address1: "",
    address2: "",
    city: "",
    nationality: { name: options && options[0].label },
    profileImgUrl: "",
    walletAddress: "",
    walletNetwork: "",
  });
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    dob: "",
    address1: "",
    address2: "",
    city: "",
    nationality: "",
    profileImgUrl: "",
  });

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, [AccountInfo]);

  useEffect(() => {
    if (sub === "wallet-address") {
      setIsAccount(false);
    }
  }, [sub]);

  useEffect(() => {
    // setImg(upload.preview);
    if (upload && upload.preview) {
      setImg(upload.preview);
    } else {
      setImg(profileImage);
    }
  }, [upload]);
  const fetchUser = async () => {
    // const user = JSON.parse(localStorage.getItem('user'))

    const user = await FetchUserInfo();

    if (user.code === 200) {
      const {
        username,
        firstname,
        lastname,
        phone,
        dob,
        address1,
        address2,
        city,
        nationality,
        profileImgUrl,
        email,
        walletAddress,
        walletNetwork,
      } = user.data;
      setAccountInfo({
        ...accountInfo,
        username: username ? username : "",
        firstname: firstname ? firstname : "",
        lastname: lastname ? lastname : "",
        email: email ? email : "",
        phone: phone ? phone : "",
        dob: dob ? moment(dob).format("yyyy-MM-DD") : "",
        address1: address1 ? address1 : "",
        address2: address2 ? address2 : "",
        city: city ? city : "",
        nationality:
          nationality !== undefined
            ? nationality
            : { name: options && options[0].label },
        profileImgUrl: profileImgUrl ? profileImgUrl : "",
        walletAddress: walletAddress ? walletAddress : "",
        walletNetwork: walletNetwork ? walletNetwork : "",
      });

      if (user.data.profileImgUrl !== undefined) {
        // setImg(process.env.REACT_APP_BASE_URL + "/" + user.data.profileImgUrl);
        // setProfileImage(
        //   process.env.REACT_APP_BASE_URL + "/" + user.data.profileImgUrl
        // );
        // setImg(user.data.profileImgUrl);
        // setProfileImage(
        //    user.data.profileImgUrl
        // );
        fetchProfileImage(user.data.profileImgUrl);
      }

      // setUpload(user.data.profileImgUrl)
    } else if (user.code === 400) {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: "Unauthenticated",
      });
    } else {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: user?.message,
      });
    }
  };

  const checkValidation = (e) => {
    let errors = {};
    // const regExp = new RegExp(
    //   // eslint-disable-next-line
    //   /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    // )

    if (accountInfo.firstname === "") {
      errors.firstname = "first name is required";
    }
    if (accountInfo.lastname === "") {
      errors.lastname = "last name is required";
    }
    // if (accountInfo.email === '') {
    //   errors.email = 'email is empty'
    // } else if (!regExp.test(accountInfo.email)) {
    //   errors.email = 'email is invaid'
    // }
    if (accountInfo.nationality === "") {
      errors.nationality = "country is required";
    }

    if (accountInfo.city === "") {
      errors.city = "city is required";
    }

    if (accountInfo.phone === "") {
      errors.phone = "phone number is empty";
    } else if (!isValidPhoneNumber(accountInfo.phone)) {
      errors.phone = "phone number is invalid";
    }
    if (accountInfo.dob === "") {
      errors.dob = "date of birth is required";
    } else {
      var dob = accountInfo.dob;
      dob = dob.split("-");
      var bday_in_milliseconds = new Date(
        parseInt(dob[0], 10),
        parseInt(dob[1], 10) - 1,
        parseInt(dob[1]),
        10
      ).getTime();
      var now = new Date().getTime();
      if (now - bday_in_milliseconds < 568024668000) {
        errors.dob = "date of birth is less than 18";
      }
    }

    if (accountInfo.address1 === "") {
      errors.address1 = "address is required";
    }

    // if (accountInfo.zip === '') {
    //   errors.zip = 'zip code is empty'
    // }
    //  else if (accountInfo.zip < 4) {
    //   errors.zip = 'zip code is less than 5 digitals'
    // }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const handleChange = (e, type) => {
    if (type === "phone") {
      setAccountInfo({ ...accountInfo, phone: e });
    } else if (type === "country") {
      const country = { name: e };
      setAccountInfo({ ...accountInfo, nationality: country });
    } else if (e?.target?.name) {
      setAccountInfo({ ...accountInfo, [e.target.name]: e.target.value });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = checkValidation();
    if (validate) {
      setLoading(true);

      const user = await UpdateUserInfo(accountInfo, upload);

      if (user.code === 200) {
        setLoading(false);
        setAccountInfo(user.data);
        // setProfileImage(
        //   process.env.REACT_APP_BASE_URL + user.data.profileImgUrl
        // );
        fetchProfileImage(user.data.profileImgUrl);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "success",
          message: "Successfully Updated",
        });
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message: user.message,
        });
      }
    } else {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: "Some fields have errors",
      });
    }
  };
  const addDefaultSrc = (ev) => {
    ev.target.src = "images/user-thumb-lg.png";
  };

  const fetchProfileImage = async (key) => {
    // const userInfo = JSON.parse(localStorage.getItem("user"))
    const profile = await FetchUserProfileImg(key);
    if (
      profile.data !== undefined ||
      profile.data !== "" ||
      profile.data.data !== 400
    ) {
      // setProfileImage(process.env.REACT_APP_BASE_URL + profile.data);
      // setImg(process.env.REACT_APP_BASE_URL + profile.data);
      setProfileImage(profile.data);
      setImg(profile.data);
    } else {
      setProfileImage("images/user-thumb-lg.png");
    }
  };

  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2 className="user-panel-title">Account Information</h2>

          <ul className="nav nav-tabs nav-tabs-line" role="tablist">
            <li className="nav-item">
              <a
                className={`nav-link ${
                  sub !== "wallet-address" ? "active" : ""
                }`}
                data-bs-toggle="tab"
                href="#personal-data"
              >
                Personal Data
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link pr-2 ${
                  sub == "wallet-address" ? "active" : ""
                } `}
                data-bs-toggle="tab"
                href="#wallet-address"
                onClick={handleClickAccount}
              >
                Wallet Address
                {!user.walletAddress && isAccount ? (
                  <>
                    <div className="badge-without-number with-wave"></div>
                  </>
                ) : (
                  ""
                )}
              </a>
            </li>
            {/* <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#notification'>
                Notification
              </a>
            </li> */}
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                sub !== "wallet-address" ? "show active" : ""
              } `}
              id="personal-data"
            >
              <form action="#">
                <div className="avater-container input-item input-with-label d-flex justify-content-between align-items-center flex-wrap">
                  <div className="avatar-img-contain input-item input-with-label d-flex flex-col">
                    <div className="avater-img">
                      <img
                        src={img ? img : ""}
                        onError={addDefaultSrc}
                        alt="profile-img"
                        width="80"
                        height="80"
                      ></img>
                    </div>
                    <div className="ml-sm-3 ml-2 mt-sm-2 avatar-info text-truncate">
                      <h4 className="text-primary m-0">
                        {accountInfo.username}
                      </h4>

                      <h6 className="text-secondary text-truncate">
                        {accountInfo.email}
                      </h6>
                    </div>
                  </div>
                  <div className="upload-btn">
                    <ImageUploader upload={upload} setUpload={setUpload} />
                  </div>
                </div>

                <div className="input-item input-with-label">
                  <label htmlFor="full-name" className="input-item-label">
                    First Name
                  </label>
                  <input
                    className="input-bordered"
                    type="text"
                    id="full-name"
                    name="firstname"
                    placeholder="Enter your first name"
                    value={accountInfo.firstname}
                    onChange={handleChange}
                  />
                  <span className="error1">{errors.firstname}</span>
                </div>

                <div className="input-item input-with-label">
                  <label htmlFor="sur-name" className="input-item-label">
                    Last Name
                  </label>
                  <input
                    className="input-bordered"
                    type="text"
                    id="lastname"
                    name="lastname"
                    placeholder="Enter your last name"
                    value={accountInfo.lastname}
                    onChange={handleChange}
                  />
                  <span className="error1">{errors.lastname}</span>
                </div>
                <div className="input-item input-with-label">
                  <label htmlFor="email-address" className="input-item-label">
                    Email Address
                  </label>
                  <input
                    className="input-bordered"
                    type="text"
                    id="email-address"
                    name="email"
                    placeholder="Enter your email address"
                    value={accountInfo.email}
                    onChange={handleChange}
                    disabled
                  />
                  <span className="error1">{errors.email}</span>
                </div>
                <div className="input-item input-with-label">
                  <label htmlFor="phone-number" className="input-item-label">
                    Address 1
                  </label>
                  <input
                    className="input-bordered"
                    type="text"
                    id="phone-number"
                    placeholder="Enter your address "
                    name="address1"
                    value={accountInfo.address1}
                    onChange={handleChange}
                  />
                  <span className="error1">{errors.address1}</span>
                </div>
                <div className="input-item input-with-label">
                  <label htmlFor="phone-number" className="input-item-label">
                    Address 2
                  </label>
                  <input
                    className="input-bordered"
                    type="text"
                    id="phone-number"
                    name="address2"
                    placeholder="Enter your optional address 2 "
                    value={accountInfo.address2}
                    onChange={handleChange}
                  />
                  <span className="error1">{errors.address2}</span>
                </div>
                <div className="input-item input-with-label">
                  <label htmlFor="phone-number" className="input-item-label">
                    City
                  </label>
                  <input
                    className="input-bordered"
                    type="text"
                    id="phone-number"
                    placeholder="Enter your city name"
                    name="city"
                    value={accountInfo.city}
                    onChange={handleChange}
                  />
                  <span className="error1">{errors.city}</span>
                </div>
                <div className="input-item input-with-label">
                  <label htmlFor="nationality" className="input-item-label">
                    Current Nationality
                  </label>

                  {/* <select
                    className="input-bordered"
                    name="nationality"
                    style={{ border: "2px solid #d3e0f3" }}
                    id="nationality"
                    value={accountInfo.nationality}
                    onChange={handleChange}
                  >
                    {options.map((item, index) => {
                      return (
                        <option
                          value={item.label}
                          key={index}
                          name={item.label}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </select> */}
                  <Select
                    options={options}
                    name="nationality"
                    value={options?.label}
                    onChange={(value) => handleChange(value?.label, "country")}
                    isSearchable={true}
                    placeholder={accountInfo.nationality?.name}
                  />

                  <span className="error1">{errors.nationality}</span>
                </div>
                <div className="input-item input-with-label">
                  <label htmlFor="phone-number" className="input-item-label">
                    Phone Number
                  </label>
                  {/* <input
                    className="input-bordered"
                    type="number"
                    id="phone-number"
                    name="phone"
                    placeholder="Enter your phone number"
                    value={accountInfo.phone}
                    onChange={handleChange}
                  /> */}

                  <PhoneInput
                    placeholder="Enter phone number"
                    name="phone"
                    value={accountInfo.phone}
                    onChange={(value) => handleChange(value, "phone")}
                    error={
                      accountInfo.phone
                        ? isValidPhoneNumber(accountInfo.phone)
                          ? undefined
                          : "Invalid phone number"
                        : "Phone number required"
                    }
                  />

                  <span className="error1">{errors.phone}</span>
                </div>
                <div className="row">
                  <div className="input-item input-with-label">
                    <label htmlFor="date-of-birth" className="input-item-label">
                      Date of Birth:{" "}
                    </label>
                    <input
                      className="input-bordered date-picker"
                      type="date"
                      id="date-of-birth"
                      name="dob"
                      // defaultValue={new Date(
                      //   accountInfo.dob
                      // ).toLocaleDateString()}
                      onChange={handleChange}
                      value={accountInfo.dob}
                    />
                    <span className="error1">{errors.dob}</span>
                  </div>
                </div>
                <div className="gaps-1x"></div>
                <div className="d-sm-flex justify-content-between align-items-center">
                  <button
                    className="btn btn-primary account-updatr-btn"
                    onClick={handleSubmit}
                  >
                    {loading ? <Clip /> : <>Update</>}
                  </button>
                </div>
              </form>
            </div>

            <div
              className={`tab-pane fade ${
                sub == "wallet-address" ? "show active" : ""
              }`}
              id="wallet-address"
            >
              <NewWalletAddress accountInfo={accountInfo} />
            </div>
            <div className="tab-pane fade" id="notification">
              <Notification />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountInfo;
