/* eslint-disable */
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  ETHEREUM_ICON_WHITE_35,
  BITCOIN_ICON_WHITE_35,
  PERSON_ICON_WHITE_35,
  DAI_ICON_WHITE_35,
  TUSD_ICON_WHITE_35,
  USDT_ICON_WHITE_35,
  BUSD_ICON_WHITE_35,
} from "../../../helpers/ICONS";
import {
  PERSON_S,
  BITCOIN_S,
  ETHEREUM_S,
  DAI_S,
  TUSD_S,
  USDT_S,
  BUSD_S,
} from "../../../helpers/constants";
export default function CardComp(props) {
  const { label, bg, logo, investors } = props;
  return (
    <Card
      className={"mb-3 mr-3 p-0 col-md-2 cards-nth-color" + " " + bg}
      sx={{ minWidth: "30%", width: "100%", maxHeight: 90 }}
    >
      <CardContent>
        <div className="d-flex justify-content-between">
          <div>
            <Typography
              sx={{ fontSize: 20, fontWeight: 1000 }}
              color="#ffffff"
              gutterBottom
            >
              {investors}
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500 }}
              color="#ffffff"
              gutterBottom
            >
              {label}
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: 14 }} color="#ffffff" gutterBottom>
              {logo === PERSON_S ? (
                <PERSON_ICON_WHITE_35 />
              ) : logo === ETHEREUM_S ? (
                <ETHEREUM_ICON_WHITE_35 />
              ) : logo === BITCOIN_S ? (
                <BITCOIN_ICON_WHITE_35 />
              ) : logo === DAI_S ? (
                <DAI_ICON_WHITE_35 />
              ) : logo === TUSD_S ? (
                <TUSD_ICON_WHITE_35 />
              ) : logo === USDT_S ? (
                <USDT_ICON_WHITE_35 />
              ) : logo === BUSD_S ? (
                <BUSD_ICON_WHITE_35 />
              ) : null}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
