import Card from "./card";
import {
  BITCOIN_S,
  ETHEREUM_S,
  DAI_S,
  TUSD_S,
  USDT_S,
  BUSD_S,
} from "../../../helpers/constants";
export default function BasicCard(props) {
  const { CardsHeadings, investors } = props;
  return (
    <div className="d-flex dashboard-card mt-3 mb-3 flex-wrap">
      {CardsHeadings.map((c) => {
        return (
          <Card
            key={"cards" + c.label + Math.random().toString(36).substring(7)}
            label={c.label}
            bg={c.bg}
            logo={c.logo}
            investors={
              c.id === BITCOIN_S
                ? investors.btc
                : c.id === ETHEREUM_S
                ? investors.eth
                : c.id === DAI_S
                ? investors.dai
                : c.id === TUSD_S
                ? investors.tusd
                : c.id === USDT_S
                ? investors.ust
                : c.id === BUSD_S
                ? investors.busd
                : 5
            }
          />
        );
      })}
    </div>
  );
}
