import { useEffect } from "react";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { useState } from "react";
import "flatpickr/dist/themes/material_orange.css";

const FlatPickerComp = (props) => {
  const { fieldName, value, setFieldValue } = props;
  //   var dateString = moment.unix(value).format("MM-DD-YYYY H:MM");
  // var dateString = moment.unix(value);
  // eslint-disable-next-line

  const [date, setDate] = useState(value);

  // useEffect(() => {
  //   if (value !== undefined) {
  //     setDate(value.toString());
  //   }
  //   // eslint-disable-next-line
  // }, [date]);
  const onDateChange = (date) => {
    setFieldValue(fieldName, moment(date).unix().toString());
  };

  useEffect(() => {
    if (value !== undefined) {
      setDate(moment.unix(value).format("YYYY-MM-DD hh:mm"));
    }
  }, [value]);

  return (
    <div className="col-md-9">
      <>
        <Flatpickr
          data-enable-time
          className="w-100 input-bordered-error text-center"
          // minDate="today"
          value={date}
          // defaultValue={}
          options={{
            enableTime: true,
            dateFormat: "Z",
          }}
          onChange={([date]) => {
            onDateChange(date);
          }}
        />
      </>
    </div>
  );
};
export default FlatPickerComp;
