export const chains = {
  97: {
    name: "BscTest",
    chainId: 80001,
    defaultRpc: process.env.REACT_APP_RPC_URL,
    icoContractAddress: process.env.REACT_APP_ICO_ADDRESS,
    multiCall: process.env.REACT_APP_MULTI_CALL,
    tokens: [
      {
        index: 1,
        name: "DAI",
        price: 1,
        address: "0x63f5d6713f888fac521dd4b163e51e7363e64cd5",
      },
      {
        index: 2,
        name: "USDT",
        price: 1,
        address: "0x367fE170f1D0c00e9C23cA641137490465648f99",
      },

      {
        index: 3,
        name: "BUSD",
        price: 1,
        address: "0x029d2f8ffc2af549001f77798b45f274977fadf7",
      },
      //not working for now
      {
        index: 4,
        name: "TUSD",
        price: 1,
        address: "0xFc8Ad9fb5368793399De8DC479f1FAF1f50ED3B9",
      },
      {
        index: 5,
        name: "Bitcoin",
        price: 32200,
        address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
      },
      {
        index: 6,
        name: "Ethereum",
        price: 2500,
        address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
      },
      {
        index: 7,
        name: "Credit Card",
        price: 1,
        address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
      },
    ],
  },
  80001: {
    name: "PolygonMumbai",
    defaultRpc: "https://rpc.ankr.com/polygon_mumbai",
    chainId: 80001,
    icoContractAddress: "0x6c90ea95a94158819606a81aeb0de40f11585821",
    multiCall: "0xC8594C87Dbf78358ab8DfC11ed2693d87Ee4c962",
    tokens: [
      {
        index: 1,
        name: "DAI",
        price: 1,
        address: "0x63f5d6713f888fac521dd4b163e51e7363e64cd5",
      },
      {
        index: 2,
        name: "USDT",
        price: 1,
        address: "0x367fE170f1D0c00e9C23cA641137490465648f99",
      },

      {
        index: 3,
        name: "BUSD",
        price: 1,
        address: "0x029d2f8ffc2af549001f77798b45f274977fadf7",
      },
      //not working for now
      {
        index: 4,
        name: "TUSD",
        price: 1,
        address: "0xFc8Ad9fb5368793399De8DC479f1FAF1f50ED3B9",
      },
      {
        index: 5,
        name: "Bitcoin",
        price: 32200,
        address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
      },
      {
        index: 6,
        name: "Ethereum",
        price: 2500,
        address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
      },
      {
        index: 7,
        name: "Credit Card",
        price: 1,
        address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
      },
    ],
  },
  56: {
    name: "BscMain",
    chainId: 56,
    defaultRpc: "https://bsc-dataseed2.binance.org",
    icoContractAddress: "0x0000000000000000000000000000000000000000",
    multiCall: "0x0000000000000000000000000000000000000000",
    tokens: [
      {
        index: 1,
        name: "DAI",
        price: 1,
        address: "0xF5e484Fa2E41Cd95208a5f78316b5ec3910a1625",
      },
      {
        index: 2,
        name: "USDT",
        price: 1,
        address: "0xD5Ff1963c27823c3A7BA851c74F334D0a24F3457",
      },

      {
        index: 3,
        name: "BUSD",
        price: 1,
        address: "0x72FB8107c46447CbC4bebD9744C8C6ff829E4AbF",
      },
      //not working for now
      {
        index: 4,
        name: "TUSD",
        price: 1,
        address: "0xB4166E9DC5A107256CEFEE4241bd31317D87A96F",
      },
      {
        index: 5,
        name: "Bitcoin",
        price: 32200,
        address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
      },
      {
        index: 6,
        name: "Ethereum",
        price: 2500,
        address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
      },
      {
        index: 7,
        name: "Credit Card",
        price: 1,
        address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
      },
    ],
  },
  137: {
    name: "PolygonMain",
    chainId: 137,
    defaultRpc: "https://rpc.ankr.com/polygon",
    icoContractAddress: "0x0000000000000000000000000000000000000000",
    multiCall: "0x0000000000000000000000000000000000000000",
    tokens: [
      {
        index: 1,
        name: "DAI",
        price: 1,
        address: "0x0000000000000000000000000000000000000000",
      },
      {
        index: 2,
        name: "USDT",
        price: 1,
        address: "0x0000000000000000000000000000000000000000",
      },

      {
        index: 3,
        name: "BUSD",
        price: 1,
        address: "0x0000000000000000000000000000000000000000",
      },
      //not working for now
      {
        index: 4,
        name: "TUSD",
        price: 1,
        address: "0x0000000000000000000000000000000000000000",
      },
      {
        index: 5,
        name: "Bitcoin",
        price: 32200,
        address: "0x0000000000000000000000000000000000000000",
      },
      {
        index: 6,
        name: "Ethereum",
        price: 2500,
        address: "0x0000000000000000000000000000000000000000",
      },
      {
        index: 7,
        name: "Credit Card",
        price: 1,
        address: "0x0000000000000000000000000000000000000000",
      },
    ],
  },
};
