import React, { useContext, useEffect, useState } from "react";
import {
  GetReferralData,
  PostVisitCount,
} from "../../Services/User/Referrals/referral";
import { CountUp } from "use-count-up";
import {
  FacebookShareButton,
  FacebookShareCount,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  TelegramShareButton,
  TelegramIcon,
  RedditIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { WalletContext } from "../../App";
import {
  ClaimReferral,
  getReferralData,
} from "../../Services/User/web3Calls/contractCall";
import { Snackbar } from "@mui/material";
import { useProvider, useSigner } from "wagmi";
import { toast } from "react-toastify";

function Referral() {
  const [referraldata, setReferralData] = useState({
    referralLink: "",
    referralId: "",
    userId: "",
    refCount: 0,
    visitCount: 0,
    bonus: 0,
  });
  const [copy, setCopy] = useState(false);
  const [walletAddress] = useContext(WalletContext);
  const [totalBonus, setTotalBonus] = useState(0);
  const app_url = window.location.origin;

  const provider = useProvider();
  const { data: signerData } = useSigner();
  const [bonusData, setbonusData] = useState([
    {
      investamount: 0,
      address: "",
      claimamount: 0,

      claimed: 0,
    },
  ]);

  useEffect(() => {
    GetReferalData1();
    datafromcontract();
    //eslint-disable-next-line
  }, [walletAddress]);

  const datafromcontract = async () => {
    const data = await getReferralData(walletAddress, 0);

    setbonusData(data[0]);
    setTotalBonus(data[1]);
  };

  const claimTokens = async (index) => {
    try {
      await ClaimReferral(index, signerData, provider);
      datafromcontract();
    } catch (err) {
      toast.error(
        err?.code == "UNPREDICTABLE_GAS_LIMIT"
          ? "Your claim is not unlocked"
          : err?.reason
      );
    }
    // const data = await ClaimReferral(walletAddress, item.bonus);
    // GetReferalData()
  };
  const GetReferalData1 = async () => {
    GetReferralData()
      .then((res) => {
        if (res.data) {
          setReferralData(res?.data);
        }
      })
      .catch((err) => {
        console.log("respoence form referrer", err);
      });
  };
  const PostVisitCount2 = () => {
    PostVisitCount()
      .then((res) => {
        setReferralData(res.data);
      })
      .catch((err) => {
        console.log("error in visiting count submit");
      });
  };
  return (
    <>
      <div className="container">
        <div className="d-flex">
          <div className="user-content">
            <div className="user-panel">
              <h2 className="user-panel-title">Referral</h2>
              <h5>Invite your friends and family and recive free tokens</h5>
              <p>
                <strong>
                  Each member recives a unique referral link to share with
                  friends and family and recive a bonus - 50% of the value of
                  their contribution.
                </strong>
              </p>
              <p>
                The referral link may be used during a token contribution, in
                the pre-sale and the ICO.
              </p>
              <p>
                Imagine giving your unique referral link to your crypto-friend
                and he or she contributes tokens using your link, the bonus will
                be sent to your account automatically. The strategy is simple:
                the more links you send to your collagues, family and friends -
                the more tokens you may earn!
              </p>
              <h6>My unique referral link</h6>

              <div className="refferal-info">
                <div class="d-flex align-items-center">
                  <div class="icon-inside-input2">
                    <span className="refferal-copy-feedback copy-feedback"></span>
                    <em className="fas fa-link"></em>
                  </div>

                  <span style={{ width: "100%" }}>
                    <input
                      type="text"
                      className="refferal-address"
                      value={app_url + "/signup/" + referraldata?.referralId}
                      disabled
                    />
                  </span>

                  <Snackbar
                    message="Copied to clibboard"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    autoHideDuration={2000}
                    onClose={() => setCopy(false)}
                    open={copy}
                  />
                  <CopyToClipboard
                    text={app_url + "/signup/" + referraldata?.referralId}
                  >
                    <button
                      className="text-white btn-primary address-btn"
                      style={{ width: "20%", textAlign: "center" }}
                      onClick={() => setCopy(!copy)}
                    >
                      {/* <span
                        className=" font-bold text-black ml-3 cursor-puri "
                        onClick={() => navigator.clipboard.writeText()}
                        onClick={() => {
                          navigator.clipboard.writeText(referraldata.referralId);
                        }}
                      /> */}
                      Copy
                    </button>
                  </CopyToClipboard>
                </div>
              </div>

              {/* <div class="refferal-info">
                <div class="d-flex align-items-center">
                <span className="refferal-copy-feedback copy-feedback"></span>
                  <div class="icon-inside-input2">
                    <span className="refferal-copy-feedback copy-feedback"></span>
                    <em className="fas fa-link"></em>
                  </div>
                  <input
                   style="width: 80%;"
                  type="text"
                  className="refferal-address"
                  value={
                    process.env.REACT_APP_APP_URL +
                    "signup/" +
                    referraldata?.referralId
                  }
                  disabled
                />
                  <div>
                  <Snackbar
                  message="Copied to clibboard"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  autoHideDuration={2000}
                  onClose={() => setCopy(false)}
                  open={copy}
                />
                <CopyToClipboard
                  text={
                    process.env.REACT_APP_APP_URL +
                    "signup/" +
                    referraldata?.referralId
                  }
                >
                  <button
                    className="refferal-copy copy-clipboard"
                    style={{ width: "10%", textAlign: "center" }}
                    onClick={() => setCopy(!copy)}
                  >
                    <span
                      className=" font-bold text-black ml-3 cursor-puri "
                 
                    />
                    copy
                  </button>
                </CopyToClipboard>
                  </div>
                </div>
                <div class="mt-2"></div>
              </div> */}

              {/* <div class="tranx-payment-info">
                <div class="d-flex align-items-center">
                  <div class="icon-inside-input2">
                    <em class="fab fa-ethereum text-dark"></em>
                  </div>
                  <input type="text" class="tranx-payment-address error" placeholder="Enter Ethereum address you want to whitelist" style="width: 80%;" />
                  <button class="text-white btn-primary address-btn">
                    <span class="text-white ">WhiteList</span>
                  </button>
                </div>
                <div class="mt-2"></div>
              </div> */}

              <div className="p-3">
                <TwitterShareButton
                  className="p-1"
                  onClick={PostVisitCount2}
                  url={referraldata.referralLink}
                  quote={
                    "SIgn Up with my Referral Link and Get 20% Bonus on all purchases"
                  }
                >
                  {" "}
                  <TwitterIcon size={"50"} round={true}></TwitterIcon>
                </TwitterShareButton>
                <FacebookShareButton
                  className="p-1"
                  onClick={PostVisitCount2}
                  url={referraldata.referralLink}
                  quote={
                    "SIgn Up with my Referral Link and Get 20% Bonus on all purchases"
                  }
                  hashtag="#React"
                >
                  <FacebookIcon
                    logoFillColor="white"
                    size={"50"}
                    round={true}
                  ></FacebookIcon>
                </FacebookShareButton>
                <TelegramShareButton
                  className="p-1"
                  onClick={PostVisitCount2}
                  url={referraldata.referralLink}
                  quote={
                    "SIgn Up with my Referral Link and Get 20% Bonus on all purchases"
                  }
                  hashtag="#React"
                >
                  <TelegramIcon
                    logoFillColor="white"
                    size={"50"}
                    round={true}
                  ></TelegramIcon>
                </TelegramShareButton>
                <RedditShareButton
                  className="p-1"
                  onClick={PostVisitCount2}
                  url={referraldata.referralLink}
                  quote={
                    "Sign Up with my Referral Link and Get 20% Bonus on all purchases"
                  }
                  hashtag="#React"
                >
                  <RedditIcon
                    logoFillColor="white"
                    size={"50"}
                    round={true}
                  ></RedditIcon>
                </RedditShareButton>
                <WhatsappShareButton
                  className="p-1"
                  onClick={PostVisitCount2}
                  url={referraldata.referralLink}
                  quote={
                    "SIgn Up with my Referral Link and Get 20% Bonus on all purchases"
                  }
                >
                  <WhatsappIcon round={true} size={"50"}></WhatsappIcon>
                </WhatsappShareButton>
                <LinkedinShareButton
                  className="p-1"
                  onClick={PostVisitCount2}
                  url={referraldata.referralLink}
                  quote={
                    "Sign Up with my Referral Link and Get 20% Bonus on all purchases"
                  }
                >
                  {" "}
                  <LinkedinIcon size={"50"} round={true}></LinkedinIcon>
                </LinkedinShareButton>
              </div>

              <div className="text-dark">
                <FacebookShareCount url={referraldata.referralLink}>
                  {(shareCount) => (
                    <span className="myShareCountWrapper">{shareCount}</span>
                  )}
                </FacebookShareCount>
              </div>

              <div className="gaps-1x"></div>
              <h4>Referrals Statistics</h4>
              <div className="refferal-statistics">
                <div className="row">
                  <div className="col-md-4">
                    <div className="refferal-statistics-item">
                      <h6>Visit Count</h6>
                      <span>{referraldata.visitCount}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="refferal-statistics-item">
                      <h6>Signin Count</h6>
                      <span>{referraldata.refCount}</span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="refferal-statistics-item">
                      <h6>Total Bonus</h6>
                      <span>
                        <CountUp isCounting end={totalBonus} duration={1} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <h4>Referrals List</h4>
              <table className="data-table refferal-table w-100">
                <thead>
                  <tr>
                    <th className="refferal-name">
                      <span>Referee</span>
                    </th>
                    <th className="refferal-tokens">
                      <span>Bought Token</span>
                    </th>
                    <th className="refferal-bonus">
                      <span>Bonus</span>
                    </th>
                    {/* <th className="refferal-date">
                      <span>Date</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {bonusData.length > 0 && bonusData !== undefined ? (
                    <>
                      {bonusData.map((item, index) => {
                        return (
                          <tr key={index}>
                            {item.investamount > 0 ? (
                              <>
                                <td className="refferal-name">
                                  {item.address}
                                </td>
                                <td className="refferal-tokens">
                                  {item.investamount}
                                </td>
                                <td className="refferal-bonus">
                                  {item.claimamount}
                                </td>
                                <td className="refferal-bonus">
                                  {item.claimed ? (
                                    <button className=" bg-success border border-none text-white rounded">
                                      Claimed
                                    </button>
                                  ) : (
                                    <button
                                      className=" bg-primary border border-none text-white rounded"
                                      onClick={() => claimTokens(index)}
                                    >
                                      Claim
                                    </button>
                                  )}
                                </td>
                              </>
                            ) : (
                              <></>
                            )}
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <h6>No Refer Bonus Found</h6>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Referral;
