import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { KycContext } from "../../userRoutes";

const KycVerification = () => {
  const kycStatus = useContext(KycContext);

  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2 className="user-panel-title">Identity Verification - KYC</h2>
          <p>
            To comply with regulation each participant will have to go through
            identity verification (KYC). So please complete our fast and secure
            verification process to participate in our token sale. You can
            proceed from here to verify your identity and also you can check
            your application status if you have submitted it already.{" "}
          </p>
          {/* <div className="gaps-2x"></div> */}
          <div className="status status-empty">
            <div className="status-icon">
              <em className="ti ti-files"></em>
              <div className="status-icon-sm">
                <em className="ti ti-close"></em>
              </div>
            </div>
            <span className="status-text">
              {kycStatus[0].toLowerCase() == "pending" &&
                "You have not submitted your KYC Application"}
              {kycStatus[0].toLowerCase() == "in progress" &&
                "You have submitted your KYC Application Click to View or Update"}
            </span>
            <Link to={"/kyc"} className="btn btn-primary">
              CLick to proceed
            </Link>
          </div>
          <div className="note note-md note-info note-plane">
            <em className="fas fa-info-circle"></em>
            <p>
              Some of countries and regions will not able to pass KYC process
              and therefore are restricted from token sale.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default KycVerification;
