import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./stripe.css";
import axios from "axios";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import { Spinner } from "react-bootstrap";
import Clip from "../../components/Comps-Utils/ClipLoader";
import ErrorIcon from "@mui/icons-material/Error";
import {
  AddTokenTransaction,
  DeleteTokenTransaction,
} from "../../Services/User/Tokens/token";
export default function CheckoutForm({ purchase }) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  //eslint-disable-next-line
  const [clientSecret, setClientSecret] = useState("");
  // const [email, setEmail] = useState("");
  // const [amount] = useState(purchase?.amount);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    // fetchApi();
    // window
    //   .fetch("/create-payment-intent", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    //   })
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((data) => {
    //     setClientSecret(data.clientSecret);
    //   });
  }, []);
  // const fetchApi = async () => {
  //   const data = await axios.post(
  //     `${process.env.REACT_APP_BASE_URL}/transaction/stripe`,
  //     {
  //       body: JSON.stringify({ items: [{ id: "recut-coin" }] }),
  //     }
  //   );
  //   setClientSecret(data.clientSecret);
  // };

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(!event.complete);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setProcessing(true);
    try {
      AddTokenTransaction(purchase).then(async (transaction_data) => {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/transaction/stripe`, {
            // amount: amount,
            name: purchase.username,
            email: purchase.useremail,
            transaction_id: transaction_data.data._id,
          })
          .then(async (data) => {
            if (data.data.code === 400) {
              DeleteTokenTransaction(transaction_data.data._id);
              setError(`${data.data.msg.raw.message}`);
              setProcessing(false);
            }
            const payload = await stripe.confirmCardPayment(
              data.data.clientSecret,
              {
                payment_method: {
                  card: elements.getElement(CardElement),
                },
              }
            );
            if (payload.error) {
              DeleteTokenTransaction(transaction_data.data._id);
              setError(`Payment failed ${payload.error.message}`);
              setProcessing(false);
            } else {
              // if payment is successfull.post the transation
              // purchase.transactionId = payload.paymentIntent.id;
              // await AddTokenTransaction(purchase);
              setError(null);
              setProcessing(false);
              setSucceeded(true);
            }
          });
      });
    } catch (err) {
      console.log("Stripe Error: ", err);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {/* <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email address"
      />
      <input
        type="text"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter Amount   "
      /> */}
      <CardElement
        id="card-element"
        options={cardStyle}
        onChange={handleChange}
      />
      <div className="d-flex justify-content-center mt-4">
        {succeeded ? (
          <></>
        ) : (
          <button
            disabled={processing || disabled || succeeded}
            id="submit"
            className={
              disabled
                ? "payment-btn btn btn-primary d-flex w-40 justify-content-center align-items-center bg-light text-dark"
                : "btn btn-primary payment-btn d-flex w-40 justify-content-center align-items-center"
            }
          >
            <span id="button-text">{processing ? <Clip /> : "Pay now"}</span>
          </button>
        )}
      </div>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="stripe-error" role="alert">
          <span className="mr-1">
            <ErrorIcon />
          </span>
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        <div>
          <p className="text-center">
            {" "}
            <CheckCircleIcon color="green" /> Payment successfully submitted,
            see details in Transactions{" "}
          </p>
        </div>
        {/* <a href={`https://dashboard.stripe.com/test/payments`}>
          {" "}
          Stripe dashboard.
        </a>{" "} */}
        {/* Refresh the page to pay again. */}
      </p>
    </form>
  );
}
