import { useEffect, useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import AddIcon from "@mui/icons-material/Add";
// import Skeleton from '@material-ui/lab/Skeleton';
const UploadIcon = (props) => {
  const { upload, setUpload } = props;
  const [files, setFiles] = useState([]);

  useEffect(() => {
    // if (props.upload && props.upload.preview) {
    //   setFiles([props.upload]);
    // }
    setFiles([upload]);
  }, [upload]);
  // const baseStyle = {
  //   display: "flex",
  //   alignItems: "center",
  //   // border: "1px solid #f64b1c",
  //   color: "#bdbdbd",
  //   transition: "border .24s ease-in-out",
  //   justifyContent: "center",
  //   borderColor: "#f64b1c",
  // };
  const focusedStyle = {
    borderColor: "#2196f3",
  };
  const acceptStyle = {
    borderColor: "#00e676",
  };
  const rejectStyle = {
    borderColor: "#ff1744",
  };
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    // fileRejections,
  } = useDropzone({
    noClick: false,
    noKeyboard: true,
    multiple: false,
    accept: "image/*",
    minSize: 0,
    maxSize: 800 * 1000,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setUpload(
          acceptedFiles && acceptedFiles.length > 0
            ? acceptedFiles[0]
            : props.icon
        );
      }
      if (rejectedFiles) {
        console.log("Files Rejected");
      }
    },
  });
  const style = useMemo(
    () => ({
      // ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line
    [isFocused, isDragAccept, isDragReject]
  );
  // const fileRejectionItems = fileRejections.map(({ file, errors }) => (
  //   <ul>
  //     {errors.map((e) => (
  //       <li key={e.code}>{e.message}</li>
  //     ))}
  //   </ul>
  // ));
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file?.preview));
    },
    [files]
  );
  return (
    <>
      <section className="d-flex justify-content-center">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {/* <div
            className="d-flex flex-row "
            style={{ width: "100px", textAlign: "center", paddingLeft: "8px" }}
          >
            <AddIcon
              style={{
                color: "white",
                border: "1px solid white",
                borderRadius: "50%",
                marginRight: "7px",
              }}
            />
            <h6 className="text-white">Upload</h6>
          </div> */}
          <div className="d-flex justify-content-end mb-3">
            <button
              onClick={(e) => {
                e.preventDefault();
              }}
              className="btn btn-md btn-grad btn-grad-theme btn-round mr-2 upload-btn2"
            >
              <div className="d-flex justify-content-center align-items-center">
                <AddIcon
                  style={{
                    color: "white",
                    border: "1px solid white",
                    borderRadius: "50%",
                    marginRight: "7px",
                  }}
                />{" "}
                <span>Upload</span>
              </div>
            </button>
          </div>
        </div>
        {/* <aside className="mt-5 ml-4">{fileRejectionItems}</aside> */}
      </section>
    </>
  );
};
export default UploadIcon;
