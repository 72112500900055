import { useState, useContext, useEffect } from "react";
// import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import {
  FetchSpecificTransaction,
  fetchWhiteListAddress,
  SubmitETHTransactionsDetails,
  SubmitBtcTransactionsDetails,
} from "../../Services/User/Tokens/token";
import { ToasterContext } from "../../App";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
// import { VerifyTransaction } from "../../Services/User/BlockCypher/blockcypher";
import axios from "axios";
import { VerifyETHTransaction } from "../../Services/User/EthAPIFetch/ethapi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@mui/material";
// import ErrorIcon from "@mui/icons-material/Error";
// import axios from "axios";
const Payment = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [copy, setCopy] = useState(false);
  const transid = searchParams.get("transid");

  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    txnno: "",
  });
  const [paymentObj, setPaymentObj] = useState({
    from: "",
    email: "",
    id: transid,
    txnno: "",
    amount: 0,
    receiveaddress: "",
    paymentoptions: "",
  });
  useEffect(() => {
    fetchTransactionWithId();
    // eslint-disable-next-line
  }, []);
  const fetchTransactionWithId = async () => {
    const data = await FetchSpecificTransaction(transid);

    const { paymentMethod, useremail, expectedamount, from } = data.data;
    setPaymentObj({
      ...paymentObj,
      paymentoptions: paymentMethod,
      from: from,
      email: useremail,
      id: transid,
      amount: expectedamount,
      receiveaddress:
        paymentMethod === "ETH"
          ? process.env.REACT_APP_ETH_PUBLIC_ADDRESS.substring(0, 7) +
            "..." +
            process.env.REACT_APP_ETH_PUBLIC_ADDRESS.substring(35, 42)
          : process.env.REACT_APP_BTC_PUBLIC_ADDRESS,
    });
  };

  // const handleValidation = () => {
  //   let newErrors = {};
  //   const txRegex = new RegExp(/^[a-fA-F0-9]{64}$/g);
  //   if (paymentObj.txnno === "" || !txRegex.test(paymentObj.txnno)) {
  //     newErrors.txnno = "txn hash is empty/invalid ";
  //   }
  //   //
  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors(newErrors);
  //     return true;
  //   } else {
  //     setErrors({});
  //     return false;
  //   }
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentObj({ ...paymentObj, [name]: value });
  };
  const SubmitBTCDetails = async (e) => {
    e.preventDefault();
    setLoading(true);
    const btcTxRegex = new RegExp(/^[a-fA-F0-9]{64}$/g);

    const regextest = btcTxRegex.test(paymentObj.txnno);
    if (regextest) {
      axios
        .get(`${process.env.REACT_APP_BITCOIN_API_URL}/${paymentObj.txnno}`)
        .then(async (fetchTransactionsfromCypher) => {
          if (fetchTransactionsfromCypher.status === 200) {
            const { inputs, outputs } = fetchTransactionsfromCypher.data.data;

            //Check 1. checking if address input is whitelisted and transaction from same address
            const inputAddress = await inputs.filter(
              (i) => i.address.toLowerCase() === paymentObj.from.toLowerCase()
            );
            if (inputAddress.length > 0) {
              const outputAddress = outputs.filter(
                (i) =>
                  i.address.toLowerCase() ===
                  process.env.REACT_APP_BTC_PUBLIC_ADDRESS.toLowerCase()
              );
              // check2:
              if (outputAddress.length > 0) {
                // const actualAmount = outputAddress[0].value;
                const dummyObj = {
                  from: paymentObj.from,
                  id: paymentObj.id,
                  txnno: paymentObj.txnno,
                  // actualamount: actualAmount,
                };
                setLoading(true);
                const data = await SubmitBtcTransactionsDetails(dummyObj);
                setLoading(false);
                setTostify({
                  ...tostify,
                  visible: true,
                  key: Math.random().toString(36).substring(7),
                  type: data.code === 200 ? "success" : "error",
                  message: data.data,
                });
              } else {
                setTostify({
                  ...tostify,
                  visible: true,
                  key: Math.random().toString(36).substring(7),
                  type: "error",
                  message: "Receving wallet address does not exist in tx",
                });
                setLoading(false);
              }
            } else {
              setTostify({
                ...tostify,
                visible: true,
                key: Math.random().toString(36).substring(7),
                type: "error",
                message: "Sending wallet address does not exist in tx",
              });
              setLoading(false);
            }
            //   // Check 3. Check if tx is after we created transaction in our db
          }
          // Check 2. Check if output same as out btc address
          else {
            setTostify({
              ...tostify,
              visible: true,
              key: Math.random().toString(36).substring(7),
              type: "error",
              message: fetchTransactionsfromCypher.message,
            });
          }
        })
        .catch((err) => {
          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: "error",
            message: "Something went wrong",
          });
        }); //from blockcypher
      setLoading(false);
    } else {
      setErrors({ ...errors, txnno: "txn hash is empty/invalid" });
      setLoading(false);
    }
  };
  const SubmitETHDetails = async (e) => {
    e.preventDefault();
    setLoading(true);
    const ethTxRegex = new RegExp(/^0x([A-Fa-f0-9]{64})$/g);

    const regextest = ethTxRegex.test(paymentObj.txnno);

    if (regextest) {
      VerifyETHTransaction(paymentObj.txnno).then(async (res) => {
        const { from, to, value } = res.data.result;
        let amount = parseFloat(parseInt(value) / Math.pow(10, 18));
        //check 1
        // eslint-disable-next-line
        const fetchtxno = await FetchSpecificTransaction(transid); //from backend
        //   //Check 1. checking if address input is whitelisted and transaction from same address
        const fetchwhitelist = await fetchWhiteListAddress("ethereum");
        const checkWhitelist = await fetchwhitelist.data.find(
          (o) => o.address.toLowerCase() === from.toLowerCase()
        );
        // const resolved = await Promise.all(checkWhitelist);
        //
        if (checkWhitelist !== undefined) {
          if (
            to.toLowerCase() ===
            process.env.REACT_APP_ETH_PUBLIC_ADDRESS.toLowerCase()
          ) {
            const dummyObj = {
              from: paymentObj.from,
              id: paymentObj.id,
              txnno: paymentObj.txnno,
              actualamount: amount,
            };
            setLoading(true);
            const data = await SubmitETHTransactionsDetails(dummyObj);
            setLoading(false);
            setTostify({
              ...tostify,
              visible: true,
              key: Math.random().toString(36).substring(7),
              type: data.code === 200 ? "success" : "error",
              message: data.data,
            });
          } else {
            setTostify({
              ...tostify,
              visible: true,
              key: Math.random().toString(36).substring(7),
              type: "error",
              message: "Incorrect ETH receving wallet address ",
            });
            setLoading(false);
          }
          // check 3 here we check transaction dates
        } else {
          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: "error",
            message: "sending address in submitted tx is not whitelisted",
          });
          setLoading(false);
        }
        // check 2
      });
    } else {
      setErrors({ ...errors, txnno: "txn hash is empty/invalid" });
      setLoading(false);
    }
  };
  //
  return (
    <>
      <div className="user-ath user-ath-page transaction-btc">
        <div className="container-fluid" id="bg-div">
          <div className="card-payment">
            <div className="card-payment-bg">
              <div className="img-logo-round">
                <Link to={"/"}>
                  <img
                    src="images/512-betts.png"
                    style={{ width: "32px" }}
                    srcSet="images/512-betts.png"
                    alt="icon"
                  />
                </Link>
              </div>
            </div>

            <div className="card-payment-body">
              <h2 className="text-center mb-2" style={{ color: "#000000" }}>
                Transaction details
              </h2>
              <p className="text-center" style={{ color: "#8B8B8B" }}>
                Submit all the details in given form
              </p>
              <div className="card-payment-body-details mt-4">
                <div className="detail">
                  <p>
                    <img
                      className="img-fluid"
                      src="images/payment-amt.svg"
                      alt=""
                    />
                    {paymentObj.paymentoptions} Purchased Amount
                  </p>
                  <p>{paymentObj.amount}</p>
                </div>
                <div className="detail">
                  <p>
                    <img
                      className="img-fluid"
                      src="images/payment-opt.svg"
                      alt=""
                    />
                    Payment Option
                  </p>
                  <p>{paymentObj.paymentoptions}</p>
                </div>
                <div className="detail">
                  <p>
                    <img
                      className="img-fluid"
                      src="images/payment-email.svg"
                      alt=""
                    />
                    Email
                  </p>
                  <p>{paymentObj.email}</p>
                </div>
                <div className="detail">
                  <p>
                    <img
                      className="img-fluid"
                      src="images/payment-wallet-address.svg"
                      alt=""
                    />
                    {[paymentObj.paymentoptions]} Wallet Address
                  </p>
                  <p title={paymentObj.from}>
                    {paymentObj.from.length > 15 ? (
                      <>
                        {paymentObj.from.substring(0, 7) +
                          " .... " +
                          paymentObj.from.substring(
                            paymentObj.from.length - 7,
                            paymentObj.from.length
                          )}
                      </>
                    ) : (
                      <>{paymentObj.from}</>
                    )}
                    <Snackbar
                      message="Copied to clibboard"
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      autoHideDuration={2000}
                      onClose={() => setCopy(false)}
                      open={copy}
                    />
                    <CopyToClipboard text={paymentObj?.from}>
                      {/* <button
                        className="refferal-copy copy-clipboard"
                        style={{ width: "10%", textAlign: "center" }}
                      >
                        copy
                      </button> */}

                      <em
                        onClick={() => setCopy(!copy)}
                        style={{ cursor: "pointer" }}
                        className="ti ti-files ms-2"
                      ></em>
                    </CopyToClipboard>
                  </p>
                </div>
                <div className="detail">
                  <p>
                    <img
                      className="img-fluid"
                      src="images/payment-receive.svg"
                      alt=""
                    />
                    Receiving Wallet Address
                  </p>
                  <p title={paymentObj.receiveaddress}>
                    <>{paymentObj.receiveaddress}</>

                    <Snackbar
                      message="Copied to clibboard"
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      autoHideDuration={2000}
                      onClose={() => setCopy(false)}
                      open={copy}
                    />
                    <CopyToClipboard text={paymentObj.receiveaddress}>
                      {/* <button
                        className="refferal-copy copy-clipboard"
                        style={{ width: "10%", textAlign: "center" }}
                      >
                        copy
                      </button> */}

                      <em
                        onClick={() => setCopy(!copy)}
                        style={{ cursor: "pointer" }}
                        className="ti ti-files ms-2"
                      ></em>
                    </CopyToClipboard>
                  </p>
                </div>
                <div
                  className="detail"
                  style={{
                    flexDirection: "column",
                    padding: "20px 0px 20px 0px",
                  }}
                >
                  <p>
                    <img
                      className="img-fluid"
                      src="images/payment-transcation.svg"
                      alt=""
                    />
                    Submit Transaction Hash Here
                  </p>
                  <div className="input-group">
                    <input
                      type="text"
                      className="w-100"
                      name="txnno"
                      id="exp"
                      placeholder="Transaction Hash"
                      value={paymentObj.txnno}
                      onChange={handleChange}
                      required
                    />
                    <span className="error1">{errors.txnno}</span>{" "}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center flex-column-reverse">
                <Link to={"/transactions"}>
                  <button className="btn btn-primary rounded-pill   ">
                    Back
                  </button>
                </Link>
                <button
                  className="transction-btc-btn btn placeicon mb-sm-0 mb-3"
                  onClick={
                    paymentObj.paymentoptions === "ETH"
                      ? SubmitETHDetails
                      : SubmitBTCDetails
                  }
                >
                  {loading ? (
                    <ClipLoader color={"white"} />
                  ) : (
                    <>
                      <img
                        src="images/btn-tick.svg"
                        style={{ marginRight: "10px" }}
                        alt=""
                        className="img-fluid"
                      />{" "}
                      Submit Details
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Payment;
