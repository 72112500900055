import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ImageContext } from "../../App";
import useConnectWallet from "../../Services/User/Wallet/wallet";
import { WalletContext } from "../../App";
import { ToasterContext } from "../../App";
import { NavLink } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import WalletPromptModal from "./walletPromptModal";
import {
  GetVestingCount,
  ICOTokenBalance,
  ICOTokenName,
} from "../../Services/User/web3Calls/contractCall";
// import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWallet";
import { FetchTransaction } from "../../Services/User/Tokens/token";
function Navbar() {
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [crossToggle, setCrossToggle] = useState(false);
  const showMobileSidebar = () => {
    setMobileSidebar(!mobileSidebar);
    setCrossToggle(!crossToggle);
  };
  // eslint-disable-next-line
  const wallet = useConnectWallet();

  const [profileImage] = useContext(ImageContext);
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [walletAddress] = useContext(WalletContext);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const Logout = async () => {
    await sessionStorage.clear();
    await localStorage.clear();
    window.location = "/login";
  };
  const [icoName, setIcoName] = useState("");
  const [icoBal, setIcoBal] = useState(0);
  const [rewardToken, setRewardToken] = useState("-");
  const user =
    localStorage.getItem("user") === "undefined" ||
    !localStorage.getItem("user")
      ? { email: "", username: "", _id: "" }
      : JSON.parse(localStorage.getItem("user"));
  const addDefaultSrc = (ev) => {
    ev.target.src = "images/user-thumb-lg.png";
  };
  const [isActive, setIsActive] = useState(true);
  const [isAccount, setIsAccount] = useState(true);

  const handleClick = () => {
    // 👇️ toggle isActive state on click
    setIsActive(false);
  };
  const handleClickAccount = () => {
    // 👇️ toggle isActive state on click
    setIsAccount(false);
  };
  const kycStatus = JSON.parse(localStorage.getItem("user"))?.kycstatus;

  useEffect(() => {
    fetchICOBalance();
    // if (window.ethereum !== undefined) {
    //   handleWallet();
    // }

    // eslint-disable-next-line
  }, [walletAddress]);
  useEffect(() => {
    if (window.ethereum !== undefined && walletAddress !== "Connect Wallet") {
      handleWalletCheck();
    }
  }, [walletAddress]);
  const [disableTabs, setDisableTabs] = useState({
    wallet: true,
    application: true,
    buyTokens: true,
    claim: true,
    referral: true,
  });

  useEffect(() => {
    fetchTabsInfo();
    // eslint-disable-next-line
  }, [window.ethereum, walletAddress]);
  const fetchTabsInfo = async () => {
    var wallet = true,
      application = true,
      buyTokens = true,
      claim = true,
      referral = true;
    if (window.ethereum !== undefined) {
      wallet = false;
      var vestingCount = await GetVestingCount(walletAddress);

      if (vestingCount > 0) {
        buyTokens = false;
        claim = false;
      }
    }

    var localStore = JSON.parse(localStorage.getItem("user"));

    if (localStore.kycstatus !== "pending") {
      application = false;
    }

    setDisableTabs({
      ...disableTabs,
      wallet: wallet,
      application: application,
      claim: claim,
      buyTokens: buyTokens,
      referral: referral,
    });
  };
  useEffect(() => {
    fetchTokenName();
    CountTransactions();
    // eslint-disable-next-line
  }, []);

  const fetchTokenName = async () => {
    const tokenSymbol = await ICOTokenName();
    setRewardToken(tokenSymbol);
  };
  const handleWalletCheck = async () => {
    if (walletAddress !== user.walletAddress) {
      setShowWalletModal(true);
    }
  };
  const closeWalletModal = () => setShowWalletModal(false);
  // const handleWallet = async () => {
  //   if (window.ethereum !== undefined) {
  //     wallet
  //       .then((res) => {
  //         //
  //         if (res.code === -32002) {
  //           //
  //           setTostify({
  //             ...tostify,
  //             visible: true,
  //             key: Math.random().toString(36).substring(7),
  //             type: "error",
  //             message:
  //               "your metamask is locked or pending to connect check your metamask",
  //           });
  //         } else if (res !== undefined) {
  //           setWalletAddress(res);
  //           window.ethereum
  //             .request({
  //               method: "wallet_addEthereumChain",
  //               params: [
  //                 {
  //                   chainId: "0x61",
  //                   chainName: "Binance Smart Chain Testnet",
  //                   nativeCurrency: {
  //                     name: "Binance Coin",
  //                     symbol: "BNB",
  //                     decimals: 18,
  //                   },
  //                   rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
  //                   blockExplorerUrls: ["https://testnet.bscscan.com/"],
  //                 },
  //               ],
  //             })
  //             .catch((error) => {
  //               console.log(error);
  //             });
  //           tostify.dismissAll();
  //         }
  //       })
  //       .catch((err) => {});
  //   } else {
  //     window.open(
  //       "https://metamask.io/",
  //       "_blank" // <- This is what makes it open in a new window.
  //     );
  //     tostify.dismissAll();
  //     // setTostify({
  //     //   ...tostify,
  //     //   visible: true,
  //     //   key: Math.random().toString(36).substring(7),
  //     //   type: "error",
  //     //   message: "Install Metamask ",
  //     // });
  //   }
  // };
  const fetchICOBalance = async () => {
    if (walletAddress !== "Connect Wallet") {
      const name = await ICOTokenName();
      //
      setIcoName(name);
      const bal = await ICOTokenBalance(walletAddress);

      setIcoBal(bal);
    }
  };
  const [tokensInvested, setTokensInvested] = useState({
    dai: 0,
    eth: 0,
    busd: 0,
    btc: 0,
    ust: 0,
    tusd: 0,
    credit: 0,
  });
  const CountTransactions = async () => {
    const transaction = await FetchTransaction();
    if (transaction.status === 200) {
      // let _transactions = [];
      // let _dai = [];
      // let _busd = [];
      // let _ust = [];
      // let _tusd = [];
      // let _btc = [];
      // let _eth = [];
      // eslint-disable-next-line no-loop-func
      // eslint-disable-next-line no-loop-func
      let dai = 0;
      let busd = 0;
      let ust = 0;
      let tusd = 0;
      let btc = 0;
      let eth = 0;
      let credit = 0;

      transaction.data.map((i, index) => {
        if (i.paymentMethod === "DAI") {
          dai += parseFloat(i.expectedamount);
        }
        if (i.paymentMethod === "BUSD") {
          busd += parseFloat(i.expectedamount);
        }
        if (i.paymentMethod === "UST") {
          ust += parseFloat(i.expectedamount);
        }
        if (i.paymentMethod === "TUSD") {
          tusd += parseFloat(i.expectedamount);
        }
        if (i.paymentMethod === "BTC") {
          btc += parseFloat(i.expectedamount);
        }
        if (i.paymentMethod === "ETH") {
          eth += parseFloat(i.expectedamount);
        }
        if (i.paymentMethod === "credit") {
          credit += parseFloat(i.expectedamount);
        }
        return 0;
      });
      setTokensInvested({
        ...tokensInvested,
        dai: dai.toFixed(2),
        btc: btc.toFixed(4),
        eth: eth.toFixed(4),
        tusd: tusd.toFixed(2),
        ust: ust.toFixed(2),
        busd: busd.toFixed(2),
        credit: credit.toFixed(2),
      });
    } else {
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: "error",
        message: transaction.message,
      });
    }
    // setDai(_dai);
    // setBusd(_busd);
    // setUst(_ust);
    // setTusd(_tusd);
    // setBtc(_btc);
    // setEth(_eth);
  };
  return (
    <>
      <div className="topbar">
        <div className="topbar-md d-lg-none">
          <div className="container">
            <div className="d-flex flex-lg-row flex-column align-items-center justify-content-between py-3">
              {/* <span className="toggle-nav site-toggle" onClick={showInvestorBar}> */}

              <div className="d-flex flex-lg-row flex-row-reverse justify-content-between align-items-center w-100 mb-sm-0 mb-3">
                <div>
                  <span
                    className="toggle-nav site-toggle"
                    onClick={showMobileSidebar}
                  >
                    {crossToggle ? (
                      <div className="dashboard-toggle active d-none">
                        <div className="toggle-line active-line">
                          <span></span>
                        </div>
                      </div>
                    ) : (
                      <div className="dashboard-toggle">
                        <div className="toggle-line">
                          <span></span>
                        </div>
                      </div>
                    )}
                  </span>
                  <div
                    className={
                      mobileSidebar
                        ? "user-sidebar-mobile active"
                        : "user-sidebar"
                    }
                  >
                    {/* <div className="user-sidebar-overlay"></div> */}
                    <ul className="user-icon-nav pt-0">
                      <nav>
                        <li className="right-toggle">
                          <div
                            className="header-nav-toggle header-dashboard-toggle"
                            onClick={showMobileSidebar}
                          >
                            {crossToggle ? (
                              <div className="dashboard-toggle active d-lg-block">
                                <div className="toggle-line active-line d-lg-block">
                                  <span></span>
                                </div>
                              </div>
                            ) : (
                              <div className="dashboard-toggle d-lg-block d-none">
                                <div className="toggle-line">
                                  <span></span>
                                </div>
                              </div>
                            )}
                          </div>
                        </li>
                        <li>
                          <NavLink to="/dashboard">
                            <div>
                              <em className="ti ti-dashboard"></em>
                              <span className="mr-2">Dashboard</span>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/setupwallet">
                            <div className="d-flex justify-content-between w-100">
                              <div>
                                <em className="ti ti-wallet"></em>
                                <span className="mr-2">Setup Wallet</span>
                              </div>
                              <span>
                                {disableTabs.wallet ? (
                                  <>
                                    <CheckCircleIcon />
                                  </>
                                ) : (
                                  <span className="check-icon">
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </span>
                                )}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/kyc-verification">
                            <div className="d-flex justify-content-between w-100">
                              <div>
                                <em className="ti ti-files"></em>
                                <span className="mr-2">Application</span>
                              </div>
                              <span>
                                {disableTabs.application ? (
                                  <>
                                    <CheckCircleIcon />
                                  </>
                                ) : (
                                  <span className="check-icon">
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </span>
                                )}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/buytokens">
                            <div className="d-flex justify-content-between w-100">
                              <div>
                                <em className="ti ti-pie-chart"></em>
                                <span className="mr-2">Buy Tokens</span>
                              </div>
                              <span>
                                {disableTabs.buyTokens ? (
                                  <>
                                    <CheckCircleIcon />
                                  </>
                                ) : (
                                  <span className="check-icon">
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </span>
                                )}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/claim">
                            <div className="d-flex justify-content-between w-100">
                              <div>
                                <em className="ti ti-wand"></em>
                                <span className="mr-2">Claim Tokens</span>
                              </div>
                              <span>
                                {disableTabs.claim ? (
                                  <>
                                    <CheckCircleIcon />
                                  </>
                                ) : (
                                  <span className="check-icon">
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                  </span>
                                )}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/claim">
                            <div>
                              <em className="ti ti-star me-3"></em>
                              <span className="mr-2">Referral</span>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/transactions">
                            <div>
                              <em className="ti ti-control-shuffle"></em>
                              <span>Transactions</span>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="account" onClick={handleClickAccount}>
                            <div className="d-flex ddddd justify-content-between w-100">
                              <div className="d-flex position-relative">
                                <div>
                                  <em className="ti ti-user me-3"></em>
                                  <span className="mr-2 pr-2">Account</span>
                                  {!user.walletAddress && isAccount ? (
                                    <>
                                      <div className="badge-without-number with-wave"></div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                {!user.walletAddress && isAccount ? (
                                  <>
                                    <div
                                      className={
                                        isActive ? "icnBox" : "icnBoxhide"
                                      }
                                      onClick={handleClick}
                                    >
                                      <span
                                        className={
                                          isActive ? "icnCircle" : "icnBoxhide"
                                        }
                                      >
                                        <i className="fa fa-fw fa-close"></i>
                                      </span>

                                      {isActive == true && (
                                        <span className="msg-icn">
                                          Please provide your wallet address
                                          {/* Make money from answering simple questions. We pay you in cash. Simple and fun. */}
                                        </span>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {/* <div>
                                  <em className="ti ti-user"></em>
                                  <span className="mr-2">Account</span>
                                </div>
                                <div class="badge-without-number with-wave"></div>
                                <div className="icnBox">
                                  <span className="msg-icn">
                                    Please provide your wallet address
                                  </span>
                                </div> */}
                              </div>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/security">
                            <div>
                              <em className="ti ti-lock"></em>
                              <span>Security</span>
                            </div>
                          </NavLink>
                        </li>
                      </nav>
                    </ul>
                    <div className="user-sidebar-sap"></div>
                    <ul className="user-nav">
                      <li>
                        <Link to="/how-to-buy">How to buy?</Link>
                      </li>
                      <li>
                        <Link to="/faqs">Faqs</Link>
                      </li>
                      <li>
                        Contact Support<span>info@betts.com</span>
                      </li>
                    </ul>
                    <div className="d-lg-none">
                      <div className="user-sidebar-sap"></div>
                      <div className="gaps-1x"></div>
                      <ul className="topbar-action-list">
                        <li className="topbar-action-item topbar-action-link">
                          <Link to="/">
                            <em className="ti ti-home"></em> Go to main site
                          </Link>
                        </li>
                        {/* <li className="dropup topbar-action-item topbar-action-language">
                          <span data-bs-toggle="dropdown" aria-haspopup="true">
                            {" "}
                            EN <em className="ti ti-angle-up"></em>{" "}
                          </span>
                          <ul className="dropdown-menu">
                            <li>
                              <span>FR</span>
                            </li>
                            <li>
                              <span>JY</span>
                            </li>
                            <li>
                              <span>CH</span>
                            </li>
                          </ul>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="site-logo">
                  <Link to="/" className="site-brand">
                    <img src="images/logo.svg" alt="logo" />
                  </Link>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-lg-end justify-content-center w-100">
                <div className="connect-wallet pr-md-2 pr-1 py-0">
                  {" "}
                  {walletAddress !== "Connect Wallet" ? (
                    <ConnectButton />
                  ) : (
                    <ConnectButton />
                  )}
                </div>
                {/* <div className="dropdown topbar-action-item topbar-action-user">
                  <span data-bs-toggle="dropdown">
                    {" "}
                    {walletAddress !== "Connect Wallet" ? (
                      <ConnectButton />
                    ) : (
                      <ConnectButton />
                    )}
                  </span>
                </div> */}
                <div className="dropdown topbar-action-item topbar-action-user">
                  <span data-bs-toggle="dropdown">
                    {" "}
                    <img
                      className="icon"
                      src={profileImage ? profileImage : ""}
                      onError={addDefaultSrc}
                      alt="profile"
                    />{" "}
                  </span>
                  <div className="dropdown-menu dropdown-menu-end">
                    <div className="user-dropdown">
                      <div className="user-dropdown-head">
                        <h6 className="user-dropdown-name aaa">
                          {user.username} <span>( {user._id} )</span>
                        </h6>
                        <span className="user-dropdown-email">
                          {user.email}
                        </span>
                      </div>
                      <div className="user-dropdown-balance">
                        <h6>{rewardToken} BALANCE</h6>
                        <h3>
                          {icoBal} {icoName}
                        </h3>
                        <ul>
                          <li>{tokensInvested.btc} BTC</li>
                          <li>{tokensInvested.eth} ETH</li>
                          <li>{tokensInvested.dai} DAI</li>
                        </ul>
                      </div>
                      <ul className="user-dropdown-btns btn-grp guttar-10px">
                        {/* <li>
                          <span className="btn btn-xs btn-warning min-auto">
                            Confirm Email
                          </span>
                        </li> */}
                        <li>
                          {kycStatus === "active" ? (
                            <span className="btn btn-xs btn-success">
                              KYC {kycStatus}
                            </span>
                          ) : kycStatus === "in progress" ? (
                            <span className="btn btn-xs btn-warning">
                              KYC {kycStatus}
                            </span>
                          ) : (
                            <span className="btn btn-xs btn-warning">
                              KYC {kycStatus}
                            </span>
                          )}
                        </li>
                      </ul>
                      <div className="gaps-1x"></div>
                      <ul className="user-dropdown-links">
                        <li>
                          <Link to={"/account"}>
                            <i className="ti ti-id-badge"></i>My Profile
                          </Link>
                        </li>
                        <li>
                          <Link to={"/security"}>
                            <i className="ti ti-lock"></i>Security
                          </Link>
                        </li>
                        <li>
                          <Link to={"/activity"}>
                            <i className="ti ti-eye"></i>Activity
                          </Link>
                        </li>
                      </ul>
                      <ul className="user-dropdown-links">
                        <li>
                          <Link to={""} onClick={Logout}>
                            <i className="ti ti-power-off"></i>Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="d-lg-flex align-items-center justify-content-between">
          <div className="topbar-lg d-none d-lg-block">
            <div className="site-logo">
              <Link to="/" className="site-brand">
                <img src="images/logo.svg" alt="logo" />
              </Link>
            </div>
          </div>
          <div className="topbar-action d-none d-lg-block">
            <ul className="topbar-action-list">
              <li className="topbar-action-item topbar-action-link">
                <Link to="/">
                  <em className="ti ti-home"></em>Homepage
                </Link>
              </li>
              {/* <li className=""> */}
              {walletAddress !== "Connect Wallet" ? (
                <ConnectButton />
              ) : (
                <ConnectButton />
              )}
              {/* </li> */}
              <li>
                <div
                // style={{
                //   width: "100vw",
                //   height: "100vh",
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "center",
                // }}
                >
                  {/* <ConnectButton /> */}
                </div>
              </li>
              <li
                className="dropdown topbar-action-item topbar-action-user"
                onClick={fetchICOBalance}
              >
                <Link to={""} data-bs-toggle="dropdown">
                  {" "}
                  <img
                    className="rounded-circle"
                    src={profileImage ? profileImage : ""}
                    alt="thumb"
                    onError={addDefaultSrc}
                  />
                </Link>
                <div className="dropdown-menu dropdown-menu-end">
                  <div className="user-dropdown">
                    <div className="user-dropdown-head">
                      <h6 className="user-dropdown-name">
                        {user.username} <span>({user._id})</span>
                      </h6>{" "}
                      <span className="user-dropdown-email">{user.email}</span>
                    </div>
                    <div className="user-dropdown-balance">
                      <h6>{rewardToken} BALANCE</h6>
                      <h3>
                        {icoBal} {icoName}
                      </h3>
                      <ul>
                        <li>{tokensInvested.btc} BTC</li>
                        <li>{tokensInvested.eth} ETH</li>
                        <li>{tokensInvested.dai} DAI</li>
                      </ul>
                    </div>
                    <ul className="user-dropdown-links">
                      <li>
                        <Link to={"/account"}>
                          <i className="ti ti-id-badge"></i>My Profile
                        </Link>
                      </li>
                      <li>
                        <Link to={"/security"}>
                          <i className="ti ti-lock"></i>Security
                        </Link>
                      </li>
                      <li>
                        <Link to={"/activity"}>
                          <i className="ti ti-eye"></i>Activity
                        </Link>
                      </li>
                    </ul>
                    <ul className="user-dropdown-links">
                      <li>
                        <Link to={""} onClick={Logout}>
                          <i className="ti ti-power-off"></i>Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <WalletPromptModal
          showWalletModal={showWalletModal}
          closeWalletModal={closeWalletModal}
        />
      </div>
    </>
  );
}
export default Navbar;
