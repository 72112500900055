import { TextField } from "@material-ui/core";
import { ErrorMessage } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import Button from "@material-ui/core/Button";
const TextField50 = (props) => {
  const {
    fieldName,
    handleChange,
    values,
    touched,
    place,
    errors,
    type,
    widthclass,
    setAmountExceed,
    setFieldValue,
    tab,
    index,
    heading,
    actionState,
  } = props;
  return (
    <>
      <div className={widthclass}>
        {tab && tab === "Withdraw Tokens" ? (
          <span className="d-flex align-items-center justify-content-end mb-1">
            <Button
              className="p-0 m-0 mr-1 mb-1 text-xplay-light"
              onClick={() => {
                setFieldValue(
                  "amount",
                  parseFloat(values["balance"]).toFixed(6)
                );
                setAmountExceed(false);
              }}
            >
              Max
            </Button>
          </span>
        ) : null}
        <TextField
          disabled={
            heading === "Email Template" &&
            fieldName === "type" &&
            actionState === "edit"
          }
          variant="outlined"
          size="small"
          fullWidth
          id="first-name-input fff"
          color="primary"
          name={fieldName}
          type={type}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (type === "number") {
              (["e", "E", "+", "-"].includes(e.key) ||
                e.which === 38 ||
                e.which === 40) &&
                e.preventDefault();
            }
          }}
          min="0"
          placeholder={place}
          value={values[fieldName]}
          error={touched[fieldName] && errors[fieldName]}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {fieldName === "phone" &&
                values &&
                values.nationality &&
                values.nationality.phone
                  ? "+" +
                    (values && values.nationality && values.nationality.phone
                      ? values.nationality.phone
                      : "")
                  : ""}
              </InputAdornment>
            ),
            endAdornment:
              tab === "Early Minting" ||
              tab === "Minting (Uncapped)" ||
              tab === "Payment Tokens" ? (
                <InputAdornment position="end">
                  {tab === "Payment Tokens" && values["name" + index] ? (
                    <span className="ml-2 mr-2">{values["name" + index]}</span>
                  ) : null}
                  {(tab === "Early Minting" && values["status" + index]) ||
                  (tab === "Minting (Uncapped)" && values["status" + index]) ||
                  (tab === "Payment Tokens" && values["status" + index]) ? (
                    <CheckCircleOutlinedIcon color="success" />
                  ) : (
                    <CancelOutlinedIcon color="error" />
                  )}
                </InputAdornment>
              ) : null,
          }}
        />
        <ErrorMessage
          name={fieldName}
          component="div"
          className={"error-text"}
        />
      </div>
    </>
  );
};
export default TextField50;
