import { useState, useEffect, useContext } from "react";
// import { computeMerkle } from '../../helpers/merkleTree'
import { WalletContext } from "../../App";
import ExpandClaim from "./expandClaim";
import { toast } from "react-toastify";
import {
  GetVestingScheduleWithIndex,
  ICOClaim,
} from "../../Services/User/web3Calls/contractCall";
import SkeletonClaim from "./skeletonClaim";
import { useSigner, useProvider, useNetwork } from "wagmi";
import moment from "moment";

function ClaimTokens() {
  const [walletAddress] = useContext(WalletContext);
  const { chain } = useNetwork();
  const [claimArr, setClaimArr] = useState([]);
  const [loadingTx, setLoadingTx] = useState(false);
  // multi wallet
  const { data: signerData } = useSigner();
  const provider = useProvider();
  // multi wallet
  const [loading, setLoading] = useState(false);
  const [tokenSymbol] = useState(false);
  useEffect(() => {
    setClaimArr([]);
    fetchClaimData();
    // eslint-disable-next-line
  }, [walletAddress, loadingTx, chain]);
  const handleClaim = async (e, index) => {
    setLoadingTx(true);
    await ICOClaim(index, walletAddress, signerData, provider)
      .then((res) => {
        setLoadingTx(false);
      })
      .catch((err) => {
        // toast.error("Error" + err?.reason);
        toast.error(
          err?.code == "UNPREDICTABLE_GAS_LIMIT"
            ? "Your next claim is not unlocked"
            : err?.reason
        );
        setLoadingTx(false);
      });
  };
  const fetchClaimData = async () => {
    if (walletAddress !== "Connect Wallet") {
      setLoading(true);
      const data = await GetVestingScheduleWithIndex(walletAddress);
      setLoading(false);
      setClaimArr(data);
    } else {
      setLoading(false);
      setClaimArr([]);
    }
  };
  return (
    <>
      <div className="user-content">
        <div
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          className="bg-white pb-3 user-panel"
        >
          <h2 className="user-panel-title">Claims</h2>
          {loading ? (
            // <div className='d-flex justify-content-center p-3'>
            <SkeletonClaim />
          ) : (
            // </div>
            <>
              {claimArr?.length > 0 ? (
                <>
                  {claimArr.map((item, index) => {
                    return (
                      <div className="single-timeline-area bg-white">
                        <div
                          className="timeline-date wow fadeInLeft"
                          data-wow-delay="0.1s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.1s",
                            animationName: "fadeInLeft",
                          }}
                        >
                          <p key={index}>{item.depositedOn}</p>
                        </div>
                        <div className="row m-0">
                          <div className="col-12 col-md-12 col-lg-12 pl-2  claim-box">
                            <div
                              className="single-timeline-content d-flex wow fadeInLeft"
                              data-wow-delay="0.7s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.7s",
                                animationName: "fadeInLeft",
                                border: "1px solid #ff000038",
                              }}
                            >
                              {/* <div className="timeline-icon">
                                <i className="fa fa-picture-o" aria-hidden="true"></i>
                              </div> */}
                              <div className="timeline-text timeline-w-75">
                                <h5 className="">
                                  Total claimable:{" "}
                                  <span>
                                    {item.amount} {tokenSymbol}
                                  </span>
                                </h5>
                                {/* <p className='mt-2  mr-2'>
                                  <Clock date={item.time1} />
                                </p> */}
                                <p className="">
                                  Unlock on:{" "}
                                  <span>
                                    {claimArr[index]?.noClaimedMonths < 12
                                      ? claimArr[index]?.vestingMonths[
                                          claimArr[index]?.noClaimedMonths
                                        ]?.releaseDate
                                      : " All Claimed"}
                                    {/* {index===item.noClaimedMonths?<>
                             
                             </>} */}
                                    {/* {
                                      item?.vestingMonths[item?.noClaimedMonths]
                                        .releaseDate
                                    } */}
                                  </span>
                                  <br />
                                  Monthly tokens:{" "}
                                  <span>
                                    {item.monthlyRelease} {tokenSymbol}
                                  </span>
                                  <br />
                                  Claimed tokens:{" "}
                                  <span>
                                    {item.claimedToken} {tokenSymbol}
                                  </span>
                                  <ExpandClaim item={item} />
                                </p>
                              </div>
                              <div>
                                <button
                                  onClick={(e) => handleClaim(e, item.indexOn)}
                                  className={
                                    Date.now() / 1000 < item.object[2] ||
                                    moment(
                                      claimArr[index]?.vestingMonths[
                                        claimArr[index]?.noClaimedMonths
                                      ]?.releaseDate,
                                      "MMMM Do YYYY, LT"
                                    ).unix() > moment().unix() ||
                                    claimArr[index]?.noClaimedMonths > 11
                                      ? "disabled btn btn-primary"
                                      : "btn btn-primary"
                                  }
                                >
                                  Claim
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="text-left">
                    <h4 className="pt-3 text-black-theme mb-0">
                      ∅ No claims avalible
                    </h4>
                    <small>
                      Please buy tokens and make your deposit to see claims.{" "}
                    </small>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default ClaimTokens;
