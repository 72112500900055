import { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";

import { toast } from "react-toastify";
import GenericForm from "../Comps-Utils/GenericFormIcoSettings";
import {
  IcoInitialValues,
  getInitialValues,
  IcoSchema,
  IcoFormFields,
  CreateMintingInitialValue,
  CreateMintingFormFields,
  CreateMintingSchema,
  BonusSchema,
  BonusFormFields,
  BonusInitialValues,
  PricesInitialValues,
  PriceFormFields,
  PriceSchema,
  CreatePaymentTokenInitialValue,
  CreatePaymentTokensSchema,
  PaymentTokensFormFields,
  MakeArray,
  setBonusInitialValues,
  setPricesInitialValues,
  MakeArrayPaymentTokens,
  WithdrawTokensInitialValues,
  WithdrawTokensFormFields,
  WithdrawTokensSchema,
  setWithdrawTokenInitialValues,
  IcoFounderFormFields,
  FounderSchema,
  FounderInitialValues,
  setFounderInitialValues,
} from "./helpers";
// import { GetICOInfo, UpdateIcoInfo } from '../../Services/Admin/IcoSettings'
import { ToasterContext } from "../../App";
import {
  EarlyMintingUpdate,
  MintingUncappedUpdate,
  GetBonusData,
  updateBonusData,
  GetPriceData,
  updatePriceData,
  GetPreAndPublicDates,
  SetPreAndPublicDates,
  updatePaymentTokens,
  getPaymentTokens,
  withdrawPaymentTokens,
  Founder,
} from "../../Services/Admin/Web3call/contractCall";
import IcoSkelton from "../Comps-Utils/Skeltons/IcoSettingsSkelton";
import Web3 from "web3";
import { useProvider, useSigner } from "wagmi";
import {
  UpdateIcoInfo,
  UpdateIcoPriceInfo,
} from "../../Services/Admin/IcoSettings";
const IcoSettings = () => {
  const provider = useProvider();
  const { data: signerData } = useSigner();
  // wagmi call end
  const [general, setGeneral] = useState([]);
  const [bonus, setBonus] = useState([]);
  const [prices, setPrices] = useState([]);
  const [founder, setFounder] = useState([]);
  const [paymentTokens, setPaymentTokens] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Early Minting");
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [mintFields, setMintFields] = useState([0]);
  const [MintingFormFields, setMintingFormFields] = useState();
  const [MintingInitialValue, setMintingInitialValue] = useState({
    address0: "",
    amount0: "",
    status0: false,
  });
  const [MintingSchema, setMintingSchema] = useState();
  const [valuesTemp, setValuesTemp] = useState({});
  const [paymentTokensFormFields, setPaymentTokensFormFields] = useState();
  const [paymentTokensInitialValue, setPaymentTokensInitialValue] = useState({
    address0: "",
    amount0: "",
  });
  const [paymentTokenSchema, setPaymentTokenSchema] = useState();
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [EarlyMintingSkelton, setEarlyMintingSkelton] = useState(false);
  const [uncappedMintingSkelton, setuncappedMintingSkelton] = useState(false);
  const [FounderSkelton, setFounderSkelton] = useState(false);
  const [bonusSkelton, setBonusSkelton] = useState(true);
  const [datesSkelton, setDatesSkelton] = useState(true);
  const [paymentTokensSkelton, setPaymentTokensSkelton] = useState(false);
  const [priceSkelton, setPriceSkelton] = useState(false);
  const [fetchPaymentTokensSkelton, setFetchPaymentTokensSkelton] =
    useState(true);
  // const [reset, setReset] = useState(false);
  // const GetIcoService = () => {
  //   GetICOInfo().then(res => {
  //
  //     if (res.status === 200) {
  //       setGeneral(res.data[0])
  //     } else {
  //       setLoading(false)
  //       setTostify({
  //         ...tostify,
  //         visible: true,
  //         key: Math.random()
  //           .toString(36)
  //           .substring(7),
  //         type: res && res.status === 200 ? 'success' : 'error',
  //         message: res.message
  //       })
  //     }
  //   })
  // }
  useEffect(() => {
    setMintingFormFields(CreateMintingFormFields(mintFields));
    CreateMintingInitialValue(mintFields, setMintingInitialValue, valuesTemp);
    setMintingSchema(CreateMintingSchema(mintFields));
    setPaymentTokensFormFields(PaymentTokensFormFields(mintFields));
    CreatePaymentTokenInitialValue(
      mintFields,
      setPaymentTokensInitialValue,
      valuesTemp
    );
    setPaymentTokenSchema(CreatePaymentTokensSchema(mintFields));
  }, [mintFields, valuesTemp]);
  useEffect(() => {
    // GetIcoService();
    setLoading(true);
    // GetPreAndPublicDates();
    fetchDates();
    fetchBonuses();
    fetchPrices();
    fetchPaymentTokens();
    fetchFounder();
    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    fetchDates();
  }, []);
  const fetchDates = async () => {
    setDatesSkelton(true);
    const res = await GetPreAndPublicDates();
    setGeneral(res);
    setId(1);
    setDatesSkelton(false);
  };
  const fetchPaymentTokens = async () => {
    setLoading(true);
    setFetchPaymentTokensSkelton(true);
    const res = await getPaymentTokens();
    setPaymentTokens(res);
    setFetchPaymentTokensSkelton(false);
    setLoading(false);
  };
  const fetchBonuses = async () => {
    setBonusSkelton(true);
    const res = await GetBonusData();
    setBonus(res);
    setBonusSkelton(false);
  };
  const fetchPrices = async () => {
    setPriceSkelton(true);
    const res = await GetPriceData();
    setPrices(res);
    setPriceSkelton(false);
  };
  const fetchFounder = async () => {
    setFounderSkelton(true);
    // const res = await GetPriceData();
    setFounder([]);
    setFounderSkelton(false);
  };
  const updateEarlyMinting = async (data, actions) => {
    setEarlyMintingSkelton(true);
    let addressArray = [];
    let amountArray = [];
    addressArray = MakeArray("address", data);
    amountArray = MakeArray("amount", data);
    EarlyMintingUpdate(addressArray, amountArray, signerData, provider)
      .then((res) => {
        // if (res.status === true) {
        actions.setSubmitting(false);
        setEarlyMintingSkelton(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "success",
          message: "Successful Transaction",
        });
        // }
      })
      .catch((err) => {
        actions.setSubmitting(false);

        setEarlyMintingSkelton(false);
        toast.error(err?.reason);
      });
  };
  const updateMintingUncapped = async (data, actions) => {
    setuncappedMintingSkelton(true);
    let addressArray = [];
    let amountArray = [];
    addressArray = MakeArray("address", data);
    amountArray = MakeArray("amount", data);
    MintingUncappedUpdate(addressArray, amountArray, signerData, provider)
      .then((res) => {
        if (res.status === true) {
          actions.setSubmitting(false);
          setuncappedMintingSkelton(false);
          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: "success",
            message: "Successful Transaction",
          });
        }
      })
      .catch((err) => {
        actions.setSubmitting(false);
        setuncappedMintingSkelton(false);
        //
      });
  };
  const updateBonus = async (data, actions) => {
    setBonusSkelton(true);
    let amountArray = [];
    amountArray = MakeArray("bonus", data);
    updateBonusData(amountArray, signerData, provider)
      .then((res) => {
        if (res.resp.status === true) {
          actions.setSubmitting(false);
          setBonusSkelton(false);
          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: "success",
            message: "Successful Transaction",
          });
          setBonus(res.newData);
        }
      })
      .catch((err) => {
        actions.setSubmitting(false);
        setBonusSkelton(false);
      });
  };
  const updatePrices = async (data, actions) => {
    setPriceSkelton(true);
    let amountArray = [];
    amountArray = MakeArray("price", data);
    updatePriceData(amountArray, signerData, provider)
      .then((res) => {
        if (res.resp) {
          setPriceSkelton(false);
          UpdateIcoPriceInfo(data);

          actions.setSubmitting(false);
          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: "success",
            message: "Successful Transaction",
          });
          setPrices(res.newData);
        }
      })
      .catch((err) => {
        setPriceSkelton(false);
        actions.setSubmitting(false);
      });
  };
  const updateIcoService = async (data, actions) => {
    setDatesSkelton(true);
    const { presalestart, presaleend, publicsalestart, publicsaleend } = data;
    SetPreAndPublicDates(
      presalestart,
      presaleend,
      publicsalestart,
      publicsaleend,
      signerData,
      provider
    )
      .then((res) => {
        // if (res.resp.status === true) {
        UpdateIcoInfo(data);
        setDatesSkelton(false);
        actions.setSubmitting(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "success",
          message:
            "ICO dates are now successfully updated in smart contract and system.",
        });
        setGeneral(res.newData);
        // }
      })
      .catch((err) => {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message: "Error : " + err?.reason,
        });
        setDatesSkelton(false);
        actions.setSubmitting(false);
      });
  };
  const updatePaymentTokensService = async (data, actions) => {
    setPaymentTokensSkelton(true);
    let addressArray = [];
    addressArray = MakeArrayPaymentTokens("address", data);
    if (addressArray.length > 0) {
      updatePaymentTokens(addressArray, signerData, provider)
        .then((res) => {
          if (res.resp.status === true) {
            setPaymentTokensSkelton(false);
            actions.setSubmitting(false);
            setTostify({
              ...tostify,
              visible: true,
              key: Math.random().toString(36).substring(7),
              type: "success",
              message: "Successfully Updated Payment Tokens",
            });
          }
        })
        .catch((err) => {
          setPaymentTokensSkelton(false);
          actions.setSubmitting(false);
        });
    }
  };

  const withdrawPaymentTokensService = async (data, actions) => {
    setFetchPaymentTokensSkelton(true);
    withdrawPaymentTokens(
      data.index,
      Web3.utils.toWei(data.amount),
      signerData,
      provider
    )
      .then((res) => {
        if (res.resp.status === true) {
          setFetchPaymentTokensSkelton(false);
          actions.setSubmitting(false);
          actions.setFieldValue("amount", 0);

          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: "success",
            message: "Successfully Withdrawn Payment Tokens",
          });

          setLoading(true);
          setPaymentTokens(res.newData);
          setSelectedTab("Early Minting");
          setLoading(false);
        }
      })
      .catch((err) => {
        setFetchPaymentTokensSkelton(false);
        actions.setSubmitting(false);
      });
  };

  const updateFounder = async (data, actions) => {
    setFounderSkelton(true);
    const { address, amount } = data;

    Founder(address, Web3.utils.toWei(amount.toString()), signerData, provider)
      .then((res) => {
        if (res.resp) {
          setFounderSkelton(false);
          actions.setSubmitting(false);
          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: "success",
            message: "Successful Transaction",
          });
          setPrices(res.newData);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setFounderSkelton(false);
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      <Card className="mx-auto w95">
        {loading ? (
          <IcoSkelton />
        ) : (
          <GenericForm
            tostify={tostify}
            heading={"Ico Settings"}
            tab={selectedTab}
            alert={alert}
            skeltonLoading={
              selectedTab && selectedTab === "Early Minting"
                ? EarlyMintingSkelton
                : selectedTab && selectedTab === "Minting (Uncapped)"
                ? uncappedMintingSkelton
                : selectedTab && selectedTab === "Founder"
                ? FounderSkelton
                : selectedTab && selectedTab === "Bonus"
                ? bonusSkelton
                : selectedTab && selectedTab === "Ico Dates"
                ? datesSkelton
                : selectedTab && selectedTab === "Payment Tokens"
                ? paymentTokensSkelton
                : selectedTab && selectedTab === "Set Prices"
                ? priceSkelton
                : selectedTab && selectedTab === "Withdraw Tokens"
                ? fetchPaymentTokensSkelton
                : true
            }
            general={
              selectedTab && selectedTab === "Bonus"
                ? bonus
                : selectedTab && selectedTab === "Set Prices"
                ? prices
                : selectedTab && selectedTab === "Founder"
                ? founder
                : selectedTab && selectedTab === "Withdraw Tokens"
                ? paymentTokens
                : general
            }
            id={id}
            getValues={
              selectedTab && selectedTab === "Bonus"
                ? setBonusInitialValues
                : selectedTab && selectedTab === "Set Prices"
                ? setPricesInitialValues
                : selectedTab && selectedTab === "Founder"
                ? setFounderInitialValues
                : selectedTab && selectedTab === "Ico Dates"
                ? getInitialValues
                : selectedTab && selectedTab === "Withdraw Tokens"
                ? setWithdrawTokenInitialValues
                : getInitialValues
            }
            initialValues={
              (selectedTab && selectedTab === "Early Minting") ||
              selectedTab === "Minting (Uncapped)"
                ? MintingInitialValue
                : selectedTab === "Founder"
                ? FounderInitialValues
                : selectedTab && selectedTab === "Bonus"
                ? BonusInitialValues
                : selectedTab && selectedTab === "Ico Dates"
                ? IcoInitialValues
                : selectedTab && selectedTab === "Set Prices"
                ? PricesInitialValues
                : selectedTab && selectedTab === "Payment Tokens"
                ? paymentTokensInitialValue
                : selectedTab && selectedTab === "Ico Dates"
                ? IcoInitialValues
                : selectedTab && selectedTab === "Withdraw Tokens"
                ? WithdrawTokensInitialValues
                : IcoInitialValues
            }
            CreateMintingInitialValue={CreateMintingInitialValue}
            setMintingInitialValue={setMintingInitialValue}
            setSelectedTab={setSelectedTab}
            mintFields={mintFields}
            setMintFields={setMintFields}
            schema={
              (selectedTab && selectedTab === "Early Minting") ||
              selectedTab === "Minting (Uncapped)"
                ? MintingSchema
                : selectedTab === "Founder"
                ? FounderSchema
                : selectedTab && selectedTab === "Bonus"
                ? BonusSchema
                : selectedTab && selectedTab === "Ico Dates"
                ? IcoSchema
                : selectedTab && selectedTab === "Set Prices"
                ? PriceSchema
                : selectedTab && selectedTab === "Payment Tokens"
                ? paymentTokenSchema
                : selectedTab && selectedTab === "Withdraw Tokens"
                ? WithdrawTokensSchema
                : IcoSchema
            }
            formFields={
              (selectedTab && selectedTab === "Early Minting") ||
              selectedTab === "Minting (Uncapped)"
                ? MintingFormFields
                : selectedTab === "Founder"
                ? IcoFounderFormFields
                : selectedTab && selectedTab === "Bonus"
                ? BonusFormFields
                : selectedTab && selectedTab === "Ico Dates"
                ? IcoFormFields
                : selectedTab && selectedTab === "Set Prices"
                ? PriceFormFields
                : selectedTab && selectedTab === "Payment Tokens"
                ? paymentTokensFormFields
                : selectedTab && selectedTab === "Withdraw Tokens"
                ? WithdrawTokensFormFields
                : IcoFormFields
            }
            update={
              selectedTab && selectedTab === "Early Minting"
                ? updateEarlyMinting
                : selectedTab && selectedTab === "Minting (Uncapped)"
                ? updateMintingUncapped
                : selectedTab && selectedTab === "Founder"
                ? updateFounder
                : selectedTab && selectedTab === "Bonus"
                ? updateBonus
                : selectedTab && selectedTab === "Set Prices"
                ? updatePrices
                : selectedTab && selectedTab === "Payment Tokens"
                ? updatePaymentTokensService
                : selectedTab && selectedTab === "Withdraw Tokens"
                ? withdrawPaymentTokensService
                : updateIcoService
            }
            valuesTemp={valuesTemp}
            setValuesTemp={setValuesTemp}
            setTostify={setTostify}
          />
        )}
      </Card>
    </>
  );
};
export default IcoSettings;
