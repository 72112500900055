import { useEffect, useState, useContext } from "react";
import {
  createRows,
  UserKycTableHeadings,
  SetPermissionsHelpers,
} from "./helpers";
import KycTable from "../Comps-Utils/GenericTable";
import TableRows from "./TableRow";
import ModalBody from "./ModalBody";
import { ToasterContext, RightsContext } from "../../App";
// import {AddUser} from '../../Services/Admin/Kyc';
import {
  GetKycUsers,
  updateKycStatus,
  deleteUser,
  updateUserStatus,
} from "../../Services/Admin/Kyc";
import Loader from "../Comps-Utils/ClimbingLoader";
import Alert from "../Alert";
import { DialogMessage } from "../../helpers/dialouge";
import DialogBox from "../Comps-Utils/DialougeBox";

const Kyc = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ visible: false });
  const [dialog, setDialog] = useState({ visible: false });
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [updatedRow, setUpdatedRow] = useState({});
  const [actionState, setActionState] = useState("new");
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [rights, setRights] = useContext(RightsContext);

  const [kycRights, setKycRights] = useState({
    userStatus: false,
    kycStatus: false,
    viewKyc: false,
    editKyc: false,
    deleteKyc: false,
  });

  useEffect(() => {
    if (rights.sideBar) {
      let temp = SetPermissionsHelpers(rights.sideBar);
      setKycRights(temp);
    }
  }, [rights]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActionState("new");
    setUpdatedRow({});
  };

  const GetKycUsersService = () => {
    GetKycUsers().then((res) => {
      if (res.status === 200) {
        setUsers(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const Refetch = () => {
    GetKycUsers().then((res) => {
      if (res.status === 200) {
        setUsers(res.data);
      }
    });
  };

  const UpdateUserStatusService = (id, status) => {
    tostify.dismissAll();
    updateUserStatus(id, status).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setUsers(res.data);
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const UpdateStatusService = (data) => {
    tostify.dismissAll();

    updateKycStatus(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setUsers(res.data);
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const DeleteUserStatusService = (data) => {
    // setLoading(true);
    DialogMessage(
      setDialog,
      "",
      "Delete User",
      "Are you sure you want to delete user?",
      () => deleteUserFromKyc(data, true)
    );
  };
  const deleteUserFromKyc = (data, para2) => {
    deleteUser(data).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
        Refetch();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  useEffect(() => {
    GetKycUsersService();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <DialogBox {...dialog} />
      <Alert setAlert={setAlert} {...alert} />
      <div className="mx-auto w95">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="mt-3">
              <KycTable
                Refetch={Refetch}
                updatedRow={updatedRow}
                setUpdatedRow={setUpdatedRow}
                actionState={actionState}
                setActionState={setActionState}
                ModalBody={ModalBody}
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                tableHeading={"User Kyc"}
                loading={loading}
                data={users}
                TableRow={TableRows}
                createRows={createRows}
                headings={UserKycTableHeadings}
                updateUserStatus={UpdateUserStatusService}
                updateKycStatus={UpdateStatusService}
                deleteRow={DeleteUserStatusService}
                kycRights={kycRights}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Kyc;
