import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import {
  FaInfo,
  FaCheck,
  FaExclamationTriangle,
  FaBug,
  FaExclamationCircle,
} from "react-icons/fa";
export const displayIcon = (type) => {
  switch (type) {
    case "success":
      return <FaCheck />;
    case "info":
      return <FaInfo />;
    case "error":
      return <FaExclamationCircle />;
    case "warning":
      return <FaExclamationTriangle />;
    default:
      return <FaBug />;
  }
};
const AlertToaster = (props) => {
  const customId = "custom-id-yes";
  const location = useLocation();
  const [open, setOpen] = useState(props.visible);
  // const dismissAll = () => toast.dismiss();
  // window.addEventListener("click", function (event) {
  //   dismissAll();
  //   props.setTostify({visible:false})
  // });
  const ToastMessage = useCallback(({ type, message }) => {
    toast[type](
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1, fontSize: 15, padding: "8px 12px" }}>
          {message}
        </div>
      </div>,
      { toastId: customId }
    );
  }, []);
  useEffect(() => {
    setOpen(props.visible);
  }, [props.visible, props.message, props.type, location]);
  if (!open) return null;
  return <ToastMessage type={props.type} message={props.message} />;
};
export default AlertToaster;
