import { ToasterContext, WalletContext } from "../../../App";
import { useContext, useEffect } from "react";
// import Web3 from "web3";
import { useAccount } from "wagmi";
import { useNetwork } from "wagmi";
import { chains } from "../../../Config/networkConfig";

const useConnectWallet = async () => {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain } = useNetwork();
  const [tostify, setTostify] = useContext(ToasterContext);

  // eslint-disable-next-line
  const [walletAddress, setWalletAddress] = useContext(WalletContext);
  useEffect(() => {
    if (isDisconnected) {
      setWalletAddress("Connect Wallet");
    }
    if (isConnecting) {
      setWalletAddress("Connect Wallet");
    }
    // change chain here

    GetSpecific();
    if (address) {
      setWalletAddress(address);
    }
    if (address === "undefined") {
      setWalletAddress("Connect Wallet");
    }

    //eslint-disable-next-line
  }, [chain, address]);
  // useEffect(() => {
  //   window.location.reload();
  // }, [chain]);

  const GetSpecific = async () => {
    if (chain?.id && address) {
      var keys = Object.values(chains).filter(
        (item) => item.chainId === chain?.id
      );
      if (keys.length === 0) {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message:
            "Wrong Network Selection, Switch network to supported network",
        });
      }
    }
  };
};

export default useConnectWallet;
