import React from "react";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
function SaleEnded() {
  return (
    <>
      <div className="user-content">
        <div className="user-panel">
          <h2>Sales</h2>

          <div className="status status-empty">
            <div className="status-icon p-4">
              <HourglassTopIcon />
            </div>
            <span className="status-text">Public And PreSale Ended</span>
          </div>
          <div className="note note-md note-info note-plane">
            <em className="fas fa-info-circle"></em>
            <p>Some of countries and regions will not able to Participate</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SaleEnded;
