import moment from "moment";
import { MMMM_Do_YYYY, h_mm_ss_a } from "../../helpers/constants";
import {
  TEXT_LEFT,
  D_NONE__D_SM_TABLE_cell,
  TEXT_DARK,
} from "../../helpers/CLASSES";
// import Tooltip from "@mui/material/Tooltip";
const TableRow = (props) => {
  const { row, index } = props;

  return (
    <>
      <tr key={row.id + index} id={"transaction-tr-" + index}>
        {/* <td className="px-4 text-left text-capitalize">
          <span>{row.userId}</span>
        </td> */}
        <td className="text-left">
          <span>👤 {row.name}</span>
          <span style={{ fontSize: "10px", textTransform: "initial" }}>
            📧 {row.email}
          </span>
        </td>

        <td className={TEXT_LEFT}>
          <span className="expected_amount">
            <b>💸 Expected</b>: {row.expectedAmount} {row.paymentMethod}
          </span>
          <span className="received_amount">
            <b>💰 Received</b>: {row.actualamount} {row.paymentMethod}
          </span>
        </td>
        <td className={TEXT_LEFT}>
          <span>
            ⭕ {row.payableToken} {row.tokens}
          </span>
          <span className={D_NONE__D_SM_TABLE_cell}>
            <span
              className={TEXT_DARK}
              style={{ fontSize: "10px", textTransform: "initial" }}
            >
              {" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {row.saleType}
            </span>
          </span>
        </td>
        {/* <td className="text-left">
          <span>{row.saleType}</span>
        </td> */}

        {/* <td className="text-left">
          <span className="d-none d-sm-table-cell">
            <span>{row.actualamount}</span>
          </span>
        </td>
        <td className="text-left">
          <span className="d-none d-sm-table-cell">
            <span>{row.expectedamount}</span>
          </span>
        </td> */}
        {/* <td className={TEXT_LEFT}>
          <span className={D_NONE__D_SM_TABLE_cell}>
            <span className={TEXT_DARK}>👛 {row.recevingAddress}</span>
            <span className={TEXT_DARK}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>{row.saleType}</small></span>
          </span>
        </td> */}
        <td className={TEXT_LEFT}>
          <span>🕛 {moment(row.updatedAt).format(MMMM_Do_YYYY)}</span>
          <span>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            {moment(row.updatedAt).format(h_mm_ss_a)}
          </span>
        </td>
        <td className="text-left">
          <span
            className="d-none d-sm-table-cell "
            style={{ textTransform: "lowercase !important" }}
          >
            <span
              style={{
                background: row.statusBGColor,
                textTransform: "lowercase !important",
                padding: "7px 10px",
                borderRadius: "20px",
                color: row.statusColor,
              }}
            >
              {row.status}
            </span>
          </span>
        </td>
      </tr>
    </>
  );
};
export default TableRow;
