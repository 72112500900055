import { useState, useEffect, useContext } from "react";
import { ClipLoader } from "react-spinners";
import {
  AIRDROP_DETAILS,
  TOTAL_AMOUNT,
  TOTAL_ADDRESSES,
  SELECTED_ADDRESSES,
} from "../../helpers/constants";
import { ToasterContext } from "../../App";
import { ManualDepositTokens } from "../../Services/User/web3Calls/contractCall";
import {
  AirDropTokens,
  GetReferralTransactionInfo,
} from "../../Services/User/Tokens/token";
import web3 from "web3";
import { useSigner, useProvider } from "wagmi";
import { GetAllTransactionsAirDrop } from "../../Services/Admin/Dashboard";

function AirdropDetail({ data, selected, setTransactions }) {
  const [filterData, setFilterData] = useState([]);
  const provider = useProvider();
  const { data: signerData } = useSigner();
  const [tostify, setTostify] = useContext(ToasterContext);
  // eslint-disable-next-line
  const [filterAddress, setFilterAddress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [airdropVal, setAirdropVal] = useState(0);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [selected]);
  const fetchData = () => {
    var filter = [];
    var address = [];
    for (let i = 0; i < selected.length; i++) {
      let temp = data.filter((d) => d._id === selected[i]);
      filter.push(...temp);
      address.push(temp[0].recevingaddress);
    }
    setFilterData(filter);
    setFilterAddress([...new Set(address)]);
  };
  useEffect(() => {
    if (filterData.length > 0) {
      var sum = 0;
      // eslint-disable-next-line
      filterData.map((f) => {
        var payabletoken =
          f.actualamount === ""
            ? 0
            : (
                (f.biddingtokenprice / f.claimtokenprice) * f.actualamount +
                parseFloat(f.bonus)
              ).toFixed(9);

        sum = sum + parseFloat(payabletoken);
      });
      setAirdropVal(sum);
    } else {
      setAirdropVal(0);
    }
  }, [filterData]);

  const handleAirDrop = async () => {
    if (filterData.length > 0) {
      // const decimal = await ICODecimal();
      let recevingAddress = [];
      let recevingAmount = [];
      let transaction = [];
      let saleType = [];
      let public3M = [];
      let public6M = [];
      let referAddress = [];
      // let amou = 1;
      for (let i = 0; i < filterData.length; i++) {
        const {
          recevingaddress,
          _id,
          biddingtokenprice,
          actualamount,
          bonus,
          userid,
          claimtokenprice,
          saletype,
        } = filterData[i];
        // let amount = parseFloat(
        //   parseFloat(actualamount) * parseFloat(biddingtokenprice) +
        //   parseFloat(bonus)
        // ).toFixed(3);

        let amount = parseFloat(
          (parseFloat(biddingtokenprice) / parseFloat(claimtokenprice)) *
            parseFloat(actualamount) +
            parseFloat(bonus)
        );
        amount = amount * claimtokenprice;

        web3.utils.toWei(amount.toString(), "ether");
        let amount2 = web3.utils.toWei(amount.toString(), "ether");
        recevingAddress.push(recevingaddress);
        recevingAmount.push(amount2);

        let isPublicSale = saletype === "Public Sale" ? true : false;

        if (isPublicSale) {
          saleType.push(false);
          public3M.push(false);
          public6M.push(true);
        } else {
          saleType.push(true);
          public3M.push(false);
          public6M.push(false);
        }

        const referralAddress = await GetReferralTransactionInfo(userid);
        referAddress.push(referralAddress.data);
        transaction.push(_id);
      }
      setLoading(true);
      ManualDepositTokens(
        recevingAddress,
        recevingAmount,
        saleType,
        public3M,
        public6M,
        referAddress,
        signerData,
        provider
      )
        .then(async (res) => {
          setLoading(false);

          // change these transaction status so that these will not appear in future airdrops
          const airdropDb = await AirDropTokens({ transaction: transaction });
          console.log("airdrop responce from db", airdropDb);
          const getbackData = await GetAllTransactionsAirDrop();
          setTransactions(getbackData.data);
          setLoading(false);
          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: "success",
            message: "Successfully Airdropped",
          });
          console.log("airdrop list", filterData);
        })
        .catch((err) => {
          setLoading(false);
          setTostify({
            ...tostify,
            visible: true,
            key: Math.random().toString(36).substring(7),
            type: "error",
            message: "Error : " + err?.reason,
          });
        });
    }
  };

  return (
    <div className="card card-airdrop">
      <div
        className="card-header d-flex justify-content-center"
        style={{
          color: "white",
          background:
            "linear-gradient(to right, rgb(50 72 88), rgba(255,0,0,1))",
        }}
      >
        <h4 className="text-white text-center"> {AIRDROP_DETAILS}</h4>
      </div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          {TOTAL_AMOUNT} :<span className="text-primary"> {airdropVal} </span>
        </li>

        <li className="list-group-item">
          {SELECTED_ADDRESSES}:{" "}
          <span className="text-primary"> {filterData.length} </span>
        </li>

        <li className="list-group-item">
          {TOTAL_ADDRESSES}:
          <span className="text-primary"> {data.length} </span>
        </li>
      </ul>
      <div className="card-body d-flex justify-content-center">
        <button
          className={
            filterData.length > 0
              ? "btn btn-primary d-flex justify-content-center"
              : "btn btn-primary disabled"
          }
          onClick={handleAirDrop}
        >
          {loading ? <ClipLoader color={"white"} /> : <span>Airdrop</span>}
        </button>
      </div>
    </div>
  );
}
export default AirdropDetail;
