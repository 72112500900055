import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { ForgetPasswordApi } from "../../Services/User/User";
import Clip from "../../components/Comps-Utils/ClipLoader";
import { ToasterContext } from "../../App";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
const Confirm_password = () => {
  const { id } = useParams();
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);

  const [loading, setLoading] = useState(false);
  const [passwordDetails, setPasswordDetails] = useState({
    password: "",
    cpassword: "",
  });

  const [showPass, setShowPass] = useState({
    password: "",
    confirmpass: "",
  });
  const [errors, setErrors] = useState({
    password: "",
    cpassword: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setPasswordDetails({ ...passwordDetails, [name]: value });
  };
  const handleVisiblityPassword = (field) => {
    if (field === "pass") {
      let pass = !showPass.password;
      setShowPass({ ...showPass, password: pass });
    } else if (field === "rpass") {
      let pass = !showPass.cpassword;
      setShowPass({ ...showPass, cpassword: pass });
    }
  };

  const checkValidation = () => {
    let errors = {};

    if (passwordDetails.password === "") {
      errors.password = "password is empty";
    } else if (passwordDetails.password.length < 8) {
      errors.password = "password is less than 8 character";
    }

    if (passwordDetails.cpassword === "") {
      errors.cpassword = "confirm password is empty";
    } else if (passwordDetails.cpassword.length < 8) {
      errors.cpassword = "confirm password is less than 8 character";
    } else if (passwordDetails.password !== passwordDetails.cpassword) {
      errors.cpassword = "password doesn't match";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const handleSubmit = async (e) => {
    const validate = checkValidation();
    e.preventDefault();
    if (validate) {
      setLoading(true);
      const key = {
        id: id,
        pass: passwordDetails.password,
      };
      const res = await ForgetPasswordApi(key);
      if (res.data.code === 200) {
        setLoading(false);
        window.location.href = "/login";
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    } else {
      // console.log("errors");
    }
  };

  return (
    <>
      <div className="user-ath">
        <div className="user-ath-page">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 text-center">
                <div className="user-ath-logo">
                  <span>
                    <img
                      src="../images/logo.png"
                      srcSet="images/logo-sm2x.png 2x"
                      alt="icon"
                    />
                  </span>
                </div>
                <div className="user-ath-box">
                  <h4>Confirm Your Password</h4>
                  <form action="#" className="user-ath-form">
                    <div className="input-item passwordInput">
                      <input
                        type={showPass.password ? "password" : "text"}
                        placeholder="Your Password"
                        className="input-bordered"
                        name="password"
                        onChange={handleChange}
                      />
                      <i
                        className="passwordIcon"
                        onClick={() => {
                          handleVisiblityPassword("pass");
                        }}
                      >
                        {showPass.password ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </i>
                      <span className="error">{errors.password}</span>
                    </div>
                    <div className="input-item">
                      <input
                        type={showPass.cpassword ? "password" : "text"}
                        placeholder="Confirm Password"
                        className="input-bordered"
                        name="cpassword"
                        onChange={handleChange}
                      />
                      <i
                        style={{
                          position: "absolute",
                          right: "55px",
                          marginTop: "10px",
                        }}
                        onClick={() => {
                          handleVisiblityPassword("rpass");
                        }}
                      >
                        {showPass.cpassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </i>
                      <span className="error">{errors.cpassword}</span>
                    </div>
                    <div className="gaps"></div>
                    <div>
                      <button
                        onClick={handleSubmit}
                        className="btn btn-primary d-flex"
                      >
                        {loading ? <Clip /> : <>Reset Password</>}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="gaps-2x"></div>
                <div className="form-note">
                  {" "}
                  Not a member? <a href="signup.html">Sign up now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirm_password;
