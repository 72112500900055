import { useState, useContext, useEffect } from "react";
import { UpdateUserWallet } from "../../Services/User/User/user.js";
import Clip from "../../components/Comps-Utils/ClipLoader";
import { GetUserWallet } from "../../Services/User/User/user.js";
import { ToasterContext } from "../../App";

const NewWalletAddress = ({ accountInfo }) => {
  // eslint-disable-next-line
  const [tostify, setTostify] = useContext(ToasterContext);
  const [loading, setLoading] = useState(false);
  const [walletInfo, setWalletInfo] = useState({
    walletNetwork: accountInfo.walletNetwork,
    walletNetworkValue: ["Ethereum"],

    walletAddress1: "",
  });
  const user =
    localStorage.getItem("user") === "undefined"
      ? { email: "", username: "", _id: "" }
      : JSON.parse(localStorage.getItem("user"));

  const [errors, setError] = useState({
    walletNetwork: "",
    walletAddress1: "",
  });

  useEffect(() => {
    fetchWalletData();
    // eslint-disable-next-line
  }, []);

  const fetchWalletData = async () => {
    const data = await GetUserWallet();
    setWalletInfo({
      ...walletInfo,
      walletNetwork: data.data.walletNetwork,
      walletAddress1: data.data.walletAddress,
    });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setWalletInfo({ ...walletInfo, [name]: value });
  };

  const checkValidation = () => {
    let errors = {};
    if (walletInfo.walletAddress1 === "") {
      errors.walletAddress1 = "wallet Address is empty";
    }
    const walletRegex = new RegExp(/^0x[a-fA-F0-9]{40}$/g);
    if (!walletRegex.test(walletInfo.walletAddress1)) {
      errors.walletAddress1 = "Invalid  wallet Address";
    }

    if (Object.keys(errors).length > 0) {
      setError(errors);
      return false;
    } else {
      setError({});
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = checkValidation();

    if (validate) {
      setLoading(true);
      const wallet = await UpdateUserWallet(walletInfo);

      if (wallet.code === 200) {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "success",
          message: "Successfully Updated",
        });
        user.walletAddress = walletInfo.walletAddress1;
        console.log(">>>>>>>>>>>>>", JSON.stringify(user));
        localStorage.setItem("user", JSON.stringify(user));
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: "error",
          message: "Error Server/network ",
        });
      }
    } else {
      // console.log("failed");
    }
  };

  return (
    <>
      <p>
        In order to receive your Tokens, please select your wallet address and
        you have to put the address below input box. You will receive tokens to
        this address after the Token Sale end.
      </p>
      <form action="#">
        {/* <div className="row">
          <div className="col-md-6">
            <div className="input-item input-with-label">
              <label htmlFor="swalllet" className="input-item-label">
                Select Wallet
              </label>
              <select
                className="input-bordered"
                name="walletNetwork"
                style={{ border: "2px solid #D3E0F3" }}
                id="walletNetworkValue"
                value={walletInfo.walletNetwork}
                onChange={handleChange}
              >
                <option value="" name="">
                  Select Wallet Source
                </option>
                {walletInfo.walletNetworkValue.map((item, index) => {
                  return (
                    <option value={item} key={index} name={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
              <span className="error">{errors.walletNetwork}</span>
            </div>
          </div>
        </div> */}
        <div className="input-item input-with-label">
          <label htmlFor="token-address" className="input-item-label">
            Your Address for tokens:
          </label>
          <input
            className="input-bordered"
            type="text"
            id="token-address"
            value={walletInfo.walletAddress1}
            name="walletAddress1"
            onChange={handleChange}
          />
          <div className="error">{errors.walletAddress1}</div>
          <span className="input-note">
            Note: Address should be ERC20-compliant.
          </span>
        </div>
        <div className="gaps-2x"></div>
        <div className="note note-plane note-danger">
          <em className="fas fa-info-circle"></em>
          <p>
            DO NOT USE your exchange wallet address such as Kraken, Bitfinex,
            Bithumb, Binance etc. You can use MetaMask, MayEtherWallet, Mist
            wallets etc. Do not use the address if you don’t have a private key
            of the your address. You WILL NOT receive Tokens and WILL LOSE YOUR
            FUNDS if you do.
          </p>
        </div>
        <div className="gaps-3x"></div>
        <div className="gaps-1x"></div>{" "}
        <div className="d-sm-flex justify-content-between align-items-center">
          <button
            className="btn btn-primary d-flex justify-content-center align-items-center"
            onClick={handleSubmit}
          >
            {loading ? <Clip /> : <>Update</>}
          </button>
          <div className="gaps-2x d-sm-none"></div>
          {/* <span className='color-success'>
            <em className='ti ti-check-box'></em> Saved your wallet address
          </span> */}
        </div>
      </form>
    </>
  );
};

export default NewWalletAddress;
