import { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import TableRows from "./Tablerow";
import TemplatesTable from "../Comps-Utils/GenericTable";
import SkeltonTable from "../Comps-Utils/Skeltons/TableSkelton";
import {
  GetEmailTemplates,
  AddEmailTemplate,
  UpdateEmailTemplate,
} from "../../Services/Admin/EmailTemplates";
import { ToasterContext } from "../../App";
import {
  emailInitialValues,
  EmailSchema,
  setInitialValues,
  EmailFormFields,
  createRows,
  EmailListingTableHeadings,
} from "./helpers";
const EmailTemplates = () => {
  const [general, setGeneral] = useState([]);
  const [tostify, setTostify] = useContext(ToasterContext);
  const [loading, setLoading] = useState(true);
  const [updatedRow, setUpdatedRow] = useState({});
  const [actionState, setActionState] = useState("new");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActionState("new");
    setUpdatedRow({});
  };
  const GetEmailsService = () => {
    GetEmailTemplates().then((res) => {
      if (res.status === 200) {
        setGeneral(res.data);
        setLoading(false);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const DeleteEmailService = (id) => {
    tostify.dismissAll();
  };
  const AddEmailService = async (data, actions) => {
    tostify.dismissAll();
    AddEmailTemplate(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        // GetEmailsService();
        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  const updateEmailService = async (data, actions) => {
    tostify.dismissAll();
    UpdateEmailTemplate(data).then((res) => {
      if (res.status === 200 && res.code === 200) {
        setGeneral(res.data);
        // GetEmailsService();
        handleClose();
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.code === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  useEffect(() => {
    GetEmailsService();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Card className="mx-auto w95 mt-3">
        {loading ? (
          <SkeltonTable header={[0, 1, 2]} rows={[0, 1, 2]} />
        ) : (
          <>
            <div className="email-formating">
              <h4>
                Please use mentioned string for respective email templates.{" "}
              </h4>
              <ul className="d-flex align-items-center justify-content-between">
                <li>
                  <span className="me-1">
                    <strong>Reset Password:</strong>{" "}
                  </span>
                  <span>http://RESET-PASSWORD</span>
                </li>
                <li>
                  <span className="me-1">
                    <strong>Email Verification:</strong>
                  </span>{" "}
                  <span>http://NEW-USER-EMAIL</span>
                </li>
                <li>
                  <span className="me-1">
                    <strong>Payment Transaction:</strong>{" "}
                  </span>
                  <span>http://TRANSACTION-DETAIL-LINK</span>
                </li>
              </ul>
            </div>
            <TemplatesTable
              //states
              isModal={true}
              modalHeading={"Email Template"}
              tableHeading={"Manage Emails"}
              loading={loading}
              data={general}
              actionState={actionState}
              open={open}
              updatedRow={updatedRow}
              //setters
              setUpdatedRow={setUpdatedRow}
              setActionState={setActionState}
              setLoading={setLoading}
              setGeneral={setGeneral}
              //Handlers
              handleOpen={handleOpen}
              handleClose={handleClose}
              //Table Row Components
              TableRow={TableRows}
              //Helpers States &  Function
              createRows={createRows}
              headings={EmailListingTableHeadings}
              initialValues={emailInitialValues}
              schema={EmailSchema}
              setInitialValues={setInitialValues}
              formFields={EmailFormFields}
              //Services
              deleteRow={DeleteEmailService}
              add={AddEmailService}
              update={updateEmailService}
              tostify={tostify}
            />
          </>
        )}
      </Card>
    </>
  );
};
export default EmailTemplates;
