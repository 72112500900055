import Web3 from "web3";

import moment from "moment";
import IcoAbi from "./icoTokenMeta";
import Bep20Abi from "./BEP20TokenAbi";
import Multicall from "@dopex-io/web3-multicall";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { chains } from "../../../Config/networkConfig";
// import { getSpecificNetworks } from "../../NetworkConfig/networks";

// initiate Providers And default Set if no provider is given
var multicall;
var web3;
web3 = new Web3(window.ethereum || process.env.REACT_APP_RPC_URL, {
  headers: [
    {
      name: "Access-Control-Allow-Origin",
      value: process.env.REACT_APP_RPC_URL,
    },
  ],
});

if (window.ethereum !== undefined) {
  try {
    web3.eth.getAccounts().then(async (address) => {
      connectedWallet = address[0];
    });
  } catch (error) {
    console.log("error", error);
  }
}
var ICOAddress = process.env.REACT_APP_ICO_ADDRESS
  ? process.env.REACT_APP_ICO_ADDRESS
  : "0x000";
var multiCallAddress;
var chainIdWeb3;
var connectedWallet;

// case 1 : window.ethereum undefined
// case 2 : wrong network configuration
// case 3 : tokenAddress and contract address different
// case 4 :window.ethereum defined and right
export const FetchProvider = async (contractAddress, contractAbi) => {
  if (window.ethereum === undefined) {
    multiCallAddress = process.env.REACT_APP_MULTI_CALL;
    contractAddress = process.env.REACT_APP_ICO_ADDRESS;
    chainIdWeb3 = process.env.REACT_APP_CHAIN_ID;
    ICOAddress = process.env.REACT_APP_ICO_ADDRESS;
    web3 = new Web3(process.env.REACT_APP_RPC_URL);
    multicall = new Multicall({
      multicallAddress: multiCallAddress,
      // process.env.REACT_APP_MULTI_CALL,
      provider: web3,
    });
    if (Web3.utils.isAddress(contractAddress)) {
      return new web3.eth.Contract(contractAbi, contractAddress);
    }
  } else {
    chainIdWeb3 = await web3.eth.getChainId();
    if (chainIdWeb3 !== undefined) {
      var keys = Object.values(chains).filter(
        (item) => item.chainId === chainIdWeb3
      );
      if (keys.length === 0) {
        multiCallAddress = process.env.REACT_APP_MULTI_CALL;
        contractAddress = process.env.REACT_APP_ICO_ADDRESS;
        chainIdWeb3 = process.env.REACT_APP_CHAIN_ID;
        ICOAddress = process.env.REACT_APP_ICO_ADDRESS;
        web3 = new Web3(process.env.REACT_APP_RPC_URL);
        multicall = new Multicall({
          // process.env.REACT_APP_MULTI_CALL,
          multicallAddress: multiCallAddress,
          provider: web3,
        });
        if (Web3.utils.isAddress(contractAddress)) {
          return new web3.eth.Contract(contractAbi, contractAddress);
        }
      } else {
        multiCallAddress = keys[0].multiCall;
        ICOAddress = keys[0].icoContractAddress;
        if (contractAddress !== "0X000") {
          // contractAddress = contractAddress;
        } else {
          contractAddress = keys[0].icoContractAddress;
        }
        web3 = new Web3(keys[0].defaultRpc);
        multicall = new Multicall({
          multicallAddress: multiCallAddress,
          provider: web3,
        });
        if (Web3.utils.isAddress(contractAddress)) {
          return new web3.eth.Contract(contractAbi, contractAddress);
        }
      }
    } else {
      multiCallAddress = process.env.REACT_APP_MULTI_CALL;
      contractAddress = process.env.REACT_APP_ICO_ADDRESS;
      chainIdWeb3 = process.env.REACT_APP_CHAIN_ID;
      ICOAddress = process.env.REACT_APP_ICO_ADDRESS;
      web3 = new Web3(process.env.REACT_APP_RPC_URL);
      multicall = new Multicall({
        multicallAddress: multiCallAddress,
        // process.env.REACT_APP_MULTI_CALL,
        provider: web3,
      });
      if (Web3.utils.isAddress(contractAddress)) {
        return new web3.eth.Contract(contractAbi, contractAddress);
      }
    }
  }
};

// fetch provider with ether js for rainbow kit and multi wallet support
const FetchEtherProvider = async (contractAddress, contractAbi, provider) => {
  chainIdWeb3 = await web3.eth.getChainId();
  if (chainIdWeb3) {
    // const keys = await getSpecificNetworks(chainIdWeb3);
    var keys = Object.values(chains).filter(
      (item) => item.chainId === chainIdWeb3
    );
    if (keys.length > 0) {
      multiCallAddress = keys[0].multiCall;
      // debugger;
      if (contractAddress.toLowerCase() !== "0x000".toLowerCase()) {
        // contractAddress = contractAddress;
      } else {
        contractAddress = keys[0].icoContractAddress;
      }
      if (Web3.utils.isAddress(contractAddress)) {
        return new ethers.Contract(contractAddress, contractAbi, provider);
      }
    }
  } else {
    toast.error("No wallet Connected/Chain is connected");
  }
};
// ---------------------
// ---------------------
// ---------------------
// Read Contract methods
// ---------------------
// ---------------------

export const CheckApproveAmount = async (tokenAddress, walletAddress) => {
  console.log("tokenAddress", tokenAddress);
  console.log("walletAddress", walletAddress);
  console.log("ICOAddress=====", ICOAddress);
  const contract = await FetchProvider(tokenAddress.address, Bep20Abi);
  const approve = await contract.methods
    .allowance(walletAddress, ICOAddress)
    .call();
  return approve.toString();
};
export const GetPreSale = async () => {
  const contract = await FetchProvider(ICOAddress, IcoAbi);
  var multicallDates = [];
  for (let i = 0; i < 2; i++) {
    multicallDates[i] = contract.methods.dates(i);
  }
  const contractData = await multicall.aggregate(multicallDates);

  const Info = {
    presalestart: contractData[0],
    presaleend: contractData[1],
  };
  return Info;
};
export const GetPublicSale = async () => {
  const contract = await FetchProvider(ICOAddress, IcoAbi);
  var multicallDates = [];
  for (let i = 2; i < 4; i++) {
    multicallDates[i] = contract.methods.dates(i);
  }

  const contractData = await multicall.aggregate(multicallDates);

  const Info = {
    publicsalestart: contractData[2],
    publicsaleend: contractData[3],
  };
  return Info;
};
export const GetPreAndPublicDates = async () => {
  var multicallDates = [];
  const contract = await FetchProvider(ICOAddress, IcoAbi);
  if (contract !== undefined) {
    for (let i = 0; i < 4; i++) {
      multicallDates[i] = contract.methods.dates(i);
    }
    const contractData = await multicall.aggregate(multicallDates);
    const Info = {
      presalestart: contractData[0],
      presaleend: contractData[1],
      publicsalestart: contractData[2],
      publicsaleend: contractData[3],
    };
    return Info;
  }
};
export const ICOTokenDetails = async (walletAddress) => {
  const contract = await FetchProvider(ICOAddress, IcoAbi);
  const value = await contract.methods.userPublicSaleInfo(walletAddress).call();
  return value;
};
export const ICOTokenName = async (tokenAddress, walletAddress) => {
  const contract = await FetchProvider(ICOAddress, IcoAbi);
  if (contract !== undefined) {
    const symbol = await contract.methods.name().call();
    return symbol;
  }
};
export const ICODecimal = async () => {
  const contract = await FetchProvider(ICOAddress, IcoAbi);
  const decimal = await contract.methods.decimals().call();
  return decimal;
};
export const ICOTokenPrice = async (index) => {
  const contract = await FetchProvider(ICOAddress, IcoAbi);

  const contractData = await multicall.aggregate([
    contract.methods.decimals(),
    contract.methods.prices(index),
  ]);
  const decimal = contractData[0];
  const price = contractData[1];

  const value = parseFloat(price / Math.pow(10, decimal));
  return value;
};

export const GetVestingSchedule = async (walletAddress) => {
  const contract = await FetchProvider(ICOAddress, IcoAbi);
  const schedule = await contract.methods
    .getVestingSchedule(walletAddress)
    .call();
  return schedule;
};
export const GetVestingCount = async (walletAddress) => {
  const contract = await FetchProvider(ICOAddress, IcoAbi);
  if (contract !== undefined) {
    const countOfVestings = parseInt(
      await contract.methods.holdersVestingCount(walletAddress).call()
    );
    return countOfVestings;
  }
};
export const GetVestingScheduleWithIndex = async (walletAddress) => {
  const contract = await FetchProvider(ICOAddress, IcoAbi);
  if (contract !== undefined) {
    const countOfVestings = parseInt(
      await contract.methods.holdersVestingCount(walletAddress).call()
    );
    var multicallVestings = [];
    for (let i = 0; i < countOfVestings; i++) {
      multicallVestings[i] =
        contract.methods.getVestingScheduleByAddressAndIndex(walletAddress, i);
    }
    const contractData = await multicall.aggregate(multicallVestings);
    const decimals = await ICODecimal();
    let dummyArr = [];
    // contractData.reverse();
    for (let i = 0; i < contractData.length; i++) {
      var forMonths = parseFloat(
        parseFloat(contractData[i][4]) / parseFloat(contractData[i][5])
      );
      var _vestingMonths = [];

      var monthlyRelease = (
        parseFloat(contractData[i][7]) / Math.pow(10, decimals)
      ).toFixed(4);
      if (forMonths > 0) {
        monthlyRelease = (
          parseFloat(contractData[i][7]) /
          Math.pow(10, decimals) /
          forMonths
        ).toFixed(4);
      }
      var noClaimedMonths = Math.ceil(
        parseFloat(contractData[i].released) /
          Math.pow(10, decimals) /
          monthlyRelease
      );

      if (forMonths > 0) {
        var calDate =
          parseFloat(contractData[i][2]) + parseFloat(contractData[i][5]);
        _vestingMonths.push({
          releaseDate: moment
            .unix(contractData[i][2].toString())
            .format("MMMM Do YYYY, h:mm a"),
        });
        for (let j = 0; j < forMonths - 1; j++) {
          _vestingMonths.push({
            releaseDate: moment.unix(calDate).format("MMMM Do YYYY, h:mm a"),
          });
          calDate = calDate + parseFloat(contractData[i][5]);
        }
      }
      const dummyObj = {
        indexOn: i,
        amount: parseFloat(contractData[i][7]) / Math.pow(10, decimals),
        claimedToken: (
          parseFloat(contractData[i][8]) / Math.pow(10, decimals)
        ).toFixed(4),
        object: contractData[i],
        forMonths: forMonths,
        depositedOn: moment
          .unix(contractData[i][3].toString())
          .format("MMMM Do YYYY, h:mm a"),
        unlockOn: moment
          .unix(contractData[i][2].toString())
          .format("MMMM Do YYYY, h:mm a"),
        unlockOnDays: moment(contractData[i][2].toString(), "X").fromNow(),
        vestingMonths: _vestingMonths,
        monthlyRelease: monthlyRelease,
        noClaimedMonths: noClaimedMonths,
      };
      dummyArr.push(dummyObj);
    }
    dummyArr
      .sort(
        (a, b) =>
          new moment(a.depositedOn).format("YYYYMMDD") -
          new moment(b.depositedOn).format("YYYYMMDD")
      )
      .reverse();
    return dummyArr;
  }
};
export const GetTokenDecimals = async (tokenAddress) => {
  const contract = await FetchProvider(tokenAddress, Bep20Abi);
  const decimal = await contract.methods.decimals().call();
  const value = decimal.toString();
  return value;
};

export const GetTokenBalance = async (tokenAddress, walletAddress) => {
  const contract = await FetchProvider(tokenAddress, Bep20Abi);

  const decimal = await contract.methods.decimals().call();
  const bal = await contract.methods.balanceOf(walletAddress).call();
  const value = parseFloat(bal / Math.pow(10, decimal)).toFixed(2);
  return value;
};

export const ICOTokenBalance = async (walletAddress) => {
  const contract = await FetchProvider(ICOAddress, IcoAbi);
  if (contract !== undefined) {
    const contractData = await multicall.aggregate([
      contract.methods.decimals(),
      contract.methods.balanceOf(walletAddress),
    ]);

    const decimal = contractData[0];
    const bal = contractData[1];

    const value = parseFloat(bal / Math.pow(10, decimal)).toFixed(2);

    return value;
  }
};
export const TotalVestingAmount = async () => {
  const contract = await FetchProvider(ICOAddress, IcoAbi);
  if (contract !== undefined) {
    const contractData = await multicall.aggregate([
      contract.methods.decimals(),
      contract.methods.vestingSchedulesTotalAmount(),
      contract.methods.supplies(1),
      contract.methods.supplies(2),
      contract.methods.supplies(4),
      contract.methods.supplies(7),
      contract.methods.supplies(3),
    ]);
    const icoInfo = {
      softcap: contractData[2] / Math.pow(10, contractData[0]),
      hardcap: contractData[3] / Math.pow(10, contractData[0]),
      totalinvested: contractData[1] / Math.pow(10, contractData[0]),
      presale: contractData[4] / Math.pow(10, contractData[0]),
      totalinvestedPercent:
        (contractData[1] / contractData[3] / Math.pow(10, contractData[0])) *
        100,
      harCapLimit: contractData[5] / Math.pow(10, contractData[0]),
      publicSaleToken: contractData[6] / Math.pow(10, contractData[0]),
    };
    return icoInfo;
  }
};
export const getReferralData = async (walletAddress, amount) => {
  const contract = await FetchProvider(ICOAddress, IcoAbi);
  // const decimal = await contract.methods.decimals().call();
  // const value = (amount * Math.pow(10, decimal)).toString();

  const countOfRefferal = await contract.methods
    .getReferralsCount(walletAddress)
    .call();
  var multicallVestings = [];
  for (let i = 0; i < countOfRefferal; i++) {
    multicallVestings[i] = contract.methods.referralClaims(walletAddress, i);
  }
  const contractData = await multicall.aggregate(multicallVestings);

  let dummyArr = [];
  var bonus = 0;
  for (let i = 0; i < contractData.length; i++) {
    let obj = {
      investamount: 0,
      claimamount: 0,
      address: "",
      claimed: false,
    };
    obj.investamount = contractData[i][1] / Math.pow(10, 18);
    obj.claimamount = contractData[i][2] / Math.pow(10, 18);
    obj.address = contractData[i][0];
    obj.claimed = contractData[i][4];
    bonus += contractData[i][2] / Math.pow(10, 18);
    dummyArr.push(obj);
  }
  return [dummyArr, bonus];
  // return contractData;
};
// ---------------------
// ---------------------
// ---------------------
// Write Contract methods
// ---------------------
// ---------------------
// ---------------------

export const ApproveToken = async (tokenAddress, signer, provider) => {
  // const contract = await FetchProvider(
  //   tokenAddress.address,
  //   Bep20Abi,
  //   wallet,
  //   provider
  // );

  const contract = await FetchEtherProvider(
    tokenAddress.address,
    Bep20Abi,
    provider
  );

  const signWithSigner = contract.connect(signer);

  // Each DAI has 18 decimal places

  // Send 1 DAI to "ricmoo.firefly.eth"
  let tx = await signWithSigner.approve(
    ICOAddress,
    "115792089237316195423570985008687907853269984665640564039457584007913129639935"
  );

  return await tx.wait();
  // return await contract.methods

  //   .approve(
  //     ICOAddress,
  //     "115792089237316195423570985008687907853269984665640564039457584007913129639935"
  //   )
  //   .send();
};

// export const BuyPublicSale = async (tokenAddressIndex, amount) => {
//   const contract = await FetchProvider(ICOAddress, IcoAbi);
//   return await contract.methods
//     .publicSaleBuy(tokenAddressIndex - 1, amount)
//     .send();
// };

export const ICOClaim = async (index, walletAddress, signer, provider) => {
  const contractWeb3 = await FetchProvider(ICOAddress, IcoAbi);
  const contractEther = await FetchEtherProvider(ICOAddress, IcoAbi, provider);

  // ether signer
  const signWithSigner = contractEther.connect(signer);
  if (walletAddress) {
    connectedWallet = walletAddress;
  }
  const vestingId = await contractWeb3.methods
    .computeVestingScheduleIdForAddressAndIndex(connectedWallet, index)
    .call();
  let tx = await signWithSigner.claim(vestingId);
  return await tx.wait();
};

export const SetMerkleRootforPresale = async (merkleRoot, signer, provider) => {
  // const contract = await FetchProvider(ICOAddress, IcoAbi);
  const contract = await FetchEtherProvider(ICOAddress, IcoAbi, provider);
  const signWithSigner = contract.connect(signer);
  let tx = await signWithSigner.setMerkleRoot(merkleRoot);
  return tx.wait();
};

export const DepositTokens = async (
  amount,
  tokenAddressIndex,
  referAddress,
  signer,
  provider
) => {
  const decimals = await ICODecimal();
  const contract = await FetchEtherProvider(ICOAddress, IcoAbi, provider);
  if (contract !== undefined) {
    const signWithSigner = contract.connect(signer);
    const final = (amount * Math.pow(10, decimals)).toString();

    let tx = await signWithSigner.deposit(
      final,
      tokenAddressIndex - 1,
      referAddress
    );

    return await tx.wait();
  }
};

export const ManualDepositTokens = async (
  recevingAddress,
  recevingAmount,
  saleType,
  public3M,
  public6M,
  referralId,
  signer,
  provider
) => {
  const contract = await FetchEtherProvider(ICOAddress, IcoAbi, provider);
  const signWithSigner = contract.connect(signer);
  // const contract = await FetchProvider(ICOAddress, IcoAbi);
  let tx = await signWithSigner.manualDeposit(
    recevingAddress,
    recevingAmount,
    saleType,
    public3M,
    public6M,
    referralId
  );

  return await tx.wait();
};

export const ClaimReferral = async (index, signer, provider) => {
  const contract = await FetchEtherProvider(ICOAddress, IcoAbi, provider);
  const signWithSigner = contract.connect(signer);
  const referral = await signWithSigner.claimRefferalBonus(index);
  return referral;
};
