/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
// import { serverUrl } from "../../../Config/config";
// import { Card, Button } from "@material-ui/core";
import { Collapse } from "@material-ui/core";
const KycForm = (props) => {
  // const { passport, license, cnicFront, cnicBack, kycDocs } = props;
  const { kycDocs } = props;
  const [openPassport, setOpenPassport] = useState(true);
  const [openCnic, setOpenCnic] = useState(false);
  const [openDriver, setOpenDriver] = useState(false);

  return (
    <>
      <div className="from-step-item password-form pt-5">
        <div className="from-step-content">
          <ul className="nav nav-tabs nav-tabs-bordered" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                onClick={() => {
                  setOpenPassport(true);
                  setOpenCnic(false);
                  setOpenDriver(false);
                }}
                href="#passport"
              >
                <div className="nav-tabs-icon">
                  <img src="images/icon-passport.png" alt="icon" />
                  <img src="images/icon-passport-color.png" alt="icon" />
                </div>
                <span>Passport</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                onClick={() => {
                  setOpenPassport(false);
                  setOpenCnic(true);
                  setOpenDriver(false);
                }}
                href="#national-card"
              >
                <div className="nav-tabs-icon">
                  <img src="images/icon-national-id.png" alt="icon" />
                  <img src="images/icon-national-id-color.png" alt="icon" />
                </div>
                <span>National Card</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#driver-licence"
                onClick={() => {
                  setOpenPassport(false);
                  setOpenCnic(false);
                  setOpenDriver(true);
                  // setUpload([]);
                  // setUpload1([]);
                }}
              >
                <div className="nav-tabs-icon">
                  <img src="images/icon-licence.png" alt="icon" />
                  <img src="images/icon-licence-color.png" alt="icon" />
                </div>
                <span>Driver’s License</span>
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <Collapse in={openPassport} timeout="auto" unmountOnExit>
              <>
                <div className="d-flex justify-content-center">
                  <div className="row align-items-center">
                    <div className="kyc-upload-img">
                      <img
                        src={
                          kycDocs && kycDocs.passport.imageurl
                            ? kycDocs.passport.imageurl
                            : "images/vector-id-front.png"
                        }
                        alt="vector"
                      />
                    </div>
                  </div>
                  <div className="gaps-1x"></div>
                  <div className="gaps-1x"></div>
                </div>
              </>
            </Collapse>
            <Collapse in={openCnic} timeout="auto" unmountOnExit>
              <>
                <div className="d-flex justify-content-center">
                  <div className="row align-items-center">
                    <div className="kyc-upload-img">
                      <img
                        src={
                          kycDocs && kycDocs.nationalId.frontimage
                            ? kycDocs.nationalId.frontimage
                            : "images/vector-id-front.png"
                        }
                        alt="vector"
                      />
                    </div>
                  </div>
                  <div className="gaps-1x"></div>
                  <div className="row align-items-center">
                    <div className="kyc-upload-img">
                      <img
                        src={
                          kycDocs && kycDocs.nationalId.backimage
                            ? kycDocs.nationalId.backimage
                            : "images/vector-id-front.png"
                        }
                        alt="vector"
                      />
                    </div>
                  </div>
                  <div className="gaps-1x"></div>
                </div>
              </>
            </Collapse>
            <Collapse in={openDriver} timeout="auto" unmountOnExit>
              <>
                <div className="d-flex justify-content-center">
                  <div className="row align-items-center">
                    <div className="kyc-upload-img">
                      <img
                        src={
                          kycDocs && kycDocs.license.imageurl
                            ? kycDocs.license.imageurl
                            : "images/vector-id-front.png"
                        }
                        alt="vector"
                      />
                    </div>
                  </div>
                  <div className="gaps-1x"></div>
                  <div className="gaps-1x"></div>
                </div>
              </>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};
export default KycForm;
