import React, { useState, useCallback } from "react";
import CardContent from "@material-ui/core/CardContent";
import { Formik } from "formik";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import MainForm from "../MainForm";
import { useStyles, QontoConnector, ColorlibConnector } from "./style";
import {
  KycGeneralSchema,
  KycSettingsSchema,
  setInitialValues,
} from "../helpers";
import PerfectScrollbar from "react-perfect-scrollbar";
import { StepperIcons } from "./StepperIcons";
import { StepperLabels, getSteps } from "./StepperLabels";
// import RegisterForm from '../RegisterForm';

const Wizard = (props) => {
  const {
    updatedRow,
    actionState,
    SubmitForm,
    upload,
    upload1,
    setUpload1,
    setUpload,
    setDocsType,
    passportImg,
    setPasspostImg,
    cnicFront,
    setCnicFront,
    cnicBack,
    setCnicBack,
    drivingImg,
    setDrivingImg,
  } = props;
  const classes = useStyles();
  // stepper start
  const [gotoNext, setGotoNext] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const handleNext = useCallback(() => {
    setGotoNext((prev) => prev + 1);
    if (gotoNext === activeStep)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [gotoNext, activeStep]);
  const handleBack = useCallback(() => {
    setGotoNext((prev) => prev - 1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // eslint-disable-next-line
  }, [gotoNext, activeStep]);
  const handleGotoNext = useCallback(
    (state) => {
      if (state <= activeStep) setGotoNext(state);
    },
    // eslint-disable-next-line
    [gotoNext]
  );
  // const handleSubmit = () => {};
  return (
    <>
      <PerfectScrollbar>
        <div>
          <div className="pt-5">
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
              className={classes.stepper_main_cont}
            >
              {steps.map((label, index) => (
                <Step>
                  <StepLabel
                    onClick={() => handleGotoNext(index)}
                    StepIconComponent={StepperIcons}
                    className={classes.label_input}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Stepper
              alternativeLabel
              activeStep={gotoNext}
              connector={<QontoConnector />}
              className={classes.stepper_main_cont}
            >
              {steps.map((label) => (
                <Step>
                  <StepLabel StepIconComponent={StepperLabels}></StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <CardContent className={classes.bg}>
            <Formik
              initialValues={setInitialValues(updatedRow)}
              onSubmit={(values, actions) => {
                SubmitForm(values, actions, actionState, upload, upload1);
              }}
              validationSchema={
                gotoNext == 0 ? KycGeneralSchema : KycSettingsSchema
              }
            >
              {(props) => {
                return (
                  <>
                    <div className="d-flex justify-content-between">
                      <div className="mx-auto w90">
                        <div className="pt-2">
                          <MainForm
                            actionState={actionState}
                            gotoNext={gotoNext}
                            setGotoNext={setGotoNext}
                            handleNext={handleNext}
                            handleSubmit={SubmitForm}
                            handleBack={handleBack}
                            upload={upload}
                            upload1={upload1}
                            setUpload1={setUpload1}
                            setUpload={setUpload}
                            setDocsType={setDocsType}
                            passportImg={passportImg}
                            setPasspostImg={setPasspostImg}
                            cnicFront={cnicFront}
                            setCnicFront={setCnicFront}
                            cnicBack={cnicBack}
                            setCnicBack={setCnicBack}
                            drivingImg={drivingImg}
                            setDrivingImg={setDrivingImg}
                            {...props}
                          />
                          {/* <KycForm
                          actionState={actionState}
                          setActiveTab={setActiveTab}
                          {...props}
                          onUploadMultiple={onUploadMultiple}
                          upload={upload}
                          upload1={upload1}
                          setUpload1={setUpload1}
                          setUpload={setUpload}
                          onUpload={onUpload}
                        />
                      */}
                        </div>
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          </CardContent>
        </div>
      </PerfectScrollbar>
    </>
  );
};

export default Wizard;
