import { useContext } from "react";
import { SaleContext } from "../App";

import {
  GetPreAndPublicDates,
  ICOTokenPrice,
} from "../Services/User/web3Calls/contractCall";
import moment from "moment";
import { GetBonusData } from "../Services/Admin/Web3call/contractCall";
const useSaleSetter = async () => {
  const [saleObj, setSaleObj] = useContext(SaleContext);

  if (saleObj.type === "") {
    const dates = await GetPreAndPublicDates();
    const currentTime = new Date().valueOf() / 1000;
    // Sale Handling
    // 1 - Pre and Public Sale not started yet
    if (
      dates?.presalestart > currentTime &&
      dates?.publicsaleend > currentTime
    ) {
      setSaleObj({
        ...saleObj,
        sDates: dates,
        type: "Not Started",
        start: moment
          .unix(dates.publicsalestart)
          .format("ddd, MMM D, YYYY h:mm:ss"),
        end: moment.unix(dates.presaleend).format("ddd, MMM D, YYYY h:mm:ss"),
        bonus: 0,
        price: 0.0,
        phase: 0,
        loading: false,
      });
    }
    // 2 . Pre sale is ended and public sale will start in some time
    else if (
      dates?.presaleend < currentTime &&
      dates?.publicsalestart > currentTime
    ) {
      setSaleObj({
        ...saleObj,
        sDates: dates,
        type: "Upcoming-Public",
        remaintime: 0,
        start: moment
          .unix(dates.publicsalestart)
          .format("ddd, MMM D, YYYY h:mm:ss"),
        end: moment
          .unix(dates.publicsaleend)
          .format("ddd, MMM D, YYYY h:mm:ss"),
        bonus: 0.0,
        price: 0,
        phase: 0,
        loading: false,
      });
    }
    // 3. Public sale started
    else if (
      dates?.publicsalestart < currentTime &&
      dates?.publicsaleend > currentTime
    ) {
      // presale Bonus
      const bonusData = await GetBonusData();

      const bonusW1 = bonusData?.bonusWeek1;
      const bonusW2 = bonusData?.bonusWeek2;
      const bonusW3 = bonusData?.bonusWeek3;
      const bonusW4 = bonusData?.bonusWeek4;
      const bonusother = 1;
      var price = 0;
      var bonus = 0;
      const price1 = await ICOTokenPrice(1);
      price = price1;
      const remainTime = dates.publicsalestart - currentTime;
      const reimain4 = dates.publicsaleend - dates.publicsalestart;
      const remainInSeconds = reimain4 / 4;

      // const timeDuration = moment.duration(futureDate.diff(today,"seconds"))
      var phase = 0;
      if (remainTime <= remainInSeconds) {
        phase = 1;
        bonus = bonusW1;
      } else if (
        remainTime > remainInSeconds &&
        remainTime < 2 * remainInSeconds
      ) {
        phase = 2;
        bonus = bonusW2;
      } else if (
        remainTime > 2 * remainInSeconds &&
        remainTime < 3 * remainInSeconds
      ) {
        phase = 3;
        bonus = bonusW3;
      } else if (
        remainTime > 3 * remainInSeconds &&
        remainTime < 4 * remainInSeconds
      ) {
        phase = 4;
        bonus = bonusW4;
      } else {
        phase = "ended";
        bonus = bonusother;
      }

      setSaleObj({
        ...saleObj,
        sDates: dates,
        type: "Public Sale",
        remaintime: remainInSeconds,
        start: moment
          .unix(dates.publicsalestart)
          .format("ddd, MMM D, YYYY h:mm:ss"),
        end: moment
          .unix(dates.publicsaleend)
          .format("ddd, MMM D, YYYY h:mm:ss"),
        bonus: bonus,
        price: price,
        phase: phase,
        loading: false,
      });
    }

    // 4. presale started
    else if (
      dates?.presalestart < currentTime &&
      dates?.presaleend > currentTime
    ) {
      const price1 = await ICOTokenPrice(0);

      price = price1;
      setSaleObj({
        ...saleObj,
        sDates: dates,
        start: moment
          .unix(dates.presalestart)
          .format("ddd, MMM D, YYYY h:mm:ss A"),
        type: "Pre Sale",
        end: moment.unix(dates.presaleend).format("ddd, MMM D, YYYY h:mm:ss A"),
        bonus: 0,
        price: price,
        loading: false,
      });
    }
    // 5.Pre and public sale ended
    else if (
      dates?.presaleend < currentTime &&
      dates?.publicsaleend < currentTime
    ) {
      setSaleObj({
        ...saleObj,
        sDates: dates,
        type: "Token Sale Dates",
        start: moment
          .unix(dates.publicsalestart)
          .format("ddd, MMM D, YYYY h:mm:ss"),
        end: moment.unix(dates.presaleend).format("ddd, MMM D, YYYY h:mm:ss"),
        bonus: 0,
        price: 0.0,
        loading: false,
      });
    }
  }
};

export default useSaleSetter;
