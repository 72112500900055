/* eslint-disable */
import moment from "moment";
export const createRows = (rowData) => {
  const rows = rowData.map((i, index) => {
    return {
      userId: i.id,
      serial: index + 1,
      email: i.useremail && i.useremail === "" ? "..." : i.useremail,
      name: i.username === "" ? "..." : i.username,
      invested: i.amount,
      paymentMethod: i.paymentMethod,
      payableToken: i.payabletoken,
      tokens: i.tokens,
      status: i.status.length === 0 ? "In Process" : "Sent",
      updatedAt: i.date,
    };
  });
  return rows;
};
//Search Handler
export const OnSearch = (searchedVal, setRowData, data) => {
  if (searchedVal.length > 0) {
    const filteredRows = data.filter((row) => {
      let match = false;
      // match user name first and last
      if (!match && row.username) {
        match = row.username.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match email
      if (!match && row.useremail) {
        match = row.useremail.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match contact number
      if (!match && row.status) {
        match = row.status.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // // match address
      // if (!match && row.paymentMethod) {
      //   match = row.paymentMethod
      //     .toLowerCase()
      //     .includes(searchedVal.toLowerCase());
      // }
      // //match wallet address
      // if (!match && row.payableToken) {
      //   match = row.payableToken
      //     .toLowerCase()
      //     .includes(searchedVal.toLowerCase());
      // }
      return match;
    });
    setRowData(filteredRows);
  } else {
    setRowData(data);
  }
};
export const CardsHeadings = [
  {
    id: "investors",
    label: "Total Investors",
    bg: "hover-effect bg-gd-dusk cursor-puri",
    logo: "person",
  },
  {
    id: "bitcoin",
    label: "Bitcoin Investors",
    bg: "hover-effect bg-warning cursor-puri",
    logo: "bitcoin",
  },
  {
    id: "ethereum",
    label: "Ethereum Investors",
    bg: "hover-effect bg-black-50 cursor-puri",
    logo: "ethereum",
  },
  {
    id: "dai",
    label: "DAI",
    bg: "hover-effect bg-gd-aqua-op cursor-puri",
    logo: "dai",
  },
  {
    id: "tusd",
    label: "TUSD",
    bg: " hover-effect bg-zinnia-grad-alt cursor-puri",
    logo: "tusd",
  },
  {
    id: "busd",
    label: "BUSD",
    bg: " hover-effect bg-zinnia-grad-alt cursor-puri",
    logo: "busd",
  },
  {
    id: "usdt",
    label: "USDT",
    bg: " hover-effect bg-zinnia-grad-alt cursor-puri",
    logo: "usdt",
  },
];
export const rowsPerPage = 5;
export const dashboardTableHeadings = [
  {
    id: "id",
    label: "#",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "name",
    label: "User",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "invested",
    label: "Invested",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "payableToken",
    label: "Payable Token",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "status",
    label: "Status",
    class: "bg-body-light",
  },
  {
    id: "time",
    label: "Time",
    class: "bg-body-light",
  },
];
export const makeGraphData = (
  setDates,
  setOptions,
  transactions,
  setFilterData,
  setDai,
  setBusd,
  setUst,
  setTusd,
  setBtc,
  setEth
) => {
  let today = moment().format("YYYY MM DD");
  let date = moment(today).subtract(30, "days").format("YYYY MM DD");
  let _dates = [];
  for (var j = 0; j < 31; j++) {
    // eslint-disable-next-line no-loop-func
    // eslint-disable-next-line no-loop-func
    _dates.push(moment(date).add(j, "days").format("MMM DD"));
  }
  CountTransactions(
    transactions,
    _dates,
    setFilterData,
    setDai,
    setBusd,
    setUst,
    setTusd,
    setBtc,
    setEth
  );
  setDates(_dates);
  makeOptions(_dates, setOptions);
};
export const CountInvestors = (transactions) => {
  let _transactions = [];
  let _dai = [];
  let _busd = [];
  let _ust = [];
  let _tusd = [];
  let _btc = [];
  let _eth = [];
  // eslint-disable-next-line no-loop-func
  // eslint-disable-next-line no-loop-func
  transactions.map((i, index) => {
    if (i.paymentMethod === "DAI") {
      _dai.push(i.useremail);
    }
    if (i.paymentMethod === "BUSD") {
      _busd.push(i.useremail);
    }
    if (i.paymentMethod === "UST") {
      _ust.push(i.useremail);
    }
    if (i.paymentMethod === "TUSD") {
      _tusd.push(i.useremail);
    }
    if (i.paymentMethod === "BTC") {
      _btc.push(i.useremail);
    }
    if (i.paymentMethod === "ETH") {
      _eth.push(i.useremail);
    }
  });
  let uniqueDai = [...new Set(_dai)];
  let uniqueBusd = [...new Set(_busd)];
  let uniqueUst = [...new Set(_ust)];
  let uniqueTusd = [...new Set(_tusd)];
  let uniqueBtc = [...new Set(_btc)];
  let uniqueEth = [...new Set(_eth)];
  let obj = {
    dai: uniqueDai.length,
    busd: uniqueBusd.length,
    ust: uniqueUst.length,
    tusd: uniqueTusd.length,
    btc: uniqueBtc.length,
    eth: uniqueEth.length,
  };
  return obj;
};
const CountTransactions = (
  transactions,
  dates,
  setFilterData,
  setDai,
  setBusd,
  setUst,
  setTusd,
  setBtc,
  setEth
) => {
  let _transactions = [];
  let _dai = [];
  let _busd = [];
  let _ust = [];
  let _tusd = [];
  let _btc = [];
  let _eth = [];
  for (var j = 0; j < 31; j++) {
    // eslint-disable-next-line no-loop-func
    // eslint-disable-next-line no-loop-func
    let match = 0;
    let dai = 0;
    let busd = 0;
    let ust = 0;
    let tusd = 0;
    let btc = 0;
    let eth = 0;
    transactions.map((i, index) => {
      if (moment(i.date).format("MMM DD") === dates[j]) {
        match = match + 1;
        if (i.paymentMethod === "DAI") {
          dai = dai + 1;
        }
        if (i.paymentMethod === "BUSD") {
          busd = busd + 1;
        }
        if (i.paymentMethod === "UST") {
          ust = ust + 1;
        }
        if (i.paymentMethod === "TUSD") {
          tusd = tusd + 1;
        }
        if (i.paymentMethod === "BTC") {
          btc = btc + 1;
        }
        if (i.paymentMethod === "ETH") {
          eth = eth + 1;
        }
      } else {
      }
    });
    _transactions[j] = match;
    _dai[j] = dai;
    _busd[j] = busd;
    _ust[j] = ust;
    _tusd[j] = tusd;
    _btc[j] = btc;
    _eth[j] = eth;
  }
  setFilterData(_transactions);
  setDai(_dai);
  setBusd(_busd);
  setUst(_ust);
  setTusd(_tusd);
  setBtc(_btc);
  setEth(_eth);
};
export const makeOptions = (dates, setOptions) => {
  let options = {
    chart: {
      type: "area",
      // zoom: {
      //   enabled: false,
      // },
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: "Monthly Transactions Report",
          },
          svg: {
            filename: "Monthly Transactions Report",
          },
          png: {
            filename: "Monthly Transactions Report",
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      textAnchor: "left",
      style: {
        zIndex: 9000000,
        fontSize: "14px",
        marginRight: "20px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: undefined,
      },
    },
    grid: {
      row: {
        colors: ["#f3ebe8", "#ffcfbd"],
        opacity: 0.5,
      },
    },
    // grid: {
    //   row: {
    //     colors: ['#8b8b8b', '#8b8b8b', '#8b8b8b']
    //   },
    //   column: {
    //     colors: ['#8b8b8b', '#8b8b8b', '#8b8b8b']
    //   }
    // },
    // fill: {
    //   type: 'pattern',
    //   pattern: {
    //     style: 'verticalLines',
    //     width: 10,
    //     height: 10,
    //     strokeWidth: 2
    //   }
    // },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      fontFamily: "Helvetica, Arial",
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      markers: {
        width: 20,
        height: 20,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      useSeriesColors: false,
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    //   markers: {
    //     colors: ['#F44336', '#E91E63', '#9C27B0']
    //  },
    colors: [
      "#00ea90",
      "#8247e5",
      "#f73f60",
      "#2775ca",
      "#002868",
      "#f7931a",
      "#8b8b8b",
    ],
    yaxis: {
      labels: {
        style: {
          colors: "#f64b1c",
        },
        offsetX: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    xaxis: {
      type: "date",
      categories: dates,
      labels: {
        style: {
          colors: "#f64b1c",
        },
        offsetX: 0,
      },
    },
    tooltip: {
      x: {
        format: "YYYY MM DD",
      },
    },
    stroke: {
      curve: "smooth",
    },
    noData: {
      text: "NO DATA",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#8c30f5",
        fontSize: "50px",
        fontFamily: undefined,
      },
    },
    title: {
      text: "Monthly Transactions Report",
      align: "left",
    },
    // legend: {
    //   colo
    // }
  };
  setOptions(options);
};
