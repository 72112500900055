import { useState } from "react";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { getStatusClasses, StatusLabel } from "./helper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const StatusDropdown = (props) => {
  const { status, id, UpdateStatusService } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItem = (stat) => {
    UpdateStatusService({ status: stat, id: id });
    handleClose();
  };

  return (
    <>
      <Button
        disabled={props && props.dis === false}
        variant="contained"
        size="small"
        id={"statusKyc"}
        onClick={handleClick}
        className={
          props && props.dis === false
            ? "kycstatus-btn"
            : getStatusClasses(status)
        }
      >
        {status === "in progress"
          ? StatusLabel["inactive"]
          : StatusLabel[status]}{" "}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        className="status-menu"
        disabled={props && props.dis === false}
        id="statuskycmenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          className="status-menu"
          id={"statuskycactive"}
          selected={status.toLowerCase() === "pending"}
          onClick={() => {
            handleMenuItem("pending");
          }}
        >
          Pending
        </MenuItem>
        <MenuItem
          id={"statuskycactive"}
          selected={status.toLowerCase() === "active"}
          onClick={() => {
            handleMenuItem("active");
          }}
        >
          Active
        </MenuItem>
        <MenuItem
          id={"statuskycinactive"}
          selected={status.toLowerCase() === "in progress"}
          onClick={() => {
            handleMenuItem("in progress");
          }}
        >
          In Progress
        </MenuItem>
        <MenuItem
          id={"statuskycinactive"}
          selected={status.toLowerCase() === "blocked"}
          onClick={() => {
            handleMenuItem("blocked");
          }}
        >
          Blocked
        </MenuItem>
      </Menu>
    </>
  );
};
export default StatusDropdown;
