/* eslint-disable */
import * as Yup from "yup";
import Web3 from "web3";
export const WithdrawTokensInitialValues = {
  name: "",
  amount: 0,
  address: "",
  balance: "",
  index: "",
};
export const WithdrawTokensFormFields = [
  {
    field: "Name",
    fieldName: "name",
    place: "Select Asset",
    type: "auto",
  },
  {
    field: "Amount",
    fieldName: "amount",
    place: "Enter Amount",
    type: "number",
  },
];
export const WithdrawTokensSchema = Yup.object().shape({
  name: Yup.string().required("Name is required!"),
  amount: Yup.number()
    .required("Please Enter Amount!")
    .test("amount", "Amount should be greater than zero", (a) => a > 0),
});
export const setWithdrawTokenInitialValues = (CurrentUser, id) => {
  return {
    index: CurrentUser[0].index,
    name: CurrentUser[0].name ? CurrentUser[0].name : "",
    address: CurrentUser[0].address,
    balance: CurrentUser[0].balance,
    amount: 0,
  };
};
export const PricesInitialValues = {
  preSalePrice: "",
  publicSalePrice: "",
};
export const PriceFormFields = [
  {
    field: "Pre Sale Price",
    fieldName: "preSalePrice",
    place: "Enter Pre Sale Price",
    type: "number",
  },
  {
    field: "Public Sale Price",
    fieldName: "publicSalePrice",
    place: "Enter Public Sale Price",
    type: "number",
  },
];
export const PriceSchema = Yup.object().shape({
  preSalePrice: Yup.string().required("Pre Sale Price is required!"),
  publicSalePrice: Yup.string().required("Public Sale Price is required!"),
});
export const BonusInitialValues = {
  bonusWeek1: 10,
  bonusWeek2: "",
  bonusWeek3: "",
  bonusWeek4: "",
};
export const BonusFormFields = [
  {
    field: "Bonus Week 1",
    fieldName: "bonusWeek1",
    place: "Enter Bonus",
    type: "number",
  },
  {
    field: "Bonus Week 2",
    fieldName: "bonusWeek2",
    place: "Enter Bonus",
    type: "number",
  },
  {
    field: "Bonus Week 3",
    fieldName: "bonusWeek3",
    place: "Enter Bonus",
    type: "number",
  },
  {
    field: "Bonus Week 4",
    fieldName: "bonusWeek4",
    place: "Enter Bonus",
    type: "number",
  },
];
export const BonusSchema = Yup.object().shape({
  bonusWeek1: Yup.string().required("Bonus for week 1 is required!"),
  bonusWeek2: Yup.string().required("Bonus for week 2 is required!"),
  bonusWeek3: Yup.string().required("Bonus for week 3 is required!"),
  bonusWeek4: Yup.string().required("Bonus for week 4 is required!"),
});
export const IcoInitialValues = {
  presalestart: "",
  presaleend: "",
  publicsalestart: "",
  publicsaleend: "",
};
export const CreateMintingInitialValue = (
  arr,
  setMintingInitialValue,
  valuesTemp
) => {
  let temp = [];
  arr.map((i, index) => {
    let key1 = "address" + index;
    let key2 = "amount" + index;
    let key3 = "status" + index;
    temp.push({
      key1: key1,
      key2: key2,
      key3: key3,
      value1: "",
      value2: "",
      value3: false,
    });
    return 0;
  });
  var object2 = temp.reduce(
    (obj, item) => ({
      ...obj,
      [item.key1]: item.value1,
      [item.key2]: item.value2,
      [item.key3]: item.value3,
    }),
    {}
  );
  Object.keys(valuesTemp).map((item) => {
    Object.keys(object2).map((item2) => {
      if (item === item2) {
        object2[item2] = valuesTemp[item];
      }
      return 0;
    });
    return 0;
  });
  setMintingInitialValue(object2);
};
export const CreateMintingSchema = (arr) => {
  let temp = [];
  arr.map((i, index) => {
    let key1 = "address" + index;
    let key2 = "amount" + index;
    let value1 = Yup.string().required("Address is required");
    let value2 = Yup.string().required("Amount is required");
    temp.push({ key1: key1, key2: key2, value1: value1, value2: value2 });
    return 0;
  });
  var object2 = temp.reduce(
    (obj, item) => ({
      ...obj,
      [item.key1]: item.value1,
      [item.key2]: item.value2,
    }),
    {}
  );
  let schema = Yup.object().shape(object2);
  return schema;
};
export const CreateMintingFormFields = (arr) => {
  let mintFields = [];
  arr.map((i, index) => {
    mintFields.push({
      field: "Address",
      fieldName: "address" + index,
      place: "Enter Address",
      type: "text",
      field1: "Amount",
      fieldName1: "amount" + index,
      place1: "Enter Amount",
      type1: "number",
      plus: "true",
      minus: "true",
    });
    return 0;
  });
  return mintFields;
};
export const CreatePaymentTokenInitialValue = (
  arr,
  setMintingInitialValue,
  valuesTemp
) => {
  let temp = [];
  arr.map((i, index) => {
    let key1 = "address" + index;
    let key2 = "status" + index;
    temp.push({ key1: key1, key2: key2, value1: "", value2: false });
    return 0;
  });
  var object2 = temp.reduce(
    (obj, item) => ({
      ...obj,
      [item.key1]: item.value1,
      [item.key2]: item.value2,
      [item.key3]: item.value3,
    }),
    {}
  );
  Object.keys(valuesTemp).map((item) => {
    Object.keys(object2).map((item2) => {
      if (item === item2) {
        object2[item2] = valuesTemp[item];
      }
      return 0;
    });
    return 0;
  });
  setMintingInitialValue(object2);
};
export const CreatePaymentTokensSchema = (arr) => {
  let temp = [];
  arr.map((i, index) => {
    let key1 = "address" + index;
    let key2 = "status" + index;
    // let key3 = "name" + index;
    let value1 = Yup.string().required("Address is required");
    temp.push({ key1: key1, key2: key2, value1: value1 });
    return 0;
  });
  var object2 = temp.reduce(
    (obj, item) => ({
      ...obj,
      [item.key1]: item.value1,
    }),
    {}
  );
  let schema = Yup.object().shape(object2);
  return schema;
};
export const PaymentTokensFormFields = (arr) => {
  let mintFields = [];
  arr.map((i, index) => {
    mintFields.push({
      field: "Address",
      fieldName: "address" + index,
      place: "Enter Address",
      type: "text",
      field1: "status",
      fieldName1: "status" + index,
      type1: "status",
      plus: "true",
      minus: "true",
    });
    return 0;
  });
  return mintFields;
};
export const IcoFormFields = [
  {
    field: "Pre Sale Starts",
    fieldName: "presalestart",
    place: "Enter Main heading",
    type: "date",
  },
  {
    field: "Pre Sale Ends",
    fieldName: "presaleend",
    place: "Enter Main heading",
    type: "date",
  },
  {
    field: "Public Sale Starts",
    fieldName: "publicsalestart",
    place: "Enter Main heading",
    type: "date",
  },
  {
    field: "Public Sale Ends",
    fieldName: "publicsaleend",
    place: "Enter Main heading",
    type: "date",
  },
];
export const IcoSchema = Yup.object().shape({
  presalestart: Yup.string().required("Pre Sale Starts is required!"),
  presaleend: Yup.string().required("Pre Sale Ends is required!"),
  publicsalestart: Yup.string().required("Public Sale Starts is required!"),
  publicsaleend: Yup.string().required("Public Sale Ends is required!"),
});
export const FounderInitialValues = {
  address: "",
  amount: "",
};
export const IcoFounderFormFields = [
  {
    field: "Address",
    fieldName: "address",
    place: "Enter Address",
    type: "text",
  },
  {
    field: "Amount",
    fieldName: "amount",
    place: "Enter Amount",
    type: "number",
  },
];
export const FounderSchema = Yup.object().shape({
  address: Yup.string().required("Pre Sale Starts is required!"),
  amount: Yup.number()
    .required("Please Enter Amount!")
    .test("amount", "Amount should be greater than zero", (a) => a > 0),
});
export const setFounderInitialValues = (CurrentUser, id) => {
  return {
    address: "",
    amount: "",
    // amount: Web3.utils.fromWei(CurrentUser.publicSalePrice),
  };
};
export const getInitialValues = (CurrentUser, id) => {
  return {
    presalestart: CurrentUser?.presalestart
      ? CurrentUser.presalestart
      : new Date(),
    presaleend: CurrentUser?.presaleend ? CurrentUser.presaleend : new Date(),
    publicsalestart: CurrentUser?.publicsalestart
      ? CurrentUser.publicsalestart
      : new Date(),
    publicsaleend: CurrentUser?.publicsaleend
      ? CurrentUser.publicsaleend
      : new Date(),
  };
};
export const setBonusInitialValues = (CurrentUser, id) => {
  return {
    bonusWeek1: CurrentUser?.bonusWeek1,
    bonusWeek2: CurrentUser?.bonusWeek2,
    bonusWeek3: CurrentUser?.bonusWeek3,
    bonusWeek4: CurrentUser?.bonusWeek4,
  };
};
export const setPricesInitialValues = (CurrentUser, id) => {
  return {
    preSalePrice: Web3.utils.fromWei(CurrentUser.preSalePrice),
    publicSalePrice: Web3.utils.fromWei(CurrentUser.publicSalePrice),
  };
};
export const MakeArray = (name, obj) => {
  let arr = [];
  Object.keys(obj).map((item) => {
    let match = item.toLowerCase().includes(name.toLowerCase());
    if (match) {
      if (name === "amount") {
        arr.push(Web3.utils.toWei(obj[item].toString()));
        // arr.push((obj[item] * Math.pow(10, 18)).toString());
      } else if (name === "bonus") {
        arr.push(parseInt(obj[item]));
      } else if (name === "price") {
        arr.push(Web3.utils.toWei(obj[item].toString()));
      } else {
        arr.push(obj[item].toString());
      }
    }
    return 0;
  });
  return arr;
};
export const MakeArrayPaymentTokens = (name, obj) => {
  let arr = [];
  Object.keys(obj).map((item) => {
    let match = item.toLowerCase().includes(name.toLowerCase());
    if (match) {
      arr.push(obj[item].toString());
    }
    return 0;
  });
  return arr;
};
